export const OPEN_LEAD_DAILOG = "OPEN_LEAD_DAILOG";
export const CLOSE_LEAD_DAILOG = "CLOSE_LEAD_DAILOG";
export const OPEN_ACCOUNT_DIALOG = "OPEN_ACCOUNT_DIALOG";
export const CLOSE_ACCOUNT_DIALOG = "CLOSE_ACCOUNT_DIALOG";
export const OPEN_CONTACT_DIALOG = "OPEN_CONTACT_DIALOG";
export const CLOSE_CONTACT_DIALOG = "CLOSE_CONTACT_DIALOG";
export const OPEN_PRODUCT_DAILOG = "OPEN_PRODUCT_DAILOG";
export const CLOSE_PRODUCT_DAILOG = "CLOSE_PRODUCT_DAILOG";
export const OPEN_USER_DAILOG = "OPEN_USER_DAILOG";
export const CLOSE_USER_DAILOG = "CLOSE_USER_DAILOG";
export const ON_PAGE_CHANGE = "ON_PAGE_CHANGE";
export const ON_SIZE_CHANGE = "ON_SIZE_CHANGE";
export const ON_DEFAULT_PAGINATION = "ON_DEFAULT_PAGINATION";
export const OPEN_ROLE_DIALOG = "OPEN_ROLE_DIALOG";
export const CLOSE_ROLE_DIALOG = "CLOSE_ROLE_DIALOG";


import React from "react";

import { Grid, Typography } from "@mui/material";

const ActivityHOC = ({ icon, title, subtitle, children, sx }) => {
  return (
    <div>
      <Grid
        container
        sx={{
          border: "2px solid #576a77a6",
          padding: "0 10px",
          marginTop: "10px",
          ...sx,
        }}
        className='rounded'
        direction='row'
      >
        <Grid
          container
          direction='row'
          alignItems='center'
          sx={{
            borderBottom: "1px solid #576a77a6",
            padding: "5px 10px",
            height: "50px",
          }}
        >
          <Grid item xs={12} className='d-flex align-items-center'>
            <Grid item xs={2} className='second'>
              {icon}
            </Grid>
            <Grid item xs={10}>
              <Typography variant='h6' fontSize='14px'>
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container display='grid' direction='column' className='second'>
            <Typography variant='p' fontSize='10px'>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction='row'
          sx={{ padding: "10px" }}
          alignItems='center'
          justifyContent='left'
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

export default ActivityHOC;

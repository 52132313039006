import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{ width: "100vw", height: "100vh" }}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <Typography
          component={"h3"}
          variant="h1"
          className="text-center"
          color={"primary"}
        >
          404!
        </Typography>
        <Typography component={"h3"} variant="h4" className="text-center mb-3">
          The Page you are looking does not exits!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            navigate(
              localStorage.getItem("token") && localStorage.getItem("domain")
                ? "/"
                : "/my-crm/log-in"
            )
          }
        >
          {localStorage.getItem("token") && localStorage.getItem("domain")
            ? "Back to Dashboard"
            : "Login"}
        </Button>
      </div>
    </>
  );
};
export default NotFound;

// 1."/accounts/user/"
// 2."/accounts/login/"
// 3."/tenants/user/"
// 4."/tenants/tenant/"

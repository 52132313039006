import React from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import CustomTabs from "../../Layout/TabPanel";
import Notes from "./notes/Notes";
import Communication from "./communication/Communication";
import { makeStyles } from "@mui/styles";
import Flag from "../../Layout/flag/Flag";
import Timeline from "./timeline/Timeline";

const useStyle = makeStyles({
  root: {
    "& .MuiPaper-root .MuiPaper-elevation .MuiPaper-elevation4 .MuiAppBar-root .MuiAppBar-colorPrimary .MuiAppBar-positionStatic .css-12bj51y-MuiPaper-root-MuiAppBar-root":
      {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
      },
    "& .MuiTabs-root css-1ujnqem-MuiTabs-root": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "& .css-1ujnqem-MuiTabs-root": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
  },
});

const ContactHistory = ({ forDeal }) => {
  const { root } = useStyle();
  const matches = useMediaQuery("(max-width:900px)");
  const smMatches = useMediaQuery("(max-width:500px)")

  return (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <Grid
        container
        display="grid"
        direction="column"
        className={!matches ? "px-3" : ""}
      >
        {forDeal && (
          <Box className="d-flex scroll-example1">
            <Flag name="stage_name" />
          </Box>
        )}
        <Grid item xs={12} 
                  >
          <CustomTabs
          // sx={{
          //   fontSize: smMatches ? "0.67rem" : "0.87rem"
          // }}
          isHeightAuto
            className={`${root}`}
            data={[
              {
                index: 0,
                label: "Notes",
                component: Notes,
                componentProps: { forDeal: forDeal },
                // styles: {
                //   height: "calc(100vh - 500px)",
                // },
              },
              { index: 1, label: "Communication", component: Communication,isHeightAuto:true },
              {
                index: 2,
                label: "Timeline",
                component: Timeline,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactHistory;

import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { height } from "@mui/system";
import React from "react";
import { useState } from "react";
import AutoSelectField from "../../../../../components/formsUI/AutoComplete";
import InputField from "../../../../../components/formsUI/InputField";
import InputFieldNf from "../../../../../components/formsUI/withoutFormik/InputFieldNf";
import { VscListSelection } from "react-icons/vsc";
import { IoColorPaletteSharp } from "react-icons/io5";

const colorOptions = [
  "#023047",
  "#a8dadc",
  "#8ecae6",
  "#219ebc",
  "#457b9d",
  "#e63946",
  "#ffb703",
  "#2a9d8f",
];

const StageForm = ({ isUpdate }) => {
  const [custColor, setCustColor] = useState(false);

  return (
    <Grid container className="mt-3" columnSpacing={2}>
      {!isUpdate && (
        <Grid item xs={6} className="mt-3">
          <InputField
            name="order"
            label="Order"
            type="number"
            color="secondary"
            size="small"
            disabled={!isUpdate}
          />
        </Grid>
      )}
      <Grid container item xs={6} columnSpacing={1} className="mt-3">
        <Grid item xs={10}>
          {custColor ? (
            <InputField
              label="Color"
              name="colour"
              type="color"
              fullWidth
              size="small"
            />
          ) : (
            <AutoSelectField
              name="colour"
              label="Select Color"
              color="secondary"
              size="small"
              multiple={false}
              options={colorOptions}
              getOptionLabel={(option) => (
                <div
                  style={{
                    backgroundColor: `${option}`,
                    width: "100%",
                    padding: "5px",
                    borderRadius: "3px",
                    height: "20px",
                  }}
                ></div>
              )}
              isColor
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => setCustColor((prevState) => !prevState)}>
            {custColor ? <VscListSelection /> : <IoColorPaletteSharp />}
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={6} className="mt-3">
        <InputField
          name="stage_name"
          label="Stage Name"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={6} className="mt-3">
        <InputField
          name="probability"
          label="Probability"
          color="secondary"
          size="small"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default StageForm;

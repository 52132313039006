import React from "react";
import CustomFinal from "../../../../components/custLead/CustomFinal";
import CustomLeadHeader from "../../../../components/custLead/CustomLeadHeader";

const SmartListTemplates = () => {
  const Templatesdata = [
    {
      id: 1,
      name: "Template 01",
      type: "",
      is_active: "",
      is_required: "",
    },
    {
      id: 2,
      name: "Template 02",
      type: "",
      is_active: "",
      is_required: "",
    },
    {
      id: 3,
      name: "Template 03",
      type: "",
      is_active: "",
      is_required: "",
    },
  ];
  return (
    <>
      <CustomLeadHeader
        HeadingName="Current Fields"
        style={{ padding: "10px" }}
      />
      <div className="px-5 py-2">
        {Templatesdata?.map((item) => (
          <div className="mt-2">
            <CustomFinal
              showEdit={true}
              name={item?.name}
              label={item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default SmartListTemplates;

import { useQuery } from "react-query";
import { tenantAPI } from "../../../config/api";

const getSingleAccount = async (args) => {
  return await tenantAPI.get(`/companies/${args}`);
};

const useGetSingleAccount = (id, options = {}) => {
  return useQuery(["account", id], () => getSingleAccount(id), options);
};

export default useGetSingleAccount;

import { MoreVert, Phone, MailOutline } from "@mui/icons-material";
import { Grid, Typography, Icon, IconButton } from "@mui/material";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import { useUpdateDeal } from "../../redux/actions/dealAction";
import PhoneMenu from "../ivr/PhoneMenu";

const DealPhaseSection = ({ data }) => {
  const [hotDeal, setHotDeal] = useState(data?.is_hot);
  const { mutate } = useUpdateDeal(data?.uuid);

  return (
    <Grid
      style={{ background: "#fff", borderRadius: "8px" }}
      container
      direction="column"
      className="my-3 p-2"
    >
      <Link
        style={{ textDecoration: "none", color: "#000000" }}
        to={`/deals/${data.uuid}/`}
      >
        <Grid className="borderp-3 " item xs={12} dragable>
          <Grid container>
            <Grid item xs={10.5} className="">
              {/* <Tooltip title="view deal" arrow placement="bottom-start"> */}

              <Typography fontSize="12px" fontWeight="Medium" component="p">
                {/* {data?.contact?.first_name} {data?.contact?.last_name} */}
                {data?.name}
              </Typography>

              {/* </Tooltip> */}
            </Grid>
            <Grid item className="d-flex align-items-center" xs={1}>
              <MoreVert className="second" fontSize="12px" />
            </Grid>
            <Grid item xs={10} className="d-flex align-items-center mt-1">
              <Typography variant="caption" fontSize="10px" component="p">
                {data?.contact?.company?.company_name}
              </Typography>
            </Grid>

            <Grid
              item
              xs={2}
              className="d-flex justify-content-end align-items-center mt-1"
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  if (hotDeal === true) {
                    setHotDeal(false);
                    mutate({ is_hot: false });
                  } else {
                    setHotDeal(true);
                    mutate({ is_hot: true });
                  }
                }}
                style={{ padding: "4px" }}
              >
                <LocalFireDepartmentIcon
                  style={{
                    color: hotDeal ? "#FFA500" : "#AAAAAA",
                  }}
                />
              </IconButton>
            </Grid>
            <Grid container className="mt-3">
              <Grid item xs={6} className="d-flex align-items-center">
                <span style={{ fontWeight: 400, fontSize: "14px" }}>
                  <BiRupee />
                </span>
                <Typography component="p" fontSize="10px">
                  {data?.valuation}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                className="d-flex align-items-center justify-content-center"
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <PhoneMenu
                    name={
                      <IconButton
                        size="small"
                        sx={{ fontSize: "12px", color: "black" }}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Phone fontSize="smaller" />
                      </IconButton>
                    }
                    phNum={data?.contact?.primary_number}
                    removeWhatsapp={true}
                  />
                </span>
              </Grid>
              <Grid
                item
                xs={2}
                className="d-flex align-items-center justify-content-center"
              >
                <IconButton
                  size="small"
                  sx={{ fontSize: "12px", color: "black" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <MailOutline fontSize="smaller" />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={2}
                className="d-flex align-items-center justify-content-center"
              >
                <IconButton
                  size="small"
                  sx={{ fontSize: "12px", color: "black" }}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Icon
                    fontSize="smaller"
                    baseClassName="fab"
                    className="fa-whatsapp"
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );
};

export default DealPhaseSection;

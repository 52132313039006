import { Grid } from "@mui/material";
import React from "react";
import CheckboxLabels from "../../../components/formsUI/CheckBoxLabel";
import DateAndTImeInput from "../../../components/formsUI/DateAndTImeInput";
import InputField from "../../../components/formsUI/InputField";
import SelectDate from "../../../components/formsUI/SelectDate";

const TenantForm = (is_active) => {
  return (
    <Grid container className="mt-3" spacing={2}>
      <Grid item sm={6}>
        <InputField
          name="num_of_users"
          type="number"
          label="Users"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item sm={6}>
        <SelectDate
          name="subscription_end"
          label="End Date"
          format="YYYY-MM-DD"
        />
      </Grid>
      <Grid item sm={6}>
        <CheckboxLabels
          label="Is Active"
          name="is_active"
          value={is_active}
          className="ms-2"
        />
      </Grid>
    </Grid>
  );
};

export default TenantForm;

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#D6ECF8 !important",
    color: "#000",
    maxHeight: "48px",
    "& .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "48px !important",
    },
    "& .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded:after":
      {
        minHeight: "48px !important",
      },
    "& .MuiAccordionSummary-root": {
      minHeight: "48px",
    },
  },
});

const SimpleAccordion = ({
  children,
  name,
  button,
  onClick,
  count,
  setState,
  overrideStyles,
  style,
  ...otherProps
}) => {
  const { root } = useStyles();
  return (
    <div>
      <Accordion onChange={setState} style={{ ...style }}>
        <AccordionSummary
          className={overrideStyles ? overrideStyles : root}
          {...otherProps}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {name}

            {"   "}
            {count}
          </Typography>
          <div style={{ zIndex: 999 }}>{button}</div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
      {/* <Stack
        style={{ position: "absolute", zIndex: 999, top: "5px", right: "40px" }}
      >
        <IconButton>
          <MdAdd onClick={onClick} />
        </IconButton>
      </Stack> */}
    </div>
  );
};
export default SimpleAccordion;

import { useQuery } from "react-query";
import { tenantAPI } from "../../../config/api";

export const useCurrMonthData = (options) => {
  return useQuery(
    "db-for-month",
    async () => await tenantAPI.get(`/deals/dashboard-1/`),
    {
      ...options,
      select: (data) => data.data,
    }
  );
};

export const useNewAndHotDeals = (options) => {
  return useQuery(
    "db-deals-n-h",
    async () => await tenantAPI.get(`/deals/dashboard-2/`),
    {
      ...options,
      select: (data) => data.data,
    }
  );
};

export const useDealsClosingAndStage = (options) => {
  return useQuery(
    "db-deals-c-s",
    async () => await tenantAPI.get(`/deals/dashboard-3/`),
    {
      ...options,
      select: (data) => data.data,
    }
  );
};

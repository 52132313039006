import React, { useEffect } from "react";

import { Grid, LinearProgress, Typography } from "@mui/material";

import AuthWrapper from "../AuthWrapper";
import PersonalInfo from "./PersonalInfo";

import { useSelector } from "react-redux";
import SuccessInfo from "./SuccessInfo";
import CompanyInfo from "./CompanyInfo";
import verifyDomain from "../../../utils/verifyDomain";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const { pageNo } = useSelector((state) => state.authStore);
  const navigate = useNavigate();

  useEffect(() => {
    if (verifyDomain()) {
      navigate("/my-crm/log-in", { replace: true });
    }
  }, [navigate]);

  if (verifyDomain()) {
    return null;
  }
  return (
    <AuthWrapper>
      <Grid item md={4} className="bg-light p-5">
        <Grid item xs={12}>
          <Typography
            component="h2"
            varient="h1"
            fontSize="22px"
            fontWeight="Medium"
            className="text-center mb-1"
          >
            {pageNo === 1
              ? "Step 1 of 2"
              : pageNo === 2
              ? "Step 2 of 2"
              : "Thank You"}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={pageNo === 1 ? 50 : pageNo === 2 ? 100 : 100}
            color="secondary"
          />
        </Grid>

        {pageNo === 1 ? (
          <PersonalInfo />
        ) : pageNo === 2 ? (
          <CompanyInfo />
        ) : (
          <SuccessInfo />
        )}
      </Grid>
    </AuthWrapper>
  );
};

export default Register;

import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  closeProductDailog,
  openProductDialog,
} from "../../../../redux/actions/LayoutAction";

const useCreateProduct = () => {
  const dispatch = useDispatch();
  const FORM_VALIDATION = Yup.object().shape({
    id: Yup.string(),
    product_id: Yup.number().positive(),
    // variant_id: Yup.number().positive().required("This Feild is Required"),
    sku: Yup.string().required("This Feild is Required"),
    // category: Yup.string().required("This Feild is Required"),
    // supplier_id: Yup.number().positive().required("This Feild is Required"),
    sub_category: Yup.string().required("This Feild is Required"),
    // sub_sub_category: Yup.string().required("This Feild is Required"),
    // supplier_name: Yup.string().required("This Feild is Required"),
    // company_name: Yup.string().required("This Feild is Required"),
    brand: Yup.string().required("This Feild is Required"),
    title: Yup.string().required("This Feild is Required"),
    product_type: Yup.string().required("This Feild is Required"),
    description: Yup.string(),
    price_consumer: Yup.number().positive(),
    price: Yup.number().positive().required("This Feild is Required"),
    // consumer_discount: Yup.number().positive().required("This Feild is Required"),
    base_price: Yup.number().positive().required("This Feild is Required"),
    // effective_mrp: Yup.number().positive().required("This Feild is Required"),
    cgst: Yup.number().positive().required("This Feild is Required"),
    sgst: Yup.number().positive().required("This Feild is Required"),
    igst: Yup.number().positive().required("This Feild is Required"),
  });
  const handleClickOpen = () => {
    dispatch(openProductDialog());
  };

  const handleClose = () => {
    dispatch(closeProductDailog());
  };

  return {
    FORM_VALIDATION,
    handleClickOpen,
    handleClose,
  };
};

export default useCreateProduct;

import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useDealStage from "../../../pages/deal/dealHooks/useDealStage";
import { useUpdateDeal } from "../../../redux/actions/dealAction";
import AutoSelectFieldNf from "../../formsUI/withoutFormik/AutoCompleteNf";

const DealStatus = () => {
  const params = useParams();
  const { deal } = useSelector((state) => state.dealStore);

  const { data: deal_stage } = useDealStage();

  const [status, setStatus] = useState("");
  const { mutate } = useUpdateDeal(params.uuid);

  useEffect(() => {
    if (deal?.data?.status) {
      setStatus(deal?.data?.status);
    }
  }, [deal?.data?.status]);

  const onChange = (e, value) => {
    setStatus(value);
    mutate({ name: deal.data.name, status: value?.id });
  };

  return (
    <Grid
      container
      className="py-2"
      sx={{
        borderBottom: "1px solid #d1cac4",
        // padding: 1,
      }}
      display="grid"
      direction="column"
      alignItems="center"
    >
      <Grid item xs={12} alignItems="center">
        <Box className="d-flex flex-column align-items-start">
          <Typography
            style={{ fontSize: "12px", fontWeight: 500, color: "#616161" }}
            component="p"
          >
            Deal Stage
          </Typography>
          <AutoSelectFieldNf
            name="stage_name"
            style={{ background: "#E8F8FC" }}
            getOptionLabel={(options) => options?.stage_name}
            onChange={onChange}
            options={deal_stage ? [...deal_stage] : []}
            value={
              status ||
              (deal?.data
                ? {
                    stage_name: deal?.data?.status?.stage_name,
                    id: deal?.data?.status.id,
                  }
                : { stage_name: "" })
            }
            disableClearable
          />
        </Box>
        <Box className="d-flex flex-column align-items-start">
          <Typography
            style={{ fontSize: "12px", fontWeight: 500, color: "#616161" }}
            component="p"
            className="mt-2"
          >
            Win Probability
          </Typography>
          <Typography component="p">10%</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DealStatus;

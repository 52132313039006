const initialState = {
  search: "",
};

export const commonReducer = (state = initialState, action) => {
  const reducer = {
    SET_SEARCH: () => {
      return {
        ...state,
        search: action.payload,
      };
    },
  };

  return reducer[action?.type] ? reducer[action?.type]() : state;
};

import * as Yup from "yup";

const useLogIn = () => {
  const loginState = {
    password: "",
    email: "",
  };

  const loginValidation = Yup.object().shape({
    password: Yup.string().required("This field is required"),
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   () => (
    //     <>
    //       <span>Your Password Must contaoin following :-</span>
    //       <ul>
    //         <li>Must Contain 8 Characters</li>
    //         <li> One Uppercase</li> <li>One Lowercase</li>{" "}
    //         <li>One Number </li> <li>one special case Character</li>
    //       </ul>
    //     </>
    //   )
    // ),
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("This field is required"),
  });
  return { loginState, loginValidation };
};

export default useLogIn;

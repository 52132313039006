import { useState } from "react";

const useProductDrower = (data, setContextMenu) => {
  const [state, setState] = useState(false);
  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setContextMenu && setContextMenu(null);

    setState(!state);
  };

  const INITIAL_FORM_STATE = {
    id: data?.id ? data?.id : "",
    product_id: data?.product_id ? data?.product_id : "",
    variant_id: data?.variant_id ? data?.variant_id : "",
    sku: data?.sku ? data?.sku : "",
    category: data?.category ? data?.category : "",
    supplier_id: data?.supplier_id ? data?.supplier_id : "",
    sub_category: data?.sub_category ? data?.sub_category : "",
    sub_sub_category: data?.sub_sub_category ? data?.sub_sub_category : "",
    supplier_name: data?.supplier_name ? data?.supplier_name : "",
    company_name: data?.company_name ? data?.company_name : "",
    brand: data?.brand ? data?.brand : "",
    title: data?.title ? data?.title : "",
    product_type: data?.product_type ? data?.product_type : "",
    description: data?.description ? data?.description : "",
    price: data?.price ? data?.price : "",
    price_consumer: data?.price_consumer ? data?.price_consumer : "",
    consumer_discount: data?.consumer_discount ? data?.consumer_discount : "",
    base_price: data?.base_price ? data?.base_price : "",
    effective_mrp: data?.effective_mrp ? data?.effective_mrp : "",
    cgst: data?.cgst ? data?.cgst : "",
    sgst: data?.sgst ? data?.sgst : "",
    igst: data?.igst ? data?.igst : "",
  };

  return {
    state,
    INITIAL_FORM_STATE,
    toggleDrawer,
  };
};

export default useProductDrower;

import React from "react";
import DashboardCard from "../../../components/home/DashboardCard";
import { Divider, IconButton, MenuItem, Typography } from "@mui/material";
import { Phone, MailOutline, PlayForWork, Edit } from "@mui/icons-material";
import { useNewAndHotDeals } from "../homeHooks/useHomeData";
import PhoneMenu from "../../../components/ivr/PhoneMenu";
import RightClickMenu from "../../../components/lead/RightClickMenu";
import { useLeadDelete } from "../../../redux/actions/leadActions";
import ConvertToContact from "../../contact/convertToContact";
import DeleteDialog from "../../../components/utils/deleteDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useNavigate } from "react-router-dom";
// import LeadDrawer from "../../../pages/lead/leadDrower/index";

function NewLeads() {
  const { mutate: deleteLead } = useLeadDelete();
  const navigate = useNavigate();
  const { data } = useNewAndHotDeals();
  const newLeads = data?.newLeads;

  // const useWantDrawer = (data) => {
  //   return LeadDrawer({ data: data, name: "ksdsk" });
  // };

  return (
    <DashboardCard
      sx={{
        padding: "15px 20px 15px 13px",
        height: "400px",
      }}
    >
      <div className="d-flex justify-content-between">
        <span>
          <PlayForWork style={{ fontSize: "23px", marginRight: "8px" }} />
          <span
            style={{
              fontSize: "14px",
              fontWeight: "500",
              verticalAlign: "middle",
            }}
          >
            New Leads
          </span>
        </span>
        <span style={{ fontSize: "15px", fontWeight: "500" }}>
          {newLeads?.length}
        </span>
      </div>
      <Divider className="mt-2" />
      <div style={{ height: "350px", overflowY: "auto", paddingRight: "10px" }}>
        {newLeads?.map((lead) => (
          <RightClickMenu
            name={
              <>
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "6px 0px 0px 10px" }}
                >
                  <Typography
                    fontSize="13px"
                    fontWeight="400"
                    component="p"
                    className="my-auto"
                  >
                    {`${lead.first_name} ${lead.last_name}`}
                  </Typography>
                  <IconButton size="small">
                    <MailOutline style={{ color: "black", fontSize: "13px" }} />
                  </IconButton>
                </div>
                <div
                  className="d-flex justify-content-between"
                  style={{
                    padding: "0px 0px 10px 13px",
                    borderBottom: "0.5px solid rgba(150, 150, 150, 0.5)",
                  }}
                >
                  <Typography
                    className="second my-auto"
                    fontSize="11px"
                    fontWeight="400"
                    component="p"
                  >
                    {lead.company_name}
                  </Typography>
                  <PhoneMenu
                    name={
                      <IconButton size="small">
                        <Phone style={{ color: "black", fontSize: "13px" }} />
                      </IconButton>
                    }
                    phNum={lead.primary_number}
                  />
                </div>
              </>
            }
          >
            <MenuItem
              sx={{ fontSize: "13px" }}
              onClick={() => {
                navigate(`/leads`);
              }}
              // onClick={() => {
              //   useWantDrawer(lead);
              // }}
            >
              <Edit fontSize="18" className="me-2" />
              Edit Lead
            </MenuItem>
            <ConvertToContact data={lead} />
            <DeleteDialog
              mutate={deleteLead}
              uuid={lead.uuid}
              description="This Lead will be permanantly deleted."
              element={
                <MenuItem sx={{ fontSize: "13px" }}>
                  <DeleteForeverIcon fontSize="18" className="me-2" />
                  Delete
                </MenuItem>
              }
            />
          </RightClickMenu>
        ))}
      </div>
    </DashboardCard>
  );
}

export default NewLeads;

import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";

const Breadcrumb = ({ module, name }) => {
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          style={{ color: "#274C77", fontWeight: 500, fontSize: "16px" }}
        >
          {module}
        </Typography>
        <Typography
          style={{ color: "#274C77", fontWeight: 500, fontSize: "16px" }}
        >
          {name}
        </Typography>
      </Breadcrumbs>
    </>
  );
};

export default Breadcrumb;

import { useQuery } from "react-query";
import axios from "axios";
import moment from "moment";

const useClickToCall = (phNum) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];
  return useQuery(
    "clickToCall",
    async () =>
      await axios.get(`http://180.179.210.14/Api/ClickCallReq.aspx`, {
        params: {
          uname: 20200078,
          pass: "DEMO@78",
          reqid: 1,
          aparty: userData?.phone.replace(/-/g, "").slice(-10),
          bparty: phNum.replace(/-/g, "").slice(-10),
          schtm: moment().utcOffset("+05:30").format("YYYY-MM-DD HH:mm"),
        },
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

export default useClickToCall;

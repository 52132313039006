const initialState = {
  products: [],
};

export const productReducer = (state = initialState, action) => {
  const reducer = {
    SET_PRODUCT_DATA: () => {
      return {
        ...state,
        products: action.payload,
      };
    },
  };

  return reducer[action?.type] ? reducer[action?.type]() : state;
};

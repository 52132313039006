import { Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  useTaskDelete,
  useTaskUpdate,
} from "../../../../../redux/actions/dealAction";
import EditComponent from "../../../../Layout/edit/EditComponent";
import AddTaskForm from "./AddTaskForm";
import useUpdateTask from "./useUpdateTask";

const TaskAccordion = ({ data }) => {
  const params = useParams();

  const { TASK_UPDATE_INITIAL_FORM_STATE, TASK_FORM_VALIDATION } =
    useUpdateTask(data);
  const { mutate } = useTaskUpdate(data.uuid);
  const { mutate: deleteMutate } = useTaskDelete(params.uuid);

  return (
    <>
      <Grid container className="">
        <Grid item xs={12}>
          <Grid item xs={12} className="d-flex align-items-center">
            <Grid item xs={6}>
              <Typography
                style={{ fontWeight: 500, fontSize: "12px", color: "#000" }}
                component="p"
              >
                {data?.task_title}
              </Typography>
            </Grid>
            <Grid item xs={6} className="d-flex justify-content-end">
              <span style={{ color: "#000000" }}>
                <EditComponent
                  mutate={mutate}
                  edit="Edit"
                  clear="Delete"
                  heading="Update Task"
                  showdelete={true}
                  deleteDescription={"This Task will be permanantly deleted."}
                  onClickEdit=""
                  onClickDelete=""
                  uuid={data?.uuid}
                  deleteMutate={deleteMutate}
                  showShare={false}
                  Component={AddTaskForm}
                  initialValues={TASK_UPDATE_INITIAL_FORM_STATE}
                  validationSchema={TASK_FORM_VALIDATION}
                />
              </span>
            </Grid>
          </Grid>
          <Typography
            style={{ fontWeight: 500, fontSize: "12px", color: "#616161" }}
            component="p"
          >
            {data?.description}
          </Typography>
          <Link to="">
            <Typography
              style={{ fontWeight: 500, fontSize: "12px", color: "#000" }}
              component="p"
              className="mt-3"
            >
              {data?.related_links}
            </Typography>
          </Link>
          <Typography
            style={{ fontWeight: 500, fontSize: "12px", color: "#000" }}
            component="p"
          >
            Due Date:- {data?.due_date}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TaskAccordion;

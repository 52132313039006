import { DateTimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";

const DateAndTImeInput = ({ name, className, label, ...otherProps }) => {
  const [field, meta] = useField(name);
  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
    size: "small",
  };
  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    setFieldValue(name, new Date());
    // eslint-disable-next-line
  }, []);
  return (
    <DateTimePicker
      type="date"
      label={label}
      value={configTextfield.value}
      onChange={(newValue) => {
        setFieldValue(name, newValue);
      }}
      renderInput={(params) => (
        <TextField type="date" {...params} hiddenLabel {...configTextfield} />
      )}
    />
  );
};

export default DateAndTImeInput;

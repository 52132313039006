import React, { useState } from "react";

import {
  Grid,
  InputAdornment,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";

import { Form, Formik } from "formik";

import SubmitButton from "../../../../formsUI/SubmitButton";
import TextEditor from "../../../../Layout/Editor/RichTextEditor";
import InputField from "../../../../formsUI/InputField";
import useSendEmail from "./hooks/useSendEmail";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const SendEmailBox = () => {
  const { contact } = useSelector((state) => state?.contactStore);
  const [isCc, setIsCc] = useState(false);
  const [isBcc, setIsBcc] = useState(false);
  const [customTo, setCustomTo] = useState("");
  const [customCc, setCustomCc] = useState("");
  const [customBcc, setCustomBcc] = useState("");

  const defaultState = {
    to: [],
    cc: [],
    bcc: [],
    subject: "",
    body: "",
  };

  const { mutate } = useSendEmail();

  const validation = Yup.object().shape({
    to: Yup.array()
      .of(Yup.string().email())
      .min(1, "This field is required")
      .required("This field is required"),
    cc: Yup.array().of(Yup.string().email()),
    bcc: Yup.array().of(Yup.string().email()),
    subject: Yup.string().required("This field is required"),
    body: Yup.string().required("This field is required"),
  });

  let Options = [];
  if (contact?.primary_email)
    Options.push({
      name: contact?.first_name,
      label: "primary",
      value: contact?.primary_email,
    });
  if (contact?.secondary_email)
    Options.push({
      name: contact?.first_name,
      label: "secondary",
      value: contact?.secondary_email,
    });

  return (
    <Formik
      initialValues={{ ...defaultState }}
      validationSchema={validation}
      onSubmit={async (values, { resetForm }) => {
        mutate(values, {
          onSuccess: () => {
            resetForm();
          },
        });
      }}
    >
      {({ errors, setFieldValue, values }) => (
        <>
          <Form style={{ width: "100%" }}>
            <Grid container direction="row" className="mb-2">
              <Grid
                item
                xs={4}
                sm={1}
                md={1}
                className="d-flex align-items-center ps-2"
              >
                <Typography color="black">To:</Typography>
              </Grid>
              <Grid item xs={8} sm={9} md={9}>
                <Autocomplete
                  name="to"
                  width="100%"
                  multiple
                  freeSolo
                  id="tags-standard"
                  options={Options}
                  value={values?.to}
                  groupBy={(option) => option.name}
                  getOptionLabel={(option) =>
                    typeof option === "string"
                      ? option
                      : `${option.label} - ${option.value}`
                  }
                  onChange={(e, value) => {
                    if (typeof value[value.length - 1] === "string")
                      setCustomTo("");
                    setFieldValue(
                      "to",
                      value.map((item) =>
                        typeof item === "string" ? item : item.value
                      )
                    );
                  }}
                  onClose={(e, reason) => {
                    if (reason === "blur") {
                      setCustomTo(e.target.value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
                <Typography
                  fontSize="small"
                  color="red"
                  hidden={customTo.length === 0}
                >
                  Press enter to add {customTo}
                </Typography>
                {errors.to ? (
                  <Typography fontSize="small" color="red">
                    {Array.isArray(errors.to)
                      ? "Please enter a valid email"
                      : errors.to}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                sx={{mt:{xs:1}}}
                className="d-flex justify-content-evenly"
              >
                <Button
                  size="small"
                  onClick={() => setIsCc(!isCc)}
                  sx={{ minWidth: "35px", padding: "0px" }}
                  variant={isCc ? "contained" : "text"}
                >
                  {"Cc"}
                </Button>
                <Button
                  size="small"
                  onClick={() => setIsBcc(!isBcc)}
                  sx={{ minWidth: "35px", padding: "0px" }}
                  variant={isBcc ? "contained" : "text"}
                >
                  {"Bcc"}
                </Button>
              </Grid>
              {isCc && (
                <>
                  <Grid
                    item
                    xs={6}
                    sm={1}
                    md={1}
                    className="d-flex align-items-center ps-2 mt-2"
                  >
                    <Typography textAlign="center" color="black">
                      Cc:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} className="mt-2">
                    <Autocomplete
                      name="cc"
                      width="100%"
                      multiple
                      freeSolo
                      id="tags-standard"
                      options={Options}
                      value={values?.cc}
                      groupBy={(option) => option.name}
                      getOptionLabel={(option) =>
                        typeof option === "string"
                          ? option
                          : `${option.label} - ${option.value}`
                      }
                      onChange={(e, value) => {
                        if (typeof value[value.length - 1] === "string")
                          setCustomCc("");
                        setFieldValue(
                          "cc",
                          value.map((item) =>
                            typeof item === "string" ? item : item.value
                          )
                        );
                      }}
                      onClose={(e, reason) => {
                        if (reason === "blur") {
                          setCustomCc(e.target.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                    <Typography
                      fontSize="small"
                      color="red"
                      hidden={customCc.length === 0}
                    >
                      Press enter to add {customCc}
                    </Typography>
                    {errors.cc ? (
                      <Typography fontSize="small" color="red">
                        {Array.isArray(errors.cc)
                          ? "Please enter a valid email"
                          : errors.cc}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item  sm={2} md={2} className="mt-2"></Grid>
                </>
              )}
              {isBcc && (
                <>
                  <Grid
                    item
                    xs={6}
                    sm={1}
                    md={1}
                    className="d-flex align-items-center ps-2 mt-2"
                  >
                    <Typography color="black">Bcc:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} className="mt-2">
                    <Autocomplete
                      name="bcc"
                      width="100%"
                      multiple
                      freeSolo
                      id="tags-standard"
                      options={Options}
                      value={values?.bcc}
                      groupBy={(option) => option.name}
                      getOptionLabel={(option) =>
                        typeof option === "string"
                          ? option
                          : `${option.label} - ${option.value}`
                      }
                      onChange={(e, value) => {
                        if (typeof value[value.length - 1] === "string")
                          setCustomBcc("");
                        setFieldValue(
                          "bcc",
                          value.map((item) =>
                            typeof item === "string" ? item : item.value
                          )
                        );
                      }}
                      onClose={(e, reason) => {
                        if (reason === "blur") {
                          setCustomBcc(e.target.value);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                    <Typography
                      fontSize="small"
                      color="red"
                      hidden={customBcc.length === 0}
                    >
                      Press enter to add {customBcc}
                    </Typography>
                    {errors.bcc ? (
                      <Typography fontSize="small" color="red">
                        {Array.isArray(errors.bcc)
                          ? "Please enter a valid email"
                          : errors.bcc}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item sm={2} md={2} className="mt-2"></Grid>
                </>
              )}
            </Grid>

            <InputField
              // hiddenLabel
              sx={{marginTop:{xs:"10px"}}}
              id="filled-hidden-label-small"
              fullWidth={true}
              variant="standard"
              name="subject"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className="ps-2"
                    sx={{ width: "auto"}}
                  >
                    <Typography color="black">Subject:</Typography>
                  </InputAdornment>
                ),
                endAdornment: (
                  <Button className="text-capitalize" size="small">
                    Template
                  </Button>
                ),
              }}
            />

            <TextEditor name="body" />

            <div className="d-flex justify-content-between mt-2 ">
              <Button
                variant="outlined"
                size="small"
                style={{
                  // paddingInline: "15px",
                  paddingBlock: "0px",
                }}
              >
                Reset
              </Button>
              <SubmitButton
                variant="contained"
                type="submit"
                className="text-capitalize bg-first fifth px-3 py-1 ms-auto d-block "
                style={{
                  paddingInline: "15px",
                  paddingBlock: "0px",
                }}
              >
                Send
              </SubmitButton>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default SendEmailBox;

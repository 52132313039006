const initialState = {
  contact: {
    address: "-",
    billing_address: "-",
    company_name: "-",
    created_on: "-",
    description: "-",
    facebook: "-",
    fax_number: "-",
    first_name: "-",
    gst_number: "-",
    industry: "-",
    last_name: "-",
    linkedin: "-",
    middle_name: "-",
    owner: "-",
    primary_email: "-",
    primary_number: "-",
    secondary_email: "-",
    secondary_number: "-",
    shipping_address: "-",
    skype: "-",
    source: "-",
    status: "-",
    tags: "-",
    title: "-",
    twitter: "-",
    updated_on: "-",
    uuid: "-",
    website: "-",
    work_number: "-",
  },
  contacts: [],
};

export const contactReducer = (state = initialState, action) => {
  const reducer = {
    SET_CONTACT_DATA: () => {
      return {
        ...state,
        contacts: action.payload,
      };
    },
    SET_SINGLE_CONTACT: () => {
      return {
        ...state,
        contact: action.payload,
      };
    },
  };

  return reducer[action?.type] ? reducer[action?.type]() : state;
};

// import { AddSharp } from "@mui/icons-material";
import { LinearProgress, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React from "react";
import { BiRupee } from "react-icons/bi";
import ConvertToDeal from "../../pages/deal/convertToDeal";
import { BsCircleFill } from "react-icons/bs";

const PiplineCardHeading = ({
  name,
  valuation,
  totalDeals,
  dealsInStage,
  item,
  color,
}) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        height: "10px",
        borderRadius: "2px",
        "& .css-1ofock6-MuiLinearProgress-bar1": {
          backgroundColor: color,
        },
        "& .css-m9i9ov-MuiLinearProgress-root": {
          backgroundColor: color === "#acbacb" ? "#fff" : "#acbacb",
          border: color === "#acbacb" ? "2px solid #000" : "",
        },
      },
    })
  );

  const styles = useStyles();
  return (
    <Grid
      container
      className="p-3"
      style={{
        borderBottom: "2px solid #274C77",
        borderRadius: "7px 7px 0px 0px",
      }}
    >
      <Grid item xs={9}>
        <Typography
          fontWeight="Medium"
          fontSize="14px"
          className="text-truncate"
          component="p"
        >
          {name} <BsCircleFill className="ms-2" fontSize="8px" color={color} />
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography
          variant="caption"
          fontSize="10px"
          component="p"
          className="text-end"
        >
          {/* <AddSharp className="bg-fifth rounded fs-6" /> */}
          {`${dealsInStage}/${totalDeals}`}
        </Typography>
      </Grid>
      <Grid item xs={12} className="pt-3">
        <LinearProgress
          className={styles.root}
          variant="determinate"
          value={(dealsInStage / totalDeals) * 100}
        />
      </Grid>
      <Grid item xs={11} className="pt-1 d-flex align-items-center">
        <span style={{ fontWeight: 400, fontSize: "14px" }}>
          <BiRupee className="d-flex align-items-center justify-content-center" />
        </span>
        <Typography
          varient="h1"
          component="p"
          fontSize="12px"
          className="text-truncate"
        >
          {valuation}
        </Typography>
      </Grid>
      <Grid item xs={1} className="pt-1 d-flex justify-content-end">
        <ConvertToDeal needButton={true} fixedStage={item} />
      </Grid>
    </Grid>
  );
};

export default PiplineCardHeading;

const { toast } = require("react-toastify");

const errorHandle = (err) => {
  toast.dismiss();
  if (err?.response) {
    console.log(err.response);
    if (err?.response?.status === 404) {
      const msg = err?.response?.data?.msg || `404 Not Found!`;
      return toast.error(msg);
    } else if ([500, 400].includes(err?.response?.status)) {
      if (err.response?.data?.error) {
        return toast.error(err.response?.data?.error);
      }
      if (err.response?.data?.msg) {
        let msg = Array.isArray(err.response?.data?.msg)
          ? err.response.data.msg[0]
          : err.response.data.msg || "Something went wrong!"; //this part is correct
        return toast.error(msg);
      }

      if (err.response?.data) {
        let msg =
          typeof err.response?.data === "object"
            ? Object.values(err.response?.data).reduce(
                (prev, curr) =>
                  Array.isArray(curr)
                    ? [...prev, ...curr]
                    : [
                        ...prev,
                        ...Object.values(curr).reduce(
                          (p, c) => [...p, ...c],
                          []
                        ),
                      ],
                []
              ) || "Something went wrong!"
            : err.response.data.msg || "Something went wrong!"; //this part is correct
        return toast.error(Array.isArray(msg) ? msg[0] : msg);
      }
    }
  } else {
    return toast.error(err.message || "Something went wrong!");
  }
};

export default errorHandle;

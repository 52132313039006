import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import Table from "./table/index";
import { nanoid } from "nanoid";

const CSVPreview = ({ file }) => {
  const [data, setData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray = [];

          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
            return 0;
          });

          let columns = rowsArray[0]?.map((item, pos) => ({
            field: item,
            headerName: item.replace("_", " "),
            minWidth: 150,
            hide: pos > 10 ? true : false,
            headerClassName: "text-capitalize full-width",
            cellClassName: "ps-3 p-0",
            flex: 1,
          }));

          setTableColumns(columns);

          let data = valuesArray?.map((item) => {
            let obj = {};
            columns?.length > 0 &&
              columns?.forEach((val, index) => (obj[val?.field] = item[index]));
            obj.id = nanoid();
            return obj;
          });

          setData(data);
        },
      });
    }
  }, [file]);

  return (
    <div style={{ width: "100%" }}>
      {!show && (
        <button className="my-3 import-button" onClick={() => setShow(true)}>
          Preview
        </button>
      )}

      {show && (
        <>
          {data?.length > 0 && (
            <Table
              className="p-2"
              style={{ width: "100%" }}
              columns={tableColumns ? tableColumns : []}
              data={data ? data : []}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CSVPreview;

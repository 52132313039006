import React, { useEffect, useState } from "react";

import Container from "../../../components/Layout/Container";

import Header from "../../../components/Layout/Header";
import Table from "../../../components/Layout/table";

import useContactTable from "./useContactTable";
import { useDispatch } from "react-redux";
import { openContactDialog } from "../../../redux/actions/LayoutAction";
import { leadAndContactColumns } from "../../../constants/columns";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import useContactData from "../contactHooks/useContactData";

const ContactTable = () => {
  const { createColumnSchema } = useContactTable();
  const [contactData, setContactData] = useState([]);
  const { isLoading } = useContactData({
    onSuccess: (data) => setContactData(data?.data),
  });

  const tableRef = useGridApiRef();

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(openContactDialog());
  };

  const OnExport = () => {
    tableRef?.current?.exportDataAsCsv();
  };

  useEffect(() => {
    document.title = "All Contacts | CRM";
  }, []);

  return (
    <>
      <Header
        name="Contacts"
        showSearch={true}
        exportButton
        exportOnClick={OnExport}
        addBtnClick={handleClickOpen}
      />
      <Container sx={{ marginTop: "30px" }}>
        <Table
          mt={"30px"}
          data={contactData ? contactData : []}
          apiRef={tableRef}
          loading={isLoading}
          columns={
            contactData && contactData[0]
              ? createColumnSchema(contactData[0])
              : leadAndContactColumns
          }
        />
      </Container>
    </>
  );
};

export default ContactTable;

import React from "react";

import { Grid, Typography, Box } from "@mui/material";

import { useSelector } from "react-redux";
import DisplayAddress from "./DisplayAddress";

const AccountAddress = () => {
  const { account } = useSelector((state) => state.accountStore);

  return (
    <Grid
      container
      sx={{
        borderTop: "0.5px solid #C2C2C2",
        marginTop: "5px",
      }}
      direction="row"
    >
      <Grid item xs={12} alignItems="center" sx={{ marginTop: "10px" }}>
        <Box
          className="d-flex align-items-end"
          sx={{
            maxWidth: "90%",
          }}
        >
          <Typography
            variant="p"
            fontSize="14px"
            sx={{
              color: "#616161",
              marginRight: "5px",
              fontWeight: "medium",
              inlineSize: "100%",
              overflowWrap: "break-word",
            }}
          >
            Address -
          </Typography>
        </Box>
      </Grid>
      <DisplayAddress
        data={[
          { add: account?.address, label: "" },
          { add: account?.billing_address, label: "Billing Address" },
          { add: account?.shipping_address, label: "Shipping Address" },
        ]}
      />
    </Grid>
  );
};

export default AccountAddress;

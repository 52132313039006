const sideBarMenu = {
  Reports: [
    {
      name: "Sales",
      path: "/reports/sales",
    },
    {
      name: "Deal Pipeline",
      path: "/reports/deal-pipeline",
    },
    {
      name: "Lead",
      path: "/reports/lead",
    },
    {
      name: "Product",
      path: "/reports/product",
    },
  ],
};

export default sideBarMenu;

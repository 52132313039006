import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { useSelector } from "react-redux";
import TemplatesDialog from "./components/TemplatesDialog";
import useSendMessage from "../../../../../redux/actions/whatsappAction";
import { toast } from "react-toastify";

const ComposeWhatsappMsg = () => {
  const { contact } = useSelector((state) => state.contactStore);
  const [messageHTML, setMessageHTML] = useState("");
  const [contacts, setContacts] = useState([]);

  const [message, setMessage] = useState({
    message: {
      template_name: "",
      broadcast_name: "",
      parameters: [],
    },
  });

  const { mutate } = useSendMessage();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClickClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    setDialogOpen(false);
    console.log(message);
    setMessageHTML(
      message.messageHTML
        ? message.messageHTML
        : "<span>Select a message from the templates</span>"
    );
  }, [message]);

  useEffect(() => {
    document.querySelector(".putMessage").innerHTML = messageHTML;
  }, [messageHTML]);

  console.log(contacts, "<=-------contacts");

  return (
    <>
      <Grid container direction="row" className="mb-2">
        <Grid
          item
          xs={4}
          sm={1}
          md={1}
          className="d-flex align-items-center ps-2"
        >
          <Typography color="black">To:</Typography>
        </Grid>
        <Grid item xs={8} sm={9} md={9}>
          <Autocomplete
            name="to"
            width="100%"
            multiple
            id="tags-standard"
            options={[
              {
                label: contact.first_name + " " + contact.last_name,
                value: contact.whatsapp,
              },
            ]}
            renderInput={(params) => (
              <TextField {...params} variant="standard" />
            )}
            onChange={(event, value) => {
              value.map((person) => {
                setContacts([person.value]);
                return 0;
              });
            }}
          />
        </Grid>
        <Grid item xs={3} sm={2} md={2}>
          <TemplatesDialog
            dialogOpen={dialogOpen}
            handleClickClose={handleClickClose}
            message={message}
            setMessage={setMessage}
          />
          <Button
            className="text-capitalize ms-2"
            size="small"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            Template
          </Button>
        </Grid>
      </Grid>
      <div
        className="mt-3 pt-2 pb-3 px-2 border-bottom putMessage"
        style={{ fontSize: "14px" }}
      ></div>
      <div className="d-flex justify-content-between mt-2">
        <Button
          variant="outlined"
          size="small"
          style={{
            // paddingInline: "15px",
            paddingBlock: "0px",
          }}
        >
          Discard
        </Button>
        <Button
          variant="contained"
          className="text-capitalize bg-first fifth px-3 py-1 ms-auto d-block "
          style={{
            paddingInline: "15px",
            paddingBlock: "0px",
          }}
          onClick={() => {
            if (!contacts.length) {
              toast.error("Please select a contact");
              return;
            }
            if (!message.message.template_name) {
              toast.error("Please select a template");
              return;
            }
            const data = {
              message: message.message,
              phNum: contacts,
            };
            mutate(data);
          }}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default ComposeWhatsappMsg;

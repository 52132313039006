import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthWrapper = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("domain")) {
      navigate("/");
    }
    //eslint-disable-next-line
  }, []);
  return (
    <Grid
      container
      sx={{ height: "100vh" }}
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="bg-forth"
    >
      {children}
    </Grid>
  );
};

export default AuthWrapper;

import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { IoMdClose } from "react-icons/io";

const TemplateDialog = ({ dialogOpen, handleClickClose, children }) => {
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        scroll="paper"
        open={dialogOpen}
        aria-labelledby="scroll-Dialog-title"
        aria-describedby="scroll-Dialog-content"
      >
        <DialogTitle
          sx={{
            background: "#ededed",
            position: "sticky",
            top: "0",
            right: "0",
            zIndex: 10,
          }}
          className="border-bottom border-dark shadow-sm d-flex justify-content-between"
          id="scroll-Dialog-title"
        >
          Choose Template
          <IconButton onClick={handleClickClose}>
            <IoMdClose />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default TemplateDialog;

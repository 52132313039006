import { Typography } from "@mui/material"
import React from "react"
import Chart from "react-apexcharts"

import DashboardCard from "../../../components/home/DashboardCard"
import { useDealsClosingAndStage } from "../homeHooks/useHomeData"
import { useNavigate } from "react-router-dom"

function DealStageReport() {
  const { data } = useDealsClosingAndStage()
  const dealsStageData = data?.dealStageReport

  const navigate = useNavigate()

  let options = {
    chart: {
      type: "donut",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(event, "--event")
          if (event?.detail === 2) {
            navigate({
              pathname: "/deals",
              search: `?select=${
                config.w.config.labels[config.dataPointIndex]
              }`,
            })
          }
        },
      },

      toolbar: {
        export: {
          csv: {
            filename: "Deal-Stage-Report",
          },
        },
      },
    },
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false
    },
    labels: dealsStageData
      ? dealsStageData.map((stage) => stage.status)
      : ["Loading"],
    colors: dealsStageData
      ? dealsStageData.map((stage) => stage.colour)
      : [
          "#2E2E2E",
          "#858585",
          "#D1D1D1",
          "#8E8E8E",
          "#646464",
          "#274c77c2",
          "#274c77",
          "#FF0000",
          "#8E8E8E",
        ],

    responsive: [
      {
        breakpoint: 480,
        options: {
          // chart: {
          //   width: 200,
          // },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  }

  var seriesData = dealsStageData
    ? dealsStageData.map((stage) => stage.count)
    : [10]
  // below is the data for static test
  // seriesData = [10, 10, 10, 10, 10, 10, 10, 10];

  return (
    <DashboardCard
      sx={{
        padding: "10px",
        height: "410px",
      }}
    >
      <Typography component="p" fontSize="16px" fontWeight="500">
        Deal Stage Report
      </Typography>
      <div>
        <Typography
          fontSize="12px"
          fontWeight="500"
          component="p"
          className="second"
        >
          Current Month - June
        </Typography>
        <Chart
          options={options}
          series={seriesData}
          type="donut"
          height={300}
          width={300}
        />
        <Typography
          fontSize="12px"
          fontWeight="500"
          component="p"
          className="second pt-2"
          marginTop="10px"
        >
          View Deal Stage Report
        </Typography>
      </div>
      <div />
    </DashboardCard>
  )
}

export default DealStageReport

const initialState = { deals: [], deal: null };

export const dealReducer = (state = initialState, action) => {
  const reducer = {
    SET_DEAL_DATA: () => {
      return {
        ...state,
        deals: action.payload,
      };
    },
    SET_SINGLE_DEAL: () => {
      return {
        ...state,
        deal: action.payload,
      };
    },
  };

  return reducer[action?.type] ? reducer[action?.type]() : state;
};

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const DealDiscription = () => {
  const { deal } = useSelector((state) => state.dealStore);

  return (
    <Grid
      container
      display="grid"
      direction="column"
      alignItems="center"
      className="pt-3"
    >
      <Grid item xs={12} alignItems="center">
        <Box className="d-flex flex-column align-items-start">
          <Typography
            style={{ fontSize: "12px", fontWeight: 500, color: "#616161" }}
            component="p"
          >
            Description
          </Typography>
          <Typography
            component="p"
            style={{ fontSize: "12px", fontWeight: 500 }}
          >
            {deal?.data?.description}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DealDiscription;

import React, { useRef } from "react";
import PropTypes from "prop-types";

import { useHotkeys } from "react-hotkeys-hook";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import {
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

import { createTheme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import KeyboardBadge from "../KeyboardBadge";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: "none !important",
        // display: "flex",
        padding: "10px",
        paddingBottom: 0,
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
        color: "#576a77",
        background: "#E1F3FA",
        "& .MuiPaper-root ": {
          background: "red",
        },
        "& .MuiDataGrid-panel": {
          right: "0 !important",
        },
        "& .css-d4cai4-MuiInputBase-root-MuiInput-root": {
          paddingBottom: 5,
        },
        "& .MuiGrid-root:nth-child(2)": {
          justifyContent: "flex-end",
          [theme.breakpoints.down("sm")]: {
            justifyContent: "space-between",
          },
        },
        "& .MuiButton-root": {
          marginLeft: 20,
          [theme.breakpoints.down("sm")]: {
            padding: "2px 10px",
            fontSize: 10,
            display: "flex",

            alignItems: "center",
          },
          [theme.breakpoints.down("md")]: {
            padding: "2px 10px",
            fontSize: 10,
            display: "flex",
            alignItems: "center",
            marginLeft: 5,
          },
          "& .css-i4bv87-MuiSvgIcon-root": {
            [theme.breakpoints.down("sm")]: {
              fontSize: 17,
            },
          },
        },
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
      "& .MuiButton-root": {
        borderColor: "#576a77",
      },
      "& .MuiButton-text": {
        color: "#576a77",
      },
    }),
  { defaultTheme }
);

const CustomToolBar = (props) => {
  const classes = useStyles();

  const searchip = useRef();

  const focusInput = (e) => {
    e.preventDefault();
    searchip.current.children[0].children[1].focus();
  };

  useHotkeys("ctrl+shift+s", (e) => focusInput(e));

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        className="d-flex aligin-items-center pb-2"
      >
        <GridToolbarColumnsButton className="second" />
        <GridToolbarFilterButton className="second" />
        <GridToolbarExport className="second" />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        className="d-flex justify-content-start align-items-center"
      >
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          ref={searchip}
          // disabled
          fullWidth
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" className="second" />,
            endAdornment: (
              <>
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? "visible" : "hidden" }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" className="second" />
                </IconButton>
                <KeyboardBadge ele="Ctrl+Shift+S" />
              </>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CustomToolBar;

CustomToolBar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

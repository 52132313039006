import { PlayForWork } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useCreateNewProduct } from "../../../../redux/actions/productAction";
import ProductDetails from "./createComponent/ProductDetails";

// Product Action

import ProductInfo from "./createComponent/ProductInfo";
import ProductPrice from "./createComponent/ProductPrice";
import useCreateProduct from "./useCreateProduct";

const CreateNewProduct = ({
  handleDropDownCloseProduct,
  hideButton,
  matches,
}) => {
  const INITIAL_FORM_STATE = {
    id: "",
    product_id: "",
    variant_id: "",
    sku: "",
    category: "",
    supplier_id: "",
    sub_category: "",
    sub_sub_category: "",
    supplier_name: "",
    company_name: "",
    brand: "",
    price: "",
    title: "",
    product_type: "",
    description: "",
    price_consumer: "",
    consumer_discount: "",
    base_price: "",
    effective_mrp: "",
    cgst: "",
    sgst: "",
    igst: "",
  };

  const { FORM_VALIDATION, handleClickOpen, handleClose } = useCreateProduct(
    handleDropDownCloseProduct
  );

  const { mutate } = useCreateNewProduct(handleDropDownCloseProduct);

  const { openProductDialog } = useSelector((state) => state.layoutStore);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openProductDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openProductDialog]);

  return (
    <>
      {!hideButton && (
        <MenuItem
          onClick={handleClickOpen}
          sx={
            !matches
              ? { cursor: "pointer" }
              : { cursor: "pointer", fontSize: 14 }
          }
        >
          <PlayForWork
            color="secondary.dark"
            className="me-2"
            sx={
              !matches
                ? { cursor: "pointer" }
                : { cursor: "pointer", fontSize: 17 }
            }
          />
          Product
        </MenuItem>
      )}
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={async (values, { resetForm }) => {
          mutate(values, { onSuccess: () => resetForm() });
        }}
      >
        {({ submitForm, errors, values }) => (
          <Form>
            <Dialog
              fullWidth
              maxWidth="md"
              scroll="paper"
              open={openProductDialog}
              onClose={handleClose}
              aria-labelledby="scroll-Dialog-title"
              aria-describedby="scroll-Dialog-content"
            >
              <DialogTitle
                sx={{ background: "#ededed" }}
                className="border-bottom border-dark"
                id="scroll-Dialog-title"
              >
                New Product
              </DialogTitle>
              <DialogContent className="mt-3">
                <Grid container>
                  <Grid container spacing={2}>
                    <ProductInfo />
                    <ProductDetails />
                    <ProductPrice />

                    {/* <AdditionalInfo /> */}
                    {/* <CompanyInfo />
                  <LeadInfo />
                  <SocialInfo />
                  <AddressSectionWrapper />
                  <AdditionalInfo /> */}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  background: "#ededed",
                }}
                className="border-top border-dark"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={5}
                    sm={3}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-around aligin-items-center"
                  >
                    <Button
                      size="small"
                      onClick={handleClose}
                      // type="submit"
                      variant="contained"
                      color="error"
                      className="text-capitalize"
                    >
                      Close
                    </Button>
                    <Button
                      variant="containd"
                      type="submit"
                      onClick={() => submitForm()}
                      sx={{
                        background: "#C4C4C4",
                        padding: "4px 10px !important",
                      }}
                      className="text-capitalize"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateNewProduct;

import React from "react";

import { Button, CircularProgress } from "@mui/material";

import { useFormikContext } from "formik";

const SubmitButton = ({ children, isLoading, ...otherProps }) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  };

  const configButton = {
    ...otherProps,
    onSubmit: handleSubmit,
  };

  return (
    <Button disabled={isLoading} {...configButton}>
      {isLoading ? (
        <CircularProgress className="me-2" color="primary" size={20} />
      ) : (
        children
      )}
    </Button>
  );
};

export default SubmitButton;

import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { tenantAPI } from "../../../../../../config/api";
import errorHandle from "../../../../../../utils/errorHandle";



const useSendEmail = () => {
  const mutation = useMutation(
    async (data) => await tenantAPI.post(`/pportal/email/send/`, data),
    {
      onSuccess: (data, variables) => {
        toast.success(data.data.msg);
      },
      onError: (error) => {
        errorHandle(error);
      },
    }
  );

  return { mutate: mutation.mutate };
};

export default useSendEmail;

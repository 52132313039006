import { PlayForWork } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import { useCreateNewUser } from "../../../../redux/actions/userAction";
import UserlInfo from "./createComponent/UserInfo";
import useCreateUser from "./useCreateUser";
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';


const CreateNewUser = ({}) => {
  const matches = useMediaQuery("(max-width:600px)")
  const INITIAL_FORM_STATE = {
    id: "",
    email: "",
    password: "",
    password2: "",
    full_name: "",
    phone: "",
    job_title: "",
  };
  const { FORM_VALIDATION, handleClickOpen, handleClose } = useCreateUser();
  const { mutate } = useCreateNewUser();
  const { openUserDialog } = useSelector((state) => state.layoutStore);
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openUserDialog) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openUserDialog]);

  return (
    <>
      <Button variant='outlined' size='small' onClick={() => handleClickOpen()}>
        {matches ? <PersonAddAltRoundedIcon/> :"Add User"}
      </Button>

      <Dialog
        fullWidth
        maxWidth='md'
        scroll='paper'
        open={openUserDialog}
        onClose={handleClose}
        aria-labelledby='scroll-Dialog-title'
        aria-describedby='scroll-Dialog-content'
      >
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={async (values, { resetForm }) => {
            mutate(values, { onSuccess: () => resetForm() });
          }}
        >
          {({ submitForm, errors, values }) => (
            <Form>
              <DialogTitle
                sx={{ background: "#ededed" }}
                className='border-bottom border-dark'
                id='scroll-Dialog-title'
              >
                New User
              </DialogTitle>
              <DialogContent className='mt-3'>
                <Grid container>
                  <Grid container spacing={2}>
                    {/* User Info */}
                    <UserlInfo />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  background: "#ededed",
                }}
                className='border-top border-dark'
              >
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-end'
                  alignItems='center'
                >
                  <Grid
                    item
                    xs={5}
                    sm={3}
                    md={2}
                    lg={2}
                    className='d-flex justify-content-around aligin-items-center'
                  >
                    <Button
                      size='small'
                      onClick={handleClose}
                      // type="submit"
                      variant='contained'
                      color='error'
                      className='text-capitalize'
                    >
                      Close
                    </Button>
                    <Button
                      variant='containd'
                      type='submit'
                      sx={{
                        background: "#C4C4C4",
                        padding: "4px 10px !important",
                      }}
                      className='text-capitalize'
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateNewUser;

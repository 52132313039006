import React from "react";
import { Routes, Route } from "react-router-dom";
import ImportLeads from "./importLeads";
import ImportFixedFields from "./importLeads/ImportFixedFields";

const ImportDataRoutes = () => {
  return (
    <Routes>
      <Route path="/import-leads" element={<ImportLeads />} />
      <Route
        path="/import-leads/fixed-fields"
        element={<ImportFixedFields />}
      />
    </Routes>
  );
};

export default ImportDataRoutes;

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { tenantAPI } from "../../../config/api";
import { setDefaultPagination } from "../../../redux/actions/LayoutAction";

const useLeadData = (options) => {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log(searchParams.get("select"), "---selected staege");
  const selectedStage = searchParams.get("select");
  const dispatch = useDispatch();
  const { page, size } = useSelector((store) => store.layoutStore);
  return useQuery(
    ["leads", page, size],
    async () =>
      await tenantAPI.get(
        selectedStage ? `/leads/filter/${selectedStage}` : "/leads/0/",
        // `${selectedLeadStage ? `/filter/${selectedLeadStage}/` : "/leads/0/"}`,
        {
          params: {
            offset: size,
            pg_no: page,
          },
        }
      ),
    {
      ...options,
      select: (data) => {
        return {
          data: data?.data?.data?.map((r) => ({ ...r, id: r.uuid })),
          count: data?.data?.count,
        };
      },
      onSuccess: (data) => {
        dispatch(
          setDefaultPagination({
            page,
            size,
            rowCount: data?.count,
          })
        );
        options?.onSuccess && options.onSuccess(data);
      },
    }
  );
};

export default useLeadData;

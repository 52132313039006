import { useQuery } from "react-query";
import { tenantAPI } from "../../../../config/api";
const useAddUserData = (options) => {
  return useQuery(
    "getUsers",
    async () => await tenantAPI.get(`accounts/tenant-users/0/`),
    {
      ...options,
      select: (data) => {
        return {
          data: data?.data?.data?.map((r) => ({ ...r, id: r.uuid })),
          count: data?.data?.count,
        };
      },
    }
  );
};

export default useAddUserData;

import { Divider, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import useGetPrevMails from "./hooks/useGetMails";
import { useSelector } from "react-redux";

// const data = [
//   {
//     sentTo: "tabish@gmail.com",
//     date: "24/08/22",
//     time: "09:43am",
//     subject: "Lorem Ipsum and some text",
//     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
//   },
//   {
//     sentTo: "sajid@gmail.com",
//     date: "24/08/22",
//     time: "09:43am",
//     subject: "Lorem Ipsum and some text",
//     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
//   },
// ];

const PreviousMails = () => {
  const { contact } = useSelector((state) => state?.contactStore);
  const { data } = useGetPrevMails({ email: contact?.primary_email });
  console.log(data, "prervMailss");

  return (
    <>
      {data &&
        data?.map((mail) => {
          return (
            <>
              <div className="d-flex justify-content-between">
                <Typography
                  color="text.dark"
                  fontSize="12px"
                  className="d-flex align-items-center"
                >
                  From: {mail.mail_from}
                </Typography>
                <span>
                  <Typography
                    fontSize="12px"
                    color="text.dark"
                    display="inline"
                    className="me-2"
                  >
                    {moment(mail.curr_time).format("DD/MM/YYYY")}
                  </Typography>
                  <Typography
                    fontSize="12px"
                    color="text.dark"
                    display="inline"
                  >
                    {mail.time}
                  </Typography>
                </span>
              </div>
              <Typography color="text.dark" fontSize="12px">
                Subject: {mail.mail_subject}
              </Typography>
              <Typography color="text.dark" fontSize="12px" className="mt-1">
                {mail.mail_content}
              </Typography>
              <Divider className="mt-2 mb-4" />
            </>
          );
        })}
    </>
    // </Container>
  );
};

export default PreviousMails;

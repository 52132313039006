import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Form, Formik } from "formik";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ data }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Formik>
      <Form>
        <Box sx={{ width: "100%" }}>
          <Box >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{"& .MuiTabs-scroller":{overflow:"auto !important"}}}
            >
              {data.map((item) => (
                <Tab 
                  key={item.id}
                  className="text-capitalize"
                  label={item.label}
                  {...a11yProps(item.id)}
                />
              ))}
            </Tabs>
          </Box>

          {data.map((item) => (
            <TabPanel key={item.id} value={value} index={item.id}>
              <item.component showField="true" {...item.componentProps} />
            </TabPanel>
          ))}
        </Box>
      </Form>
    </Formik>
  );
}

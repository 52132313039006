import { useState } from "react";

const useLeadDrower = (data) => {
  const [state, setState] = useState(false);

  const toggleDrawer = (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!state);
  };
  
  const accountDefaultState = {
    company_name: data?.company_name ? data?.company_name : "",
    title: data?.title ? data?.title : "",
    primary_email: data?.primary_email ? data?.primary_email : "",
    secondary_email: data?.secondary_email ? data?.secondary_email : "",
    gst_number: data?.gst_number ? data?.gst_number : "",
    source: data?.source ? data?.source : "Self",
    industry: data?.industry ? data?.industry : "",
    tags: data?.tags ? data.tags : "",

    address_line1: data?.address ? data?.address.split("^")[0] : "",
    city1: data?.address ? data?.address.split("^")[1] : "",
    state1: data?.address ? data?.address.split("^")[2] : "",
    nationality1: data?.address ? data?.address.split("^")[3] : "",
    pin_code1: data?.address ? data.address.split("^")[4] : "",

    address_line2: data?.billing_address
      ? data?.billing_address.split("^")[0]
      : "",
    city2: data?.billing_address ? data?.billing_address.split("^")[1] : "",
    state2: data?.billing_address ? data?.billing_address.split("^")[2] : "",
    nationality2: data?.billing_address
      ? data?.billing_address.split("^")[3]
      : "",
    pin_code2: data?.billing_address ? data.billing_address.split("^")[4] : "",

    address_line3: data?.shipping_address
      ? data?.shipping_address.split("^")[0]
      : "",
    city3: data?.shipping_address ? data?.shipping_address.split("^")[1] : "",
    state3: data?.shipping_address ? data?.shipping_address.split("^")[2] : "",
    nationality3: data?.shipping_address
      ? data?.shipping_address.split("^")[3]
      : "",
    pin_code3: data?.shipping_address
      ? data.shipping_address.split("^")[4]
      : "",

    uuid: data?.uuid ? data.uuid : "",

    description: data?.description ? data.description : "",
  };
  const defaultState = {
    prefix: data?.prefix ? data?.prefix : "",
    first_name: data?.first_name ? data?.first_name : "",
    middle_name: data?.middle_name ? data?.middle_name : "",
    last_name: data?.last_name ? data?.last_name : "",
    primary_number: data?.primary_number ? data?.primary_number : "+91 ",
    secondary_number: data?.secondary_number ? data?.secondary_number : "+91 ",
    work_number: data?.work_number ? data.work_number : "+91 ",

    company: data?.company ? data?.company : "",
    company_name: data?.company_name ? data?.company_name : "",
    title: data?.title ? data?.title : "",
    primary_email: data?.primary_email ? data?.primary_email : "",
    secondary_email: data?.secondary_email ? data?.secondary_email : "",
    gst_number: data?.company?.gst_number ? data?.company?.gst_number : "",
    source: data?.source ? data?.source : "Self",
    industry: data?.company?.industry ? data?.company?.industry : "",
    tags: data?.tags ? data.tags : "",

    status: data?.status ? data?.status : "New",
    owner:
      data?.owner 
        ? data?.owner_name
        : "",
    website: data?.website ? data?.website : "",
    skype: data?.skype ? data?.skype : "",
    facebook: data?.facebook ? data?.facebook : "",
    twitter: data?.twitter ? data?.twitter : "",
    linkedin: data?.linkedin ? data?.linkedin : "",
    whatsapp: data?.whatsapp ? data?.whatsapp : "",
    fax_number: data?.fax_number ? data.fax_number : "",

    address_line1: data?.address ? data?.address.split("^")[0] : "",
    city1: data?.address ? data?.address.split("^")[1] : "",
    state1: data?.address ? data?.address.split("^")[2] : "",
    nationality1: data?.address ? data?.address.split("^")[3] : "",
    pin_code1: data?.address ? data.address.split("^")[4] : "",

    address_line2: data?.billing_address
      ? data?.billing_address.split("^")[0]
      : "",
    city2: data?.billing_address ? data?.billing_address.split("^")[1] : "",
    state2: data?.billing_address ? data?.billing_address.split("^")[2] : "",
    nationality2: data?.billing_address
      ? data?.billing_address.split("^")[3]
      : "",
    pin_code2: data?.billing_address ? data.billing_address.split("^")[4] : "",

    address_line3: data?.shipping_address
      ? data?.shipping_address.split("^")[0]
      : "",
    city3: data?.shipping_address ? data?.shipping_address.split("^")[1] : "",
    state3: data?.shipping_address ? data?.shipping_address.split("^")[2] : "",
    nationality3: data?.shipping_address
      ? data?.shipping_address.split("^")[3]
      : "",
    pin_code3: data?.shipping_address
      ? data.shipping_address.split("^")[4]
      : "",

    uuid: data?.uuid ? data.uuid : "",

    description: data?.description ? data.description : "",
  };

  return { state, defaultState, toggleDrawer, accountDefaultState };
};

export default useLeadDrower;

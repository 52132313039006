import { useQuery } from "react-query";
import axios from "axios";

const useSendSMS = (phNum, message) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];

  return useQuery(
    "sendSMS",
    async () =>
      await axios.get(`http://142.93.208.18/ApiSmsHttp`, {
        params: {
          UserId: "smsdemo@vcc.com",
          pwd: "pwd2022",
          Message:
            "Hi Amaan Khan, Thank You for calling Fladdra Team DigiAlaya.",
          Contacts: phNum.slice(-10),
          ServiceName: "SMSTRANS",
          MessageType: 1,
          //StartTime:
          DLTTemplateId: "1707160287667305187",
          SenderId: "DGALRT",
        },
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};

export default useSendSMS;

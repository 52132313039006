import * as Yup from "yup";

const useRegisterData = () => {
  const companyState = {
    company_name: "",
    no_of_employee: "",
    domain: "",
  };

  const personalState = {
    password: "",
    password2: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    job_title: "",
  };

  const companyValidation = Yup.object().shape({
    company_name: Yup.string("This field must be number").required(
      "This field is required"
    ),
    no_of_employee: Yup.number()
      .required("This field is required")
      .integer("This field must be number"),
    domain: Yup.string().required("This field is required"),
  });

  const personalValidation = Yup.object().shape({
    password: Yup.string()
      .required("This field is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        () => (
          <>
            <span>Your Password Must contaoin following :-</span>
            <ul>
              <li>Must Contain 8 Characters</li>
              <li> One Uppercase</li> <li>One Lowercase</li>{" "}
              <li>One Number </li> <li>one special case Character</li>
            </ul>
          </>
        )
      ),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("This field is required"),
    first_name: Yup.string()
      .required("This field is required")
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    last_name: Yup.string()
      .required("This field is required")
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("This field is required"),
    phone: Yup.string()
      .required("This field is required")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
      ),
    job_title: Yup.string()
      .required("This field is required")
      .matches(/^[aA-zZ\s|0-9|]+$/, "You Can Only Use ['Aa-Zz','0-9']"),
  });

  return {
    companyState,
    personalState,
    companyValidation,
    personalValidation,
  };
};

export default useRegisterData;

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { tenantAPI } from "../../../config/api";
import { setDefaultPagination } from "../../../redux/actions/LayoutAction";

const useContactData = (options) => {
  const dispatch = useDispatch()
  const {page,size} =useSelector(store => store.layoutStore)
  return useQuery(["contacts",page,size], async () => await tenantAPI.get(`/contacts/0/`,{
    params:{
      pg_no:page,
      offset:size
    }
  }), {
    ...options,
    select: (data) => {
      // return data.data.map((r) => ({ ...r, id: r.uuid }));
      return {
        data: data.data.data.map((r) => ({ ...r, id: r.uuid })),
        count: data.data.count,
      };
    },
    onSuccess:(data)=>{
      dispatch(setDefaultPagination({
        page,
        size,
        rowCount:data?.count
      }))
      options?.onSuccess && options.onSuccess(data)
    }
  });
};

export default useContactData;

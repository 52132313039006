import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tenantAPI } from "../../config/api";
import useGetSingleUser from "../../pages/settings/user/userHooks/useGetSingleUser";
import errorHandle from "../../utils/errorHandle";

import { SET_SINGLE_USER, SET_USER_DATA } from "../types/userTypes";
import { closeUserDailog } from "./LayoutAction";

// ######## Create New User #############

export const useCreateNewUser = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const createNewUser = async (data) => {
    return await tenantAPI.post(`/accounts/user/0/nt/`, data);
  };
  const mutation = useMutation(createNewUser, {
    onSuccess: () => {
      toast.success("User Added Successfully");
      dispatch(closeUserDailog());
      queryClient.refetchQueries(["getUsers"], { exact: true });
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};

// ######## Create PassWord #############

export const useCreatePass = () => {
  const navigate = useNavigate();

  const createNewPass = async (data) => {
    return await tenantAPI.post(`/accounts/password/`, data);
  };

  const mutation = useMutation(createNewPass, {
    onSuccess: () => {
      toast.success("PassWord Changed Successfully");
      navigate("/");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};

export const setUserData = (data) => (dispatch) => {
  dispatch({ type: SET_USER_DATA, payload: data });
};

export const setSingleUser = (data) => (dispatch) => {
  dispatch({ type: SET_SINGLE_USER, payload: data });
};

// ############ User Update #################
export const useUserUpdate = (id) => {
  const queryClient = useQueryClient();

  const updateUser = async (data) => {
    return await tenantAPI.put(
      `/accounts/admin-profile-update/${data.id}/`,
      data
    );
  };

  const mutation = useMutation(updateUser, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(["User", { id: variables }], data);
      queryClient.refetchQueries(["getUsers"], { exact: true });
      queryClient.refetchQueries(["user"], { exact: true });
      toast.success("User updated successfully.");
      localStorage.setItem("userData", JSON.stringify(data));
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};

// ############# Delete User ###############
export const useUserDelete = () => {
  const deleteUser = async (id) => {
    return await tenantAPI.delete(`/accounts/user/${id}/nt/`);
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(deleteUser, {
    onSuccess: () => {
      queryClient.refetchQueries(["getUsers"], { exact: true });
      toast.success("User delete successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });

  return { mutate: mutation.mutate };
};

// ############## Login User Profile Update ############
export const useUserProfileUpdate = () => {
  useGetSingleUser({
    enabled: false,
    onSuccess: (data) => {
      localStorage.setItem("userData", JSON.stringify(data?.data));
    },
  });

  const queryClient = useQueryClient();

  const updateUserProfile = async (data) => {
    let blob = data?.profile_pic;
    const file = new File([blob], "Profile.jpeg");

    const formData = new FormData();
    formData.append("profile_pic", file);
    formData.append("full_name", data?.full_name);
    formData.append("job_title", data?.job_title);
    formData.append("phone", data?.phone);
    return await tenantAPI.put(`/accounts/profile-pic/`, formData);
  };

  const mutation = useMutation(updateUserProfile, {
    onSuccess: async () => {
      await queryClient.refetchQueries(["user"], {
        exact: true,
      });
      toast.success("User Profile Updated successfully.");
    },
    onError: (error) => {
      errorHandle(error);
    },
  });
  return { mutate: mutation.mutate };
};

import React from "react";
import RevenueFromProduct from "../../../components/home/RevenueFromProduct";
import PageStructure from "../components/PageStructure";
import LeadsLostDetails from "./LeadsLostDetails";
import LeadsWonDetails from "./LeadsWonDetails";

const LeadsWon = () => {
  return (
    <RevenueFromProduct
      name="Deals Won in May"
      revenue={"600"}
      revLastYear={-11.2}
    />
  );
};

const LeadsLost = () => {
  return (
    <RevenueFromProduct
      name="Deals lost in May "
      revenue={"400"}
      revLastYear={-11.2}
      isRevLost={true}
    />
  );
};

function Lead() {
  return (
    <PageStructure
      tab1={<LeadsWon />}
      tab2={<LeadsLost />}
      defaultTab={<div>Default</div>}
      optionTab1={<LeadsWonDetails />}
      optionTab2={<LeadsLostDetails />}
    />
  );
}

export default Lead;

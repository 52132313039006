import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdAdd, MdOutlineDelete } from "react-icons/md";
import InputFieldNf from "../formsUI/withoutFormik/InputFieldNf";
import AutoSelectFieldNf from "../formsUI/withoutFormik/AutoCompleteNf";

import useAddProduct from "../../pages/settings/product/productHooks/useAddProduct";

const AutoCompleteProduct = ({
  addProduct,
  deleteProduct,
  formData,
  onProductChange,
  handleChange,
}) => {
  const [productData, setproductData] = useState([]);

  const { data } = useAddProduct({
    onSuccess: (data) => {
      setproductData(data?.data);
    },
  });

  useEffect(() => {
    let ids = formData?.product?.map((item) => item?.product?.uuid);
    setproductData(
      productData ? productData?.filter((item) => !ids.includes(item.uuid)) : []
    );
    //eslint-disable-next-line
  }, [formData.product]);

  const onChange = (e) => {
    onProductChange(e);
  };

  const addFormFields = () => {
    addProduct();
  };

  return (
    <>
      <Grid xs={12} className="d-flex align-items-center">
        <Grid item xs={6}>
          <InputFieldNf
            name="valuation"
            label="Deal Value"
            size="small"
            className="mt-3 px-2"
            disabled
            value={formData?.valuation || 0}
          />
        </Grid>
        <Grid item xs={6} className="mt-3 px-2">
          <InputFieldNf
            required={true}
            name="proposed_value"
            label="Proposed Value"
            type="number"
            size="small"
            onChange={handleChange}
            value={formData?.proposed_value}
          />
        </Grid>
      </Grid>
      {formData.product?.map((item, index) => (
        <Grid xs={12} key={index} className="d-flex align-items-center">
          <Grid item xs={6} className="px-2">
            <AutoSelectFieldNf
              className="mt-3"
              name="product"
              options={productData ? [...productData] : []}
              label="Add Products"
              getOptionLabel={(options) => options.title || ""}
              onChange={(e, value) => {
                onChange({ name: "product", value, id: item.id });
              }}
              value={item.product}
            />
          </Grid>
          <Grid
            item
            xs={6}
            className="mt-3 d-flex justify-content-between align-items-center ps-2"
          >
            <InputFieldNf
              name="quantity"
              label="Quantity"
              type="number"
              size="small"
              onChange={(e) => {
                if (+e.target.value > 0) {
                  onChange({
                    name: "quantity",
                    value: +e.target.value,
                    id: item.id,
                  });
                }
              }}
              value={item.quantity}
              min={1}
            />
            {formData.product.length === index + 1 ? (
              <IconButton
                onClick={() => addFormFields()}
                style={{ fontSize: "24px" }}
              >
                <MdAdd />
              </IconButton>
            ) : (
              <IconButton style={{ fontSize: "24px" }}>
                <MdOutlineDelete onClick={() => deleteProduct(item.id)} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};
export default AutoCompleteProduct;

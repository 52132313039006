import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#0663C7",
      main: "#274C77",
      dark: "#0053AD",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#274C77",
      dark: "#D6ECF8",
      contrastText: "#274C77",
    },
    text: {
      primary: "#313131",
      secondary: "#8d9499",
      // dark: "black",
      dark: "#313131",
    },
  },
  typography: {
    fontFamily: "Inter",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

export default theme;

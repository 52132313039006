import { Grid, Typography } from "@mui/material";
import React from "react";
import { BiRupee } from "react-icons/bi";

const SetValuation = ({ valuation }) => {
  return (
    <>
      <Grid item xs={12} className="d-flex justify-content-end mt-2">
        <Typography
          fontSize={"12px"}
          style={{ color: "#000", fontWeight: 500 }}
          className="me-3"
          component="p"
        >
          Total Products Value
        </Typography>
        <Typography
          fontSize={"12px"}
          style={{ color: "#000", fontWeight: 500 }}
          component="p"
        >
          <span>
            <BiRupee />
          </span>
          {valuation
            ? valuation.toFixed
              ? valuation.toFixed(2)
              : valuation
            : "-"}
        </Typography>
      </Grid>
    </>
  );
};

export default SetValuation;

const initialState = { leads: [] };

export const leadReducer = (state = initialState, action) => {
  const reducer = {
    SET_LEAD_DATA: () => {
      return {
        ...state,
        leads: action.payload,
      };
    },
  };

  return reducer[action?.type] ? reducer[action?.type]() : state;
};

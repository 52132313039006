import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  MuiAccordionroot: {
    // "&.MuiAccordion-root:before": {
    //   backgroundColor: "white",
    // },
  },
});

const TabStructure = ({
  label1,
  label2,
  Panel1,
  Panel2,
  panel1Props,
  isDisabled,
  disabledPanel,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const classes = useStyle();
  return (
    <div>
      {isDisabled ? (
        disabledPanel
      ) : (
        <>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{ boxShadow: "unset" }}
            elevation={0}
            classes={{
              root: classes.MuiAccordionroot,
            }}
            disabled={isDisabled ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography color={expanded === "panel1" ? "primary" : ""}>
                {label1}
              </Typography>
            </AccordionSummary>
            <AccordionDetails {...panel1Props}>
              <Panel1 />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{ boxShadow: "unset" }}
            elevation={0}
            classes={{
              root: classes.MuiAccordionroot,
            }}
            disabled={isDisabled ? true : false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography color={expanded === "panel2" ? "primary" : ""}>
                {label2}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Panel2 />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
};

export default TabStructure;

import { Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import CustomDialog from "../../components/Layout/customDialog/CustomDialog";
import { rootAPI } from "../../config/api";
import errorHandle from "../../utils/errorHandle";
import { MdOutlineMode } from "react-icons/md";
import * as Yup from "yup";
import TenantForm from "./tenantForm/TenantForm";

const useAdmin = () => {
  const { data, isLoading, refetch } = useQuery(
    "tenants",
    async () => await rootAPI.get("/tenants/licence-admin/0/nt"),
    {
      select: (data) =>
        data?.data.map((row) => ({
          ...row,
          id: row.uuid,
          company_name: row.tenant.company_name,
          schema_name: row.tenant.schema_name,
        })),
    }
  );

  const updateTenant = async (data) => {
    if (data.subscription_end)
      data.subscription_end = moment(data.subscription_end).format(
        "YYYY-MM-DD"
      );
    return await rootAPI.put(`/tenants/licence-admin/${data.id}/nt/`, data);
  };

  const updateMutation = useMutation(updateTenant, {
    onSuccess: () => {
      refetch();
      toast.success("Tenant Updated");
    },
    onError: (err) => {
      errorHandle(err);
    },
  });

  const FORM_VALIDATION = Yup.object().shape({
    num_of_users: Yup.number().positive().required("This field is required"),
    subscription_end: Yup.date()
      .min(new Date(), "End date cannot be in the past.")
      .required("This field is required"),
    is_active: Yup.bool().required("This field is required"),
  });

  const columns = [
    {
      field: "company_name",
      headerName: "Company",
      minWidth: 100,
      cellClassName: "p-0 ps-3",
      flex: 3,
    },
    {
      field: "schema_name",
      headerName: "Schema",
      minWidth: 100,
      cellClassName: "p-0 ps-3",
      flex: 3,
    },
    {
      field: "num_of_users",
      headerName: "Users",
      type: "number",
      minWidth: 100,
      cellClassName: "p-0 pe-3",
      flex: 1,
      editable: true,
    },
    {
      field: "subscription_start",
      headerName: "Start Date",
      type: "date",
      minWidth: 100,
      cellClassName: "p-0 ps-3",
      flex: 3,
      editable: true,
    },
    {
      field: "subscription_end",
      headerName: "End Date",
      type: "date",
      minWidth: 100,
      cellClassName: "p-0 ps-3",
      flex: 3,
      editable: true,
    },
    {
      field: "is_active",
      headerName: "Is Active",
      type: "boolean",
      minWidth: 100,
      cellClassName: "p-0 text-center",
      flex: 1,
      editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      headerAlign: "center",
      headerClassName: "text-capitalize",
      cellClassName: "d-flex justify-content-around",
      flex: 1,
      renderCell: (params) => (
        <>
          <CustomDialog
            heading="Edit Tenant"
            mutate={updateMutation.mutate}
            button={
              <Tooltip title="Edit Tenant">
                <IconButton sx={{ padding: "4px" }}>
                  <MdOutlineMode />
                </IconButton>
              </Tooltip>
            }
            closeButton="CLOSE"
            addButton="UPDATE"
            initialValues={{
              id: params.row.uuid,
              num_of_users: params.row.num_of_users,
              subscription_end: params.row.subscription_end,
              is_active: params.row.is_active,
            }}
            validationSchema={FORM_VALIDATION}
          >
            <TenantForm is_active={params.row.is_active} />
          </CustomDialog>
        </>
      ),
    },
  ];

  return {
    data,
    isLoading,
    columns,
  };
};

export default useAdmin;

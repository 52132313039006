import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 4,
    borderBottom: "none",
    "& .MuiFormControl-root": {
      height: "100%",
    },
    "& .MuiFilledInput-root": {
      background: "none",
    },
    marginTop: "none",
  },
  textField: {
    "& .MuiInput-root": {
      height: "100%",
      margin: "0px",
      padding: "0px 12px",
      border: "2px solid black",
      borderRadius: 4,
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
      "& .MuiInput-input": {
        height: "64px",
      },
      "& .MuiSvgIcon-root": {
        display: "none",
      },
    },
  },
});

const MultiAutoCompleteNf = ({
  options,
  className,
  name,
  label,
  getOptionLabel,
  defaultValue,
  multiple,
  value,
  field,
  ...otherProps
}) => {
  const { root } = useStyles();
  return (
    <>
      <Autocomplete
        multiple={multiple}
        id="tags-standard"
        sx={{ width: "100%", height: "100%" }}
        size="small"
        value={value}
        options={options}
        defaultValue={defaultValue}
        className={root}
        getOptionLabel={getOptionLabel}
        filterSelectedOptions
        getOptionDisabled={(option) => {
          if (value && field) {
            if (
              multiple &&
              value?.some((day) => day[field] === option[field])
            ) {
              return true;
            }
            if (!multiple && value[field] === option[field]) {
              return true;
            }
          }
          return false;
        }}
        {...otherProps}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" />
        )}
      />
    </>
  );
};

export default MultiAutoCompleteNf;

import React from "react";

import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../../utils/PrivateRoute";
import SingleUser from "./singleUserProfile";

const Profile = () => {
  return (
    <>
      <PrivateRoute>
        <Routes>
          <Route path="/profile" element={<SingleUser />} />
        </Routes>
      </PrivateRoute>
    </>
  );
};

export default Profile;

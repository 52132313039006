import React, { useRef, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import { useHotkeys } from "react-hotkeys-hook";
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/system";
import { IconButton, TextField } from "@mui/material";
import KeyboardBadge from "../KeyboardBadge";
import { useSetSearch } from "../../../redux/actions/commonAction";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: "auto 10px auto 0",

        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiFilledInput-input": {
          padding: 5,
        },
        "& .MuiFilledInput-root": {
          background: "none",
        },
        "& .MuiFilledInput-root::after": {
          display: "none",
        },
        "& .MuiFilledInput-underline:before": {
          display: "none",
        },
        "&:focus": {
          background: "red",
        },
        // "background": "#E9ECEF",
        background: "#ffffff",
        border: "2px solid #5582AD",
        borderRadius: 5,
      },
    }),
  { defaultTheme }
);

const SearchAppBar = () => {
  const [value, setValue] = useState("");
  const classes = useStyles();
  const { setSearchValue } = useSetSearch();

  useHotkeys("ctrl+s", (e) => {
    e.preventDefault();
    ref.current.children[0].children[1].focus();
  });

  const ref = useRef();
  return (
    <TextField
      variant="filled"
      placeholder="Search…"
      ref={ref}
      size="small"
      className={classes.textField}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        setSearchValue(e.target.value);
      }}
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" className="second" />,
        endAdornment: (
          <>
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{
                visibility: value ? "visible" : "hidden",
              }}
              onClick={() => {
                setValue("");
                setSearchValue("");
              }}
            >
              <ClearIcon fontSize="small" className="second" />
            </IconButton>
            <KeyboardBadge ele="Ctrl+S" className="second" />
          </>
        ),
      }}
    />
  );
};

export default SearchAppBar;

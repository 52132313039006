import * as React from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment";

const DateRangePickerMui = ({
  value,
  setValue,
  startLabel,
  endLabel,
  ...otherProps
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: startLabel, end: endLabel }}
    >
      <DateRangePicker
        value={value}
        disableFuture
        calendars={1}
        onChange={(newValue) => {
          // setValue(newValue);
          setValue([
            moment(newValue[0]?.$d).format("MM/DD/YYYY"),
            moment(newValue[1]?.$d).format("MM/DD/YYYY"),
          ]);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} size="small" {...otherProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} size="small" {...otherProps} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};

export default DateRangePickerMui;

import { Backdrop, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import ContactHistory from "../../../components/contact/contactHistory";
import DealAccordion from "../../../components/deal/dealAccordion";
import DealCard from "../../../components/deal/dealCard";
import DealHeader from "../../../components/deal/dealCard/DealHeader";
import Container from "../../../components/Layout/Container";
import useSingleDeal from "./useSingleDeal";

const SingleDeal = () => {
  const pramas = useParams();
  const { isLoding } = useSingleDeal(pramas.uuid);
  return (
    <>
      {isLoding ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <DealHeader />
          <Container>
            <Grid container className="d-flex">
              <DealCard />
              <ContactHistory forDeal={true} />
              <DealAccordion />
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default SingleDeal;

import React, { useEffect } from "react";

import Container from "../../../components/Layout/Container";
import Table from "../../../components/Layout/table";

import useHomeLead from "./useHomeLead";
import Header from "../../../components/Layout/Header";

import { useMediaQuery } from "@mui/material";
import PrivateRoute from "../../../utils/PrivateRoute";
import { useHotkeys } from "react-hotkeys-hook";
import LeadFooter from "../../../components/lead/LeadFooter";
import { useDispatch } from "react-redux";
import { openLeadDialog } from "../../../redux/actions/LayoutAction";
import { leadAndContactColumns } from "../../../constants/columns";
import NewLeadOptions from "../../../components/lead/NewLeadOptions";
import Navbar from "../../../components/Layout/navbar";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useState } from "react";
import useLeadData from "../leadHooks/useLeadData";
import { useLocation } from "react-router-dom";

const Lead = () => {
  const search = useLocation().search;
  const selectedLeadStage = new URLSearchParams(search).get("select");
  console.log(selectedLeadStage, "---selLead");

  const { createColumnSchema } = useHomeLead({});
  const [leadsData, setLeadsData] = useState([]);
  const { isLoading } = useLeadData({
    onSuccess: (data) => setLeadsData(data?.data),
  });
  const tableRef = useGridApiRef();
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    document.title = "All Leads | CRM";
  }, []);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(openLeadDialog());
  };

  const OnExport = () => {
    tableRef?.current?.exportDataAsCsv();
  };

  useHotkeys("ctrl+alt+l", (e) => {
    e.preventDefault();
    handleClickOpen();
  });

  return (
    <PrivateRoute>
      <Navbar />
      <Header
        name="Leads"
        showSearch={true}
        exportButton
        exportOnClick={OnExport}
        addButton={
          <NewLeadOptions handleClickOpen={handleClickOpen} matches={matches} />
        }
      />
      <Container>
        <Table
          style={{ marginTop: "30px" }}
          apiRef={tableRef}
          data={leadsData ? leadsData : []}
          loading={isLoading}
          columns={
            leadsData?.length
              ? createColumnSchema(leadsData[0])
              : leadAndContactColumns
          }
        />
        {!matches && <LeadFooter data={leadsData?.length ? leadsData : []} />}
      </Container>
    </PrivateRoute>
  );
};

export default Lead;

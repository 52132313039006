import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import Checkboxes from "../../../../components/formsUI/CheckBox";
import CustomDialog from "../../../../components/Layout/customDialog/CustomDialog";
import SearchAppBar from "../../../../components/Layout/navbar/Search";
import Table from "../../../../components/Layout/table";
import StageForm from "./StageForm";
import useDealPipeline from "./useDealPipeline";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';


const DealPipeline = () => {
  const matches = useMediaQuery("(max-width:600px)")
  const {
    data,
    isLoading,
    columns,
    mutate,
    INITIAL_FORM_STATE,
    FORM_VALIDATION,
  } = useDealPipeline();

  useEffect(() => {
    document.title = "Import Leads | CRM";
  });

  useHotkeys("ctrl+alt+p", (e) => {
    e.preventDefault();
  });

  return (
    <>
      {" "}
      <Grid container className="p-2 px-3">
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }} variant="h5">
            Deal Pipeline
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "1rem" }} className="mt-2" component="p">
            Add & Customize Deal Stages
          </Typography>
        </Grid>
        <Grid
          container
          className="d-flex align-items-center justify-content-between mt-3 "
        >
          <Grid
            item
            xs={12}
            className="d-flex align-items-center justify-content-between"
          >
            <Grid className="d-flex align-items-center" xs={8} sm={6} md={10}>
              <Checkboxes />
              <SearchAppBar />
            </Grid>
            <Grid xs={4} sm={3} md={2}>
              <CustomDialog
                mutate={mutate}
                heading="New Stage"
                button={
                  <Button variant="outlined" size="small" className="me-3 deal-pipeline-button">
                    {matches ? <AddBoxRoundedIcon/> :"Add Stage"}
                  </Button>
                }
                closeButton="CLOSE"
                addButton="SAVE"
                initialValues={INITIAL_FORM_STATE}
                validationSchema={FORM_VALIDATION}
              >
                <StageForm />
              </CustomDialog>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="px-3">
          <Table
            className="p-2"
            style={{ width: "100%" }}
            mt={"30px"}
            columns={columns ? columns : []}
            data={data ? data : []}
            loading={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DealPipeline;

import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useFormikContext } from "formik";

const CheckboxLabels = ({
  label,
  name,
  default_value,
  onChange,
  ...otherProps
}) => {
  const data = useFormikContext();
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox defaultChecked={default_value || data?.values?.[name]} />
        }
        name={name}
        label={label}
        onChange={
          onChange
            ? onChange
            : (e, value) => {
                data?.setFieldValue(name, value);
              }
        }
        {...otherProps}
      />
    </FormGroup>
  );
};

export default CheckboxLabels;

// import * as React from 'react';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// export default function CheckboxLabels() {
//   return (
//     <FormGroup>
//       <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />

//     </FormGroup>
//   );
// }

import { Grid } from "@mui/material";
import React from "react";
import CustomFinal from "../../../../components/custLead/CustomFinal";
import CustomLeadHeader from "../../../../components/custLead/CustomLeadHeader";

const Field = () => {
  const Customdata = [
    {
      id: 1,
      name: "name",
      type: "",
      is_active: "",
      is_required: true,
    },
    {
      id: 2,
      name: "range",
      type: "",
      is_active: "",
      is_required: false,
    },
    {
      id: 3,
      name: "abc",
      type: "",
      is_active: "",
      is_required: false,
    },
  ];
  return (
    <>
      <CustomLeadHeader
        HeadingName="Current Fields"
        style={{ padding: "10px" }}
      />
      <Grid container xs={12} md={12}  className="px-5 py-2">
        {Customdata?.map((item) => (
          <Grid item xs={12} className="mt-2">
            <CustomFinal 
              showEdit={true}
              name={item?.name}
              label={item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}
              is_required={item.is_required}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Field;

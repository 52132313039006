import React, { useState } from "react";
import { Button, Tab, Tabs, Typography } from "@mui/material";
import {
  MailOutline,
  SmsOutlined,
  WhatsappOutlined,
} from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import TabStructure from "./TabStructure";
import SendEmailBox from "./sendEmail/SendEmailBox";
import previousMails from "./sendEmail/PreviousMails";
import PreviousCalls from "./phone/PreviousCalls";
import PhoneContacts from "./phone/PhoneContacts";
import PreviousSMS from "./sms/PreviousSMS";
import ComposeSMS from "./sms/ComposeSMS";
import ComposeWhatsappMsg from "./whatsapp/ComposeWhatsappMsg";
import MobileNumberInput from "../../../formsUI/MobileNumberInput";
import { Form, Formik } from "formik";
import useAddWhatsapp from "./whatsapp/hooks/useAddWhatsapp";
import { useUpdateContact } from "../../../../redux/actions/contactAction";
import { useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ mt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Communication = () => {
  const [value, setValue] = useState(0);
  const { validation } = useAddWhatsapp();

  const { contact } = useSelector((state) => state.contactStore);
  const { mutate } = useUpdateContact(contact.uuid, `Whatsapp Number Added`);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const defaultState = {
    whatsapp: "",
  };

  return (
    <>
      <div
        className="w-100 p-2 pt-0 bg-white mb-3"
        style={{ borderRadius: "5px"  }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="communication tabs"
          variant="fullWidth"
          // sx={{transform:{xs:"scale(0.8)"}}}
        >
          <Tab icon={<MailOutline />} aria-label="mail" />
          <Tab icon={<PhoneIcon />} aria-label="phone" />
          <Tab icon={<SmsOutlined />} aria-label="sms" />
          <Tab icon={<WhatsappOutlined />} aria-label="whatsapp" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <TabStructure
            label1="Compose New Mail"
            label2="Previous Mails"
            Panel1={SendEmailBox}
            Panel2={previousMails}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TabStructure 
            label1="Contacts"
            label2="Previous Calls"
            // Panel1={SendEmailBox}
            // Panel2={previousMails}
            Panel1={PhoneContacts}
            Panel2={PreviousCalls}
            // panel1Props={{ className: "px-4" }}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TabStructure
            label1="Compose New SMS"
            label2="Previous SMS"
            Panel1={ComposeSMS}
            Panel2={PreviousSMS}
            panel1Props={{ className: "px-4" }}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TabStructure
            label1="Compose New Message"
            label2="Previous Messages"
            Panel1={ComposeWhatsappMsg}
            Panel2={previousMails}
            isDisabled={contact.whatsapp ? false : true}
            disabledPanel={
              <div>
                <Formik
                  initialValues={{
                    ...defaultState,
                  }}
                  // validationSchema={validation}
                  enableReinitialize={true}
                  validationSchema={validation}
                  onSubmit={async (values, { resetForm }) => {
                    console.log(values, "---- values");
                    mutate(values, {
                      onSuccess: () => {
                        resetForm();
                      },
                    });
                  }}
                >
                  <Form>
                    <div className="d-flex flex-column mt-4">
                      <div className="m-auto">
                        <MobileNumberInput
                          name="whatsapp"
                          label="Whatsapp Number"
                          color="#fff"
                          border="4px"
                          width="100%"
                        />
                      </div>
                      <div className="m-auto mt-3 mb-3">
                        <Button
                          variant="contained"
                          type="submit"
                          className="text-capitalize"
                          size="small"
                        >
                          Add Whatsapp Number
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            }
          />
        </TabPanel>
      </div>
    </>
  );
};

export default Communication;

import { Grid, Typography } from "@mui/material";
import React from "react";

const CustomLeadHeader = ({ HeadingName }) => {
  return (
    <Grid container className="custom-header p-2">
      <Grid item xs={12}>
        <Typography component="p">{HeadingName}</Typography>
      </Grid>
    </Grid>
  );
};

export default CustomLeadHeader;

import React, { useState } from "react";
import RevenueFromProduct from "../../../components/home/RevenueFromProduct";
import PageStructure from "../components/PageStructure";
import DealsWonDetails from "./DealsWonDetails";
import DealsLostDetails from "./DealsLostDetails";

import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
import {
  Checkbox,
  ListItemText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const DealsWon = () => {
  return (
    <RevenueFromProduct
      name="Leads Won in May"
      revenue={"600"}
      revLastYear={-11.2}
    />
  );
};

const DealsLost = () => {
  return (
    <RevenueFromProduct
      name="Leads lost in May "
      revenue={"400"}
      revLastYear={-11.2}
      isRevLost={true}
    />
  );
};

const DealQuantityFunnel = () => {
  const [dealPipeFilter, setDealPipeFilter] = useState("Deal Quantity");

  const names = ["Deal Quantity", "Deal Amount", "Products"];
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Typography component="p" fontWeight="500" fontSize="20px">
          Deal Quantity
        </Typography>
        <FormControl sx={{ m: 1, width: 250 }}>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple={false}
            value={dealPipeFilter}
            onChange={(event) => setDealPipeFilter(event.target.value)}
            input={<OutlinedInput label="Filter By" />}
            renderValue={(dealPipeFilter) => (
              <>
                <FilterAltIcon /> By {dealPipeFilter}
              </>
            )}
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={name === dealPipeFilter} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <FunnelChart
        data={[
          { name: "Prospecting", value: 252 },
          { name: "Qualified", value: 105 },
          { name: "Quote", value: 84 },
          { name: "Closure", value: 72 },
          { name: "Closed", value: 19 },
        ]}
        showValues={true}
        showNames={true}
        pallette={["#6690FF", "#3366FF", "#1939B7", "#102693", "#001170"]}
        chartWidth={500}
        
      />
    </>
  );
};

const DealPipelineReport = () => {
  return (
    <PageStructure
      tab1={<DealsWon />}
      tab2={<DealsLost />}
      defaultTab={<DealQuantityFunnel />}
      optionTab1={<DealsWonDetails />}
      optionTab2={<DealsLostDetails />}
    />
  );
};

export default DealPipelineReport;

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import moment from "moment"
import React from "react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import useGetAll from "../../../../hooks/useGetAll"
import SimpleAccordion from "../../../Layout/accordion/Accordion"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined"
import Update from "./Update"

const Timeline = () => {
  const params = useParams()
  const { data } = useGetAll(`/tportal/audit/${params?.uuid}`, {
    select: (data) => data?.data,
  })

  const [displaying, setDisplaying] = useState("all")
  const [sortBy, setSortBy] = useState("everyone")
  const [relatedTo, setRelatedTo] = useState("everyone")

  let ValueFormatter = (key, value) => {
    let obj = {
      created_at: () => {
        return value !== "" ? moment(value).format("DD MMM YYYY") : "-"
      },
      updated_at: () => {
        return value !== "" ? moment(value).format("DD MMM YYYY") : "-"
      },
    }
    return obj[key] ? obj[key]() : value
  }

  let TitleFormatter = (user, key, value, model) => {
    let createMessage = `${user} created ${key?.replace("_", " ")}`
    return value === true ? createMessage : `${user} updated ${model}`
    // let obj = {
    //   new_lead: () => {
    //     return createMessage;
    //   },
    //   new_contact: () => {
    //     return createMessage;
    //   },
    //   new_deal: () => {
    //     return createMessage;
    //   },
    //   new_deal_quote: () => {
    //     return createMessage;
    //   },
    //   new_deal_product: () => {
    //     return createMessage;
    //   },
    //   new_deal_contact: () => {
    //     return createMessage;
    //   },
    //   new_deal_task: () => {
    //     return createMessage;
    //   },
    // };
    // return obj[key] ? obj[key]() : `${user} updated ${model}`;
  }

  return (
    <Grid
      container
      className="w-100 p-2 pt-0 bg-white mb-3 px-3"
      style={{ borderRadius: "5px" }}
    >
      <Grid container spacing={2} mt={1} item xs={12}>
        <Grid item lg={4} md={6} sm={4} xs={12}>
          <FormControl fullWidth variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Displaying:
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={displaying}
              onChange={(event) => {
                setDisplaying(event.target.value)
              }}
              size="small"
              sx={{ fontSize: "14px" }}
            >
              <MenuItem value={"all"}>all activity</MenuItem>
              <MenuItem value={"notes"}>notes</MenuItem>
              <MenuItem value={"communication"}>communication</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={6} sm={4} xs={12}>
          <FormControl fullWidth variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Sort By:{" "}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={sortBy}
              sx={{ fontSize: "14px" }}
              onChange={(event) => {
                setSortBy(event.target.value)
              }}
            >
              <MenuItem value={"everyone"}>everyone</MenuItem>
              <MenuItem value={"owner"}>owner</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={12} sm={4} xs={12}>
          <FormControl fullWidth variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Related To:{" "}
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={relatedTo}
              // onChange={handleChange}
              sx={{ fontSize: "14px" }}
              onChange={(event) => {
                setRelatedTo(event.target.value)
              }}
            >
              <MenuItem value={"everyone"}>everyone</MenuItem>
              <MenuItem value={"contact"}>contact</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item xs={12} className="my-2">
        {data?.map((audit) => (
          <Update audit={audit} />
        ))}
      </Grid>
    </Grid>
  )
}

export default Timeline

{
  /* <div>
                              Updated: {key}<span >{audit?.content[key].old_value}</span>
                              <span >
                                {audit?.content[key].new_value}
                                </span> 
                            </div> */
}

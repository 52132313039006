import { Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import VerticalLinearStepper from "../../../../components/Layout/stepper";
import { FiDownload, FiArrowLeft } from "react-icons/fi";
import FileInputNf from "../../../../components/formsUI/withoutFormik/FileInputNf";
import { toast } from "react-toastify";
import CSVPreview from "../../../../components/Layout/CSVPreview";
import useImportLeads from "./useImportLeads";
import { useNavigate } from "react-router-dom";

const { REACT_APP_API_PROTOCOL } = process.env;
const { REACT_APP_API_HOSTNAME } = process.env;
const { REACT_APP_API_PORT } = process.env;

const ImportFixedFields = () => {
  const [file, setFile] = useState();
  const { mutate } = useImportLeads();
  const navigate = useNavigate();

  const steps = [
    {
      label: "Download the Sample CSV Format",
      component: (
        <a
          href={`${REACT_APP_API_PROTOCOL}${
            window.location.host.split(".")[0]
          }.${REACT_APP_API_HOSTNAME}${REACT_APP_API_PORT}/media/BulkUploadCSV.csv`}
          className="import-button my-3"
        >
          <FiDownload />
          Download the Sample CSV format
        </a>
      ),
    },
    {
      label: "Fill and Upload the file ",
      component: (
        <>
          <FileInputNf
            name="bulkInput"
            file={file}
            accept=".csv"
            setFile={setFile}
            onChange={(e) => setFile(e.target.files[0])}
          />
        </>
      ),
      onClick: () => {
        if (!file) {
          toast.error(`please upload a file`);
          return false;
        } else {
          return true;
        }
      },
    },
    {
      label: "Preview the data",
      component: (
        <div style={{ width: "100%" }}>
          <CSVPreview file={file} />
        </div>
      ),
      onClick: () => {
        mutate(file);
      },
    },
  ];

  return (
    <>
      <Grid container className="p-2 px-3">
        <Grid item xs={12}>
          <div className="d-flex align-items-start">
            <IconButton className="me-2" onClick={() => navigate(-1)}>
              <FiArrowLeft style={{ color: "#475467" }} size={22} />
            </IconButton>
            <Stack>
              <Typography sx={{ fontWeight: 600 }} variant="h5">
                Import Leads
              </Typography>
              <Typography
                sx={{ fontWeight: 300, color: "#616161" }}
                variant="p"
              >
                Import Data as per the current Fields
              </Typography>
            </Stack>
          </div>
        </Grid>
        <Grid
          container
          className="d-flex align-items-center justify-content-between mt-3 "
        >
          <VerticalLinearStepper steps={steps} finishName="Upload" />
        </Grid>
      </Grid>
    </>
  );
};

export default ImportFixedFields;

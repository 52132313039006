import { Divider, Grid } from "@mui/material";
import React from "react";
import InputField from "../../../../../components/formsUI/InputField";

const ProductDetails = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider>Product Details</Divider>
      </Grid>
      {/* <Grid item xs={6}>
        <InputField
          name="company_name"
          label="Company Name*"
          color="secondary"
          size="small"
        />
      </Grid> */}
      <Grid item xs={6}>
        <InputField
          name="brand"
          label="Brand*"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="title"
          label="Title*"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <InputField
          name="product_type"
          label="Product Type*"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="description"
          label="Description"
          color="secondary"
          size="small"
          maxRows={4}
          minRows={4}
          multiline
        />
      </Grid>
    </>
  );
};

export default ProductDetails;

import { Chip, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { MdDelete, MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  useDeleteAttachment,
  useDeleteNote,
} from "../../../../redux/actions/notesAction";
import getDomain from "../../../../utils/getDomain";
import DeleteDialog from "../../../utils/deleteDialog";

const NoteCard = ({ row, setEdit, forDeal }) => {
  const { mutate } = useDeleteNote(forDeal);
  const { mutate: onDelete } = useDeleteAttachment(forDeal);
  return (
    <div className="w-100 p-3 bg-white mb-3" style={{ borderRadius: "5px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            fontSize="16px"
            className="d-flex justify-content-between align-items-center"
          >
            {row.text}{" "}
            <div className="d-flex justify-content-evenly  align-items-center">
              <DeleteDialog
                mutate={mutate}
                uuid={row?.uuid}
                element={
                  <IconButton size="small">
                    <RiDeleteBin6Line style={{ color: "#616161" }} />
                  </IconButton>
                }
                description="This Note will be permanantly deleted."
              />

              <IconButton size="small" onClick={() => setEdit(row.uuid)}>
                <MdOutlineModeEdit style={{ color: "#616161" }} />
              </IconButton>
            </div>
          </Typography>
          <div className="text-lowercase">
            {row?.files?.map((item) => (
              <Chip
                key={item.id}
                onDelete={() => onDelete(item?.id)}
                deleteIcon={<MdDelete color="rgb(97, 97, 97)" />}
                label={
                  <a
                    href={`${
                      process.env.REACT_APP_API_PROTOCOL
                    }${getDomain()}${"."}${process.env.REACT_APP_API_URL}${
                      item.file
                    }`}
                    style={{ color: "#616161" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.file.split("/media/notes/")[1]}
                  </a>
                }
                size="medium"
                className="me-2 mt-3 fs-7 text-decoration-underline "
                clickable
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NoteCard;

import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tenantAPI } from "../../../../config/api";
import errorHandle from "../../../../utils/errorHandle";

const useImportLeads = () => {
  const navigate = useNavigate();
  const PostCSV = async (data) => {
    let formData = new FormData();
    formData.append("csv", data);
    return await tenantAPI.post(`/leads/bulk-upload/`, formData);
  };
  const Mutation = useMutation(PostCSV, {
    onSuccess: () => {
      toast.success("Leads imported successfully");
      navigate(-1);
    },
    onError: (err) => {
      console.log(err, "error");
      errorHandle(err);
    },
  });

  return { mutate: Mutation.mutate };
};

export default useImportLeads;

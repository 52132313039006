import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  root: {
    padding: "0px 10px 20px 10px ",
    "& .css-ypiqx9-MuiDialogContent-root": {
      padding: "0px 10px",
    },
  },
});

const CustomDialogNf = ({
  heading,
  mutate,
  maxWidth,
  addButton,
  closeButton,
  children,
  data,
  Name,
  styles,
}) => {
  const { root } = useStyle();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {Name ? (
        <div onClick={handleOpen}>{Name}</div>
      ) : (
        <Stack
          style={{
            // position: "absolute",
            // zIndex: 999,
            // top: "3px",
            // right: "40px",
            ...styles,
          }}
        >
          <IconButton onClick={handleOpen}>
            <MdAdd />
          </IconButton>
        </Stack>
      )}
      <Dialog
        open={openDialog}
        fullWidth
        scroll="paper"
        maxWidth={maxWidth}
        aria-labelledby="alert-Dialog-title"
        aria-describedby="alert-Dialog-description"
      >
        <DialogTitle
          style={{ backgroundColor: "#F2F4F7", padding: "13px 22px" }}
          id="alert-Dialog-title"
          className="border-bottom"
        >
          {heading}
        </DialogTitle>
        <DialogContent className={root}>
          <DialogContentText
            id="alert-Dialog-description"
            className="mt-1"
          ></DialogContentText>
          {children}
        </DialogContent>

        <DialogActions
          style={{ backgroundColor: "#F2F4F7" }}
          className="border-top"
        >
          <Button
            className="text-capitalize"
            onClick={handleClose}
            variant="outlined"
            sx={{ color: "#274C77" }}
          >
            {closeButton}
          </Button>

          <Button
            type="submit"
            autoFocus
            onClick={() => mutate(data, { onSuccess: () => handleClose() })}
            className="text-capitalize"
            variant="contained"
          >
            {addButton}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDialogNf;

// CustomDialogNf

import React from "react";

import { Grid, Typography } from "@mui/material";

import { useSelector } from "react-redux";

const ContactOwner = () => {
  const { contact } = useSelector((state) => state.contactStore);

  return (
    <Grid
      container
      sx={{
        borderBottom: "0.5px solid #C2C2C2",
        width: "100%",
      }}
      display='row'
      alignItems='center'
      className='py-3'
    >
      <Grid item xs={12} display={"flex"} justifyContent='space-between'>
        <Typography
          variant='p'
          fontSize='14px'
          style={{ color: "#616161", fontWeight: 500 }}
        >
          Owner
        </Typography>
        <Typography
          variant='p'
          fontSize='14px'
          sx={{ fontWeight: 500, color: "#919191" }}
        >
          {contact?.owner ? contact?.owner : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContactOwner;

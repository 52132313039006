import { Button, MenuItem, useMediaQuery } from "@mui/material";
import React from "react";
import { IoIosAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import DropDown from "../Layout/dropdown";

const NewLeadOptions = ({ handleClickOpen, matches }) => {
  const navigate = useNavigate();
  const lgMatch = useMediaQuery("(max-width:1000px)")

  return (
    <>
      <DropDown
        onMouseOver={!matches}
        button={
          <Button
            variant='outlined'
            sx={{
              "border": "2px solid #5582AD",
              "background": "#ffffff",
              "color": "#5582AD",
              "fontSize": lgMatch ? "10px" : "14px",
              "minWidth": matches ? "0px" : "auto",
              "&:hover": {
                background: "#ffffff",
              },
            }}
            size='small'
            className='d-flex align-items-center justify-content-center text-capitalize px-2'
          >
            {!matches && "add"}
            <span>
              <IoIosAdd />
            </span>
          </Button>
        }
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        PaperProps={{
          "elevation": 0,
          "sx": {
            "overflow": "visible",
            "filter": "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "mt": 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: matches ? 65 : 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        }}
      >
        <MenuItem onClick={() => handleClickOpen()}>Add Single Lead</MenuItem>
        <MenuItem
          onClick={() =>
            navigate(`/settings/import-data/import-leads`, { replace: true })
          }
        >
          Bulk Import Leads{" "}
        </MenuItem>{" "}
      </DropDown>
    </>
  );
};

export default NewLeadOptions;

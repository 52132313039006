import React from "react";

import { useParams } from "react-router-dom";

import { Backdrop, CircularProgress, Grid } from "@mui/material";

import Container from "../../../components/Layout/Container";
import AccountHeader from "../../../components/account/AccountHeader";
import AccountCard from "../../../components/account/accountCard";
import ContactHistory from "../../../components/contact/contactHistory";
import useSingleAccount from "./useSingleAccount";
import AccountActivity from "../../../components/account/accountActivity";

const SingleAccount = () => {
  const params = useParams();
  const { isLoading } = useSingleAccount(params.uuid);

  return (
    <>
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <AccountHeader />
          <Container
            sx={{ marginBottom: "15px", height: "calc(100vh - 154px)" }}
          >
            <Grid container>
              <AccountCard />
              <ContactHistory />
              <AccountActivity />
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default SingleAccount;

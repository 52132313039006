import { Grid, Box, Avatar, Typography, IconButton } from "@mui/material";

import React from "react";

import { useSelector } from "react-redux";
import ConvertToDeal from "../../../pages/deal/convertToDeal";
import getDomain from "../../../utils/getDomain";

const DealAvatar = () => {
  const { deal } = useSelector((state) => state.dealStore);
  const { user } = useSelector((state) => state.userStore);
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];
  return (
    <Box>
      <Grid
        container
        sx={{
          borderBottom: "2px solid #d1cac4",
          position: "relative",
        }}
        className="d-flex flex-column justify-content-center align-items-center py-4"
      >
        <Grid
          item
          xs={12}
          style={{ position: "absolute", top: "-6px", right: "-5px" }}
          className="d-flex justify-content-end"
        >
          <ConvertToDeal needButton isUpdate />
        </Grid>
        <Grid
          item
          xs={12}
          //   sm={2}
          //   md={3}
          //   lg={2}
          //   sx={{
          //     paddingTop: "2px",
          //   }}
        >
          <Avatar
            alt="avatar"
            src={`${process.env.REACT_APP_API_PROTOCOL}${getDomain()}${"."}${
              process.env.REACT_APP_API_URL
            }${userData?.profile_pic}`}
            // src=""
            sx={{
              width: 50,
              height: 50,
              display: "inline-block",
              //   margin: "3px 10px auto 10px",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          //   sm={2}
          //   md={3}
          //   lg={2}
          //   sx={{
          //     paddingTop: "2px",
          //   }}
        >
          <Typography
            className="pb-2"
            style={{ color: "#313131", fontSize: "1.2rem", fontWeight: 500 }}
          >
            {deal?.data?.name}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DealAvatar;

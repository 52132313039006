import { Grid, Tooltip, Typography } from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined"
import moment from "moment"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { PublishedWithChanges } from "@mui/icons-material"

const keysToIgnore = ["created_at"]
const NewContact = ({ audit }) => {
  return (
    <>
      <Typography className="d-flex align-items-start mb-2">
        <PublishedWithChanges fontSize="12px" className="me-3 mt-1" />
        <span>
          <span style={{ color: "#274c77", fontWeight: "bold" }}>
            {audit?.created_by}
          </span>
          <span className="ms-2">
            has converted{" "}
            <span style={{ color: "#274c77", fontWeight: "bold" }}>
              {audit?.content?.uuid}
            </span>{" "}
            Lead to Contact at
            <span className="text-muted">
              {" "}
              {moment(audit?.created_on).format("DD/MM/YYYY HH:MM A")}
            </span>
          </span>
        </span>
      </Typography>
    </>
  )
}
const Change = ({ audit }) => {
  return (
    <div className="px-2 pb-2">
      <Typography className="d-flex align-items-center mb-2">
        <EditOutlinedIcon fontSize="12px" className="me-2" />
        <span>
          Changes by{" "}
          {
            <span style={{ color: "#274c77", fontWeight: "bold" }}>
              {audit?.created_by}
            </span>
          }{" "}
          at
          <span className="text-muted">
            {" "}
            {moment(audit?.created_on).format("DD/MM/YYYY HH:MM A")}
          </span>
        </span>
      </Typography>
      {Object.entries(audit?.content).map((item) => {
        if (!keysToIgnore.includes(item[0])) {
          return (
            <Typography sx={{ fontSize: "12px" }} className="ms-4 mt-1 d-flex justify-content-between align-items-center">
              <div className="text-capitalize">
                {item[0]?.replace(/_/g, " ")}:{" "}
              </div>
              <Tooltip title={item[1]?.old_value}>
                <div
                  style={{
                    backgroundColor: "#FFCCCB",
                    marginLeft: "5px",
                    width: "200px",
                  }}
                  className="text-truncate"
                >
                  <s>{item[1]?.old_value}</s>
                </div>
              </Tooltip>
              <Tooltip title={item[1]?.new_value}>
                <div
                  style={{
                    backgroundColor: "#CCFFCD",
                    marginLeft: "5px",
                    width: "200px",
                  }}
                  className="text-truncate"
                >
                  {item[1]?.new_value}
                </div>
              </Tooltip>
            </Typography>
          )
        }
      })}
    </div>
  )
}
const Update = ({ audit }) => {
  return (
    <Grid
      container
      className="my-3"
      style={{ borderBottom: "1px solid #576a77a6" }}
    >
      {audit?.content?.new_contact ? (
        <NewContact audit={audit} />
      ) : (
        <Change audit={audit} />
      )}
    </Grid>
  )
}

export default Update

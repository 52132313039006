import React, { useState } from "react";

import Sequences from "./Sequences";
import UpcomingAppointments from "./UpcomingAppointments";
import OngoingDeals from "./OngoingDeals";
import DealRevenue from "./DealRevenue";

import { Grid, IconButton, Stack } from "@mui/material";
import SimpleAccordion from "../../Layout/accordion/Accordion";
import ContactAccordion from "../../deal/dealAccordion/accordionChildren/contact";
import CustomDialog from "../../Layout/customDialog/CustomDialog";
import { MdAdd } from "react-icons/md";
import { useSelector } from "react-redux";
import AddContactForm from "../../deal/dealAccordion/accordionChildren/contact/AddContactForm";
import useAddContact from "../../deal/dealAccordion/accordionChildren/contact/useAddContact";
import { useCreateNewAccountContact } from "../../../redux/actions/accountAction";

const AccountActivity = () => {
  const { account } = useSelector((state) => state.accountStore);
  const { user } = useSelector((state) => state.userStore);

  const { CONTACT_INITIAL_FORM_STATE, CONTACT_FORM_VALIDATION } =
    useAddContact();
  ["company_name", "title", "whatsapp"]?.map((item) => {
    delete CONTACT_FORM_VALIDATION?.fields[item];
    delete CONTACT_INITIAL_FORM_STATE[item];
  });

  const { mutate: addContact } = useCreateNewAccountContact(
    account?.uuid,
    user?.uuid
  );
  const [contactAccordion, setContactAccordion] = useState(false);
  const dialogButtonStyles = {
    position: "absolute",
    top: "3px",
    right: "40px",
  };

  const dialogButtonStylesOpen = {
    position: "absolute",
    top: "13px",
    right: "40px",
  };

  return (
    <Grid item xs={12} sm={12} md={3} lg={3} sx={{mt:{xs:2,md:0}}}>
      
        <Stack spacing={2}>
          <div style={{ position: "relative" ,width:"100%"}}>
            <SimpleAccordion
              key={1}
              name="Contacts"
              setState={() => setContactAccordion(!contactAccordion)}
              count={
                account?.contacts?.length > 0 ? (
                  <span>({account?.contacts?.length})</span>
                ) : (
                  " "
                )
              }
            >
              {account?.contacts?.map((item) => (
                <Grid className="py-1 border-bottom border-2">
                  <ContactAccordion key={item.uuid} data={item} />
                </Grid>
              ))}
            </SimpleAccordion>
            <CustomDialog
              styles={
                contactAccordion ? dialogButtonStylesOpen : dialogButtonStyles
              }
              mutate={addContact}
              maxWidth="md"
              button={
                <IconButton>
                  <MdAdd />
                </IconButton>
              }
              closeButton="Close"
              addButton="Add"
              initialValues={CONTACT_INITIAL_FORM_STATE}
              validationSchema={CONTACT_FORM_VALIDATION}
              heading="Add Contact"
            >
              <AddContactForm isCompany />
            </CustomDialog>
          </div>
          <Sequences />
          <DealRevenue />
          <OngoingDeals />
          <UpcomingAppointments />
        </Stack>
    </Grid>
  );
};

export default AccountActivity;

import { PlayForWork, CallOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import LeadDrower from "../leadDrower";
import useLeadData from "../leadHooks/useLeadData";
import useLeadDrower from "../leadDrower/useLeadDrower";

import { useLeadUpdate } from "../../../redux/actions/leadActions";
import PhoneMenu from "../../../components/ivr/PhoneMenu";

const useHomeLead = () => {
  const { isLoading, data } = useLeadData({});

  const { toggleDrawer } = useLeadDrower();

  const { mutate, isLoading: UpdateLoading } = useLeadUpdate();

  const createColumnSchema = (data) => {
    return (
      data &&
      Object.keys(data)
        .map((key, pos) =>
          [
            "uuid",
            "additional_fields",
            "created_on",
            "updated_on",
            "created_by",
            "middle_name",
            "id",
            "prefix",
          ].includes(key)
            ? null
            : {
                field: key,
                headerName: key.replace("_", " "),
                minWidth: 150,
                hide: pos > 10 ? true : false,
                headerClassName: "text-capitalize",
                cellClassName: "p-0",
                flex: 1,
                renderCell: (params) => {
                  if (params.field === "first_name") {
                    return (
                      <LeadDrower
                        name={
                          <Box
                            onClick={toggleDrawer}
                            className="ps-2 d-flex align-items-center"
                            sx={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              width: "100%",
                              fontSize: 13,
                              height: "100%",
                            }}
                          >
                            <PlayForWork />{" "}
                            {params.row.prefix
                              ? params.row.prefix + ". " + params.value
                              : params.value}
                          </Box>
                        }
                        heading="Lead Card"
                        data={params.row}
                        mutate={mutate}
                        isLoading={UpdateLoading}
                        needRightClickMenu={true}
                      />
                    );
                  } else if (
                    [
                      "primary_number",
                      "secondary_number",
                      "work_number",
                    ].includes(params.field)
                  ) {
                    return params.value ? (
                      <PhoneMenu
                        name={
                          <Box
                            className="ps-3 primary-main text-truncate second"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              width: "100%",
                              fontSize: 13,
                              height: "100%",
                            }}
                          >
                            <CallOutlined className="me-1" fontSize="13px" />
                            {params.value}
                          </Box>
                        }
                        phNum={params.value}
                      />
                    ) : (
                      <Box
                        className="ps-3 primary-main text-truncate second"
                        style={{
                          width: "100%",
                          height: "100%",
                          textDecoration: "none",
                        }}
                      >
                        {params.value}
                      </Box>
                    );
                  } else {
                    return (
                      <Box
                        className="ps-3 primary-main text-truncate d-flex align-items-center second"
                        style={{
                          width: "100%",
                          height: "100%",
                          textDecoration: "none",
                        }}
                      >
                        {params.value}
                      </Box>
                    );
                  }
                },
              }
        )
        .filter((item) => item !== null)
    );
  };

  return { isLoading, createColumnSchema, data };
};

export default useHomeLead;

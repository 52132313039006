import { Button, Grid, IconButton, Input, Typography, useMediaQuery } from "@mui/material";

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useAddProduct from "../../../../../pages/settings/product/productHooks/useAddProduct";
import MultiAutoCompleteNf from "../../../../formsUI/withoutFormik/MultiAutoCompleteNf";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import Table from "../../../../Layout/table";
import { Box } from "@mui/system";

const AddProductForm = ({
  onClick,
  mutate,
  setTableProduct,
  tableProduct,
  valuation,
  setValuation,
}) => {
  const { deal } = useSelector((state) => state.dealStore);
  const { data } = useAddProduct({
    select: (data) => {
      return data?.data?.data?.map((r) => ({
        ...r,
        id: r.uuid,
        quantity: 0,
        total_price: 0,
      }));
    },
    onSuccess: (data) => {
      let ids = deal?.data?.product_cart.map((item) => item?.product?.uuid);
      let products = data
        ? data?.filter((item) => !ids.includes(item?.uuid))
        : [];

      setProductServer(products);
    },
  });

  const [productServer, setProductServer] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    if (deal) {
      var product_ids = [];
      const defaultProduct = deal?.data?.product_cart.map((item) => {
        product_ids.push(item?.product?.uuid);
        return {
          ...item,
          ...item.product,
          uuid: item?.product?.uuid,
          total_price: item?.quantity * item?.product.base_price,
        };
      });
      setTableProduct(defaultProduct);
    }

    //eslint-disable-next-line
  }, [deal]);

  const handleChange = (value) => {
    setSelectedProduct(value);
  };

  const addProduct = () => {
    setTableProduct((prev) => [...prev, selectedProduct]);
    setSelectedProduct("");
  };

  useEffect(() => {
    let ids = tableProduct.map((item) => item?.uuid);

    let products = data
      ? data?.filter((item) => !ids.includes(item?.uuid))
      : [];
    setProductServer(products);
    //eslint-disable-next-line
  }, [tableProduct]);

  const handleChangePrice = (e) => {
    setTableProduct((prev) =>
      prev?.map((item) =>
        item.uuid === e.target.id
          ? {
              ...item,
              quantity: +e.target.value,
              total_price: +e.target.value * item?.base_price,
            }
          : item
      )
    );
  };

  const removeTableProduct = (uuid) => {
    setTableProduct(tableProduct?.filter((item) => item.uuid !== uuid));
  };

  const totalProductQuantity = () => {
    const sum = tableProduct?.reduce((prev, current) => {
      return prev + current?.quantity;
    }, 0);
    return sum;
  };

  useEffect(() => {
    if (tableProduct) {
      setValuation();
    }
    // eslint-disable-next-line
  }, [tableProduct]);

  const totalProductValue = () => {
    const total = tableProduct?.reduce((prev, current) => {
      return prev + current?.total_price;
    }, 0);
    setValuation(total);
    return total;
  };

  const columns = [
    {
      field: "title",
      headerName: "Product Name",
      // width: 200,
      flex: 1,
    },
    {
      field: "product_id",
      headerName: "Product Id",
      // width: 150,
      flex: 1,
    },
    {
      field: "base_price",
      headerName: "Base Price",
      flex: 1,
      // width: 50,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      // width: 50,
      renderCell: (params) => (
        <Input
          fullWidth={true}
          name="quantity"
          type="number"
          id={params.row.uuid}
          defaultValue={params.row.quantity}
          onChange={(e) => handleChangePrice(e)}
        />
      ),
    },

    {
      field: "total_price",
      headerName: "Total Price",
      flex: 1,
      // width: 50,
    },
    {
      field: "remove_contact",
      headerName: "Actions",
      flex: 1,
      cellClassName: "justify-content-center",
      // sx: { justifyContent: "center" },
      maxWidth: 30,
      renderCell: (params) => (
        <Box textAlign="center" width="inherit">
          <IconButton
            color="secondary"
            aria-label="remove product"
            onClick={() => removeTableProduct(params?.row?.uuid)}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const matches = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Grid
        item
        xs={12}
        className="d-flex justify-content-center align-items-center  mt-3"
      >
        {/* <Grid item xs={12}> */}
          {/* <SearchAppBar /> */}
        {/* </Grid> */}
        <Grid item xs={12} className="d-flex align-items-center">
          <Grid item xs={10}>
            <MultiAutoCompleteNf
              name="product"
              options={productServer ? [...productServer] : []}
              label="Add Product"
              getOptionLabel={(options) => options.title || ""}
              field="uuid"
              onChange={(e, value) => {
                handleChange(value);
              }}
              // value={}
              size="small"
            />
          </Grid>
          <Grid item xs={4} className="ms-2">
            <Button
              variant="outlined"
              onClick={
                selectedProduct
                  ? addProduct
                  : () => {
                      toast.error("No product selected");
                    }
              }
              className="text-capitalize"
            >
              {" "}
              { matches ? <AddBoxRoundedIcon/> : "Add Product" }
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Table
          className="p-2"
          style={{ width: "100%", height: "auto " }}
          columns={columns}
          hideFooter
          data={tableProduct ? tableProduct : []}
          loading=""
        />
      </Grid>
      <Grid item xs={12} className="mt-4">
        <Typography component="p">
          Total Products : {tableProduct?.length}
        </Typography>
        <Typography component="p">
          Total Products Quantity : {totalProductQuantity()}{" "}
        </Typography>
        <Typography component="p">
          Total Products Value : {totalProductValue()?.toFixed(2)}
        </Typography>
      </Grid>
      {/* <Button
        type="submit"
        className="text-capitalize mt-3"
        variant="contained"
        onClick={onClick}
        // onClick={() => mutate(tableProduct)}
      >
        Add to Deal
      </Button> */}
    </>
  );
};

export default AddProductForm;

import React from "react";

import { Grid, Typography, useMediaQuery } from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Container from "../Layout/Container";
import useGetAll from "../../hooks/useGetAll";

const LeadFooter = () => {
  const matches = useMediaQuery("(max-width:900px)");
  const { data } = useGetAll(`/leads/bottom_summary/`, {
    select: (data) => data?.data,
  });

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className="footer bg-white d-block"
      component="footer"
      sx={{ borderTop: "2px solid #d1cac4" }}
    >
      <Container className="mt-0">
        <Grid container direction="row" justifyContent={"space-around"}>
          <Typography
            variant="p"
            className="second my-auto"
            fontSize={matches ? "12px" : "15px"}
            fontWeight={500}
          >
            TOTAL LEADS{" "}
            <ArrowForwardIcon
              fontSize={matches ? "12px" : "15px"}
              className="me-1"
              sx={{ marginBottom: "2px" }}
            />
            {data?.Total_Leads}
          </Typography>
          <Typography
            variant="p"
            className="second my-auto"
            fontSize={matches ? "12px" : "15px"}
          >
            NEW{" "}
            <ArrowForwardIcon
              fontSize={matches ? "12px" : "15px"}
              className="me-1"
              sx={{ marginBottom: "2px" }}
            />
            {data?.New}
          </Typography>
          <Typography
            variant="p"
            className="second my-auto"
            fontSize={matches ? "12px" : "15px"}
          >
            IN PROGRESS{" "}
            <ArrowForwardIcon
              fontSize={matches ? "12px" : "15px"}
              className="me-1"
              sx={{ marginBottom: "2px" }}
            />
            {data?.In_Progress}
          </Typography>
          <Typography
            variant="p"
            className="second my-auto"
            fontSize={matches ? "12px" : "15px"}
          >
            UNQUALIFIED{" "}
            <ArrowForwardIcon
              fontSize={matches ? "12px" : "15px"}
              className="me-1"
              sx={{ marginBottom: "2px" }}
            />
            {data?.Unqualified}
          </Typography>
          <Typography
            variant="p"
            className="second my-auto"
            fontSize={matches ? "12px" : "15px"}
          >
            QUALIFIED{" "}
            <ArrowForwardIcon
              fontSize={matches ? "12px" : "15px"}
              className="me-1"
              sx={{ marginBottom: "2px" }}
            />
            {data?.Qualified}
          </Typography>
        </Grid>
      </Container>
    </Grid>
  );
};

export default LeadFooter;

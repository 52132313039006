import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const DealOwner = () => {
  const { deal } = useSelector((state) => state.dealStore);
  return (
    <Box>
      <Grid
        container
        sx={{
          borderBottom: "2px solid #d1cac4",
        }}
        display="row"
        alignItems="center"
        className="py-2"
      >
        <Grid item xs={12} display={"flex"} justifyContent="space-between">
          <Typography
            variant="p"
            fontSize="14px"
            style={{ color: "#616161", fontWeight: 500 }}
          >
            Owner
          </Typography>
          <Typography
            variant="p"
            fontSize="14px"
            sx={{ fontWeight: 500, color: "#919191" }}
          >
            {deal?.data?.owner}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DealOwner;

import { Typography } from "@mui/material";
import React from "react";

import Chart from "react-apexcharts";

import DashboardCard from "../../../components/home/DashboardCard";

function DealLostReason() {
  var options = {
    chart: {
      type: "bar",
      toolbar: {
        export: {
          csv: {
            filename: "Deals-Lost-Reason",
          },
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        // columnWidth:100,
        barHeight:'40%',
        horizontal: true,
        distributed: true,
      },
    },
    colors: ["#9575CD", "#7986CB", "#5C6BC0", "#3F51B5", "#64B5F6", "#CE93D8"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [
        "South Korea",
        "Canada",
        "United Kingdom",
        "Netherlands",
        "Italy",
        "France",
      ],
      labels: {
        formatter: function (value) {
          return value + " %";
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  var series = [
    {
      data: [19, 43, 32, 24, 55, 62],
    },
  ];

  return (
    <DashboardCard sx={{ padding: "10px", height: "410px" }}>
      <Typography component="p" fontSize="16px" fontWeight="500">
        Deal Lost Reason
      </Typography>
      <Typography
        fontSize="12px"
        fontWeight="500"
        component="p"
        className="second"
      >
        Current Month - June
      </Typography>
      <Chart options={options} series={series} type="bar" height={300} />
    </DashboardCard>
  );
}

export default DealLostReason;

import axios from "axios";
import { useQuery } from "react-query";

const useGetTemplates = (options) => {
  return useQuery(
    "whatsapp_templates",
    async () =>
      await axios.get("https://app-server.wati.io/api/v1/getMessageTemplates", {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyYjMzYTA5OC01Y2JlLTQxMDktOWRhYS0yZWQxNDQ5ZDk5MDEiLCJ1bmlxdWVfbmFtZSI6Inlhc2luLmFuc2FyaUBmbGFkZHJhLmNvbSIsIm5hbWVpZCI6Inlhc2luLmFuc2FyaUBmbGFkZHJhLmNvbSIsImVtYWlsIjoieWFzaW4uYW5zYXJpQGZsYWRkcmEuY29tIiwiYXV0aF90aW1lIjoiMDkvMDUvMjAyMiAxMToxNTo0MSIsImRiX25hbWUiOiJ3YXRpX2FwcCIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IlRSSUFMIiwiZXhwIjoxNjYzMDI3MjAwLCJpc3MiOiJDbGFyZV9BSSIsImF1ZCI6IkNsYXJlX0FJIn0.zal7pd0QTZTAGVGvHUUa3AQsoe5wPHzsbUpkFTXQYws",
        },
      }),
    {
      ...options,
      select: (data) => data.data.messageTemplates,
    }
  );
};

export default useGetTemplates;

import * as Yup from "yup";

const useUpdateTask = (data) => {
  const TASK_UPDATE_INITIAL_FORM_STATE = {
    task_title: data?.task_title ? data?.task_title : "",
    related_links: data?.related_links ? data?.related_links : "",
    related_to: "",
    remind_at: data?.remind_at ? data?.remind_at : "",
    due_date: data?.due_date ? data?.due_date : "",
    task_priority: data?.task_priority ? data?.task_priority : "",
    description: data?.description ? data?.description : "",
  };

  const TASK_FORM_VALIDATION = Yup.object().shape({
    task_title: Yup.string().required("Task Title is Required"),
    related_links: Yup.string()
      .required("Related Links is Required")
      .matches(
        /((((https?)?:\/\/)(www\.)?|www\.))([a-z0-9.]+)(\.[a-z]{2,4})(\.[a-z]{1,2})?([^?\s]+(\?((\w+)(=[^&\s]+)?&?)+)?)?/,
        "Please enter Valid Link"
      ),
    related_to: Yup.string(),
    remind_at: Yup.date().required("Reminder is Required"),
    due_date: Yup.date().required(),
    task_priority: Yup.mixed().required(
      "Select a Task Priority it is Required"
    ),
    description: Yup.string(),
  });

  return {
    TASK_UPDATE_INITIAL_FORM_STATE,
    TASK_FORM_VALIDATION,
  };
};

export default useUpdateTask;

import React from "react";
import { MenuItem } from "@mui/material";
import { IoMdCall } from "react-icons/io";
import { MdMessage } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import RightClickMenu from "../lead/RightClickMenu";
import useClickToCall from "./useClickToCall";
import { toast } from "react-toastify";
import useSendSMS from "./useSendSMS";
import DeleteDialog from "../utils/deleteDialog";

const PhoneMenu = ({ name, phNum, removeWhatsapp }) => {
  const { refetch } = useClickToCall(phNum);
  const { refetch: smsRefetch } = useSendSMS(phNum);
  return (
    <RightClickMenu name={name} isPhNum={true}>
      <DeleteDialog
        getApiRefetch={() => {
          toast.promise(refetch, {
            pending: "pending",
            success: "Call Connected!",
            error: "Sorry, something went wrong. Try again later.",
          });
        }}
        element={
          <MenuItem sx={{ fontSize: "13px" }}>
            <IoMdCall className="me-2" /> Call Now
          </MenuItem>
        }
        description="This will make a phone call on their primary number."
        anotherAction="Call"
      />
      {/* <MenuItem sx={{ fontSize: "13px" }}>
        <IoMdCall className="me-2" /> Call Now
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          toast.promise(() => smsRefetch(), {
            pending: "pending",
            success: "SMS sent!",
            error: "Sorry, something went wrong. Try again later.",
          });
        }}
        sx={{ fontSize: "13px" }}
      >
        <MdMessage className="me-2" /> Message
      </MenuItem>
      {!removeWhatsapp && (
        <MenuItem onClick={() => {}} sx={{ fontSize: "13px" }}>
          <BsWhatsapp className="me-2" /> Whatsapp
        </MenuItem>
      )}
    </RightClickMenu>
  );
};

export default PhoneMenu;

import { useState } from "react";

import * as Yup from "yup";

const useConvertToContact = (handleClose, data) => {
  const [open, setOpen] = useState(false);
  const defaultState = {
    uuid: data.uuid ? data.uuid : "",
    owner:
      data.owner_name && data.owner
        ? { label: data.owner_name, uuid: data.owner }
        : "",

    first_name: data.first_name ? data.first_name : "",
    middle_name: data.middle_name ? data.middle_name : "",
    last_name: data.last_name ? data.last_name : "",
    primary_number: data.primary_number ? data.primary_number : "",

    company_name: data.company_name ? data.company_name : "",
    gst_number: data.gst_number ? data.gst_number : "",
    primary_email: data.primary_email ? data.primary_email : "",
  };

  const validation = Yup.object().shape({
    owner: Yup.mixed().required("This field is required"),

    first_name: Yup.string()
      .required("This field is required")
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    middle_name: Yup.string().matches(
      /^[aA-zZ\s\-|_]+$/,
      "You Can Only Use ['Aa-Zz','-','_']"
    ),
    last_name: Yup.string()
      .required("This field is required")
      .matches(/^[aA-zZ\s\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    primary_number: Yup.string()
      .required("This field is required")
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
      ),

    company_name: Yup.string()
      .required("This field is required")
      .matches(/^[aA-zZ\s|0-9|\-|_]+$/, "You Can Only Use ['Aa-Zz','-','_']"),
    gst_number: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
    ),
    primary_email: Yup.string()
      .email("Please enter a valid email.")
      .required("This field is required"),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCtoCClose = () => {
    setOpen(false);
    handleClose();
  };
  return { open, defaultState, validation, handleClickOpen, handleCtoCClose };
};

export default useConvertToContact;

import useAccountData from "../accountHooks/useAccountData";
import { Link } from "react-router-dom";
import { CallOutlined, Person } from "@mui/icons-material";
import RightClickMenu from "../../../components/lead/RightClickMenu";
import PhoneMenu from "../../../components/ivr/PhoneMenu";
import { Box } from "@mui/system";
import DeleteDialog from "../../../components/utils/deleteDialog";
import { MenuItem } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useAccountDelete } from "../../../redux/actions/accountAction";

const useAccountTable = () => {
  const { isLoading, data } = useAccountData({});

  const { mutate: deleteAccount } = useAccountDelete();

  const createColumnSchema = (data) => {
    return (
      data &&
      Object.keys(data)
        .map((key, pos) =>
          [
            "uuid",
            "additional_fields",
            "created_at",
            "updated_at",
            "created_by",
            "middle_name",
            "id",
            "notes",
            "contacts",
          ].includes(key)
            ? null
            : {
                field: key,
                headerName: key.replace("_", " "),
                minWidth: 150,
                hide: pos > 10 ? true : false,
                headerClassName: "text-capitalize full-width",
                cellClassName: "p-0",
                flex: 1,
                renderCell: (params) => {
                  if (params.field === "account_owner") {
                    return (
                      <>
                        <RightClickMenu
                          className="d-flex"
                          name={
                            <Link
                              to={`/accounts/${params.id}`}
                              className="ps-3 primary-main text-truncate second text-decoration-underline"
                              style={{
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <Person className="mb-1 me-2" />
                              {params.value}
                            </Link>
                          }
                        >
                          <DeleteDialog
                            mutate={deleteAccount}
                            uuid={data?.uuid}
                            element={
                              <MenuItem sx={{ fontSize: "13px" }}>
                                <DeleteForeverIcon
                                  fontSize="18"
                                  className="me-2"
                                />
                                Delete
                              </MenuItem>
                            }
                            description="This Account will be permanantly deleted."
                          />
                        </RightClickMenu>
                      </>
                    );
                  } else if (
                    [
                      "primary_number",
                      "secondary_number",
                      "work_number",
                    ].includes(params.field)
                  ) {
                    return params.value ? (
                      <PhoneMenu
                        name={
                          <Box
                            className="ps-3 primary-main text-truncate second"
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                              width: "100%",
                              fontSize: 14,
                              height: "100%",
                            }}
                          >
                            <CallOutlined className="me-1" fontSize="12px" />
                            {params.value}
                          </Box>
                        }
                        phNum={params.value}
                      />
                    ) : (
                      <Box
                        className="ps-3 primary-main text-truncate second"
                        style={{
                          width: "100%",
                          height: "100%",
                          textDecoration: "none",
                        }}
                      >
                        {params.value}
                      </Box>
                    );
                  } else {
                    return (
                      <Link
                        to={`/accounts/${params.id}`}
                        className="ps-3 primary-main d-flex align-items-center text-truncate second"
                        style={{
                          width: "100%",
                          height: "100%",
                          textDecoration: "none",
                        }}
                      >
                        {" "}
                        {params.value}
                      </Link>
                    );
                  }
                },
              }
        )
        .filter((item) => item !== null)
    );
  };

  return { isLoading, createColumnSchema, data };
};

export default useAccountTable;

export const leadAndContactColumns = [
  {
    field: "first_name",
    headerName: "First Name",
    width: 200,
    flex: 1,
  },
  {
    field: "Last_name",
    headerName: "Last Name",
    width: 200,
    flex: 1,
  },
  {
    field: "Primary Number",
    headerName: "Primary Number",
    width: 200,
    flex: 1,
  },
  {
    field: "Secondary Number",
    headerName: "Secondary Number",
    width: 200,
    flex: 1,
  },
  {
    field: "Created At",
    headerName: "Created At",
    width: 150,
    flex: 1,
  },
  {
    field: "Updated At",
    headerName: "Updated At",
    flex: 1,
  },
];

import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 4,
    borderBottom: "none",
    "& .MuiFormControl-root": {
      height: "100%",
    },
    "& .MuiFilledInput-root": {
      background: "none",
    },
    marginTop: "16px",
  },
  textField: {
    "& .MuiInput-root": {
      height: "100%",
      margin: "0px",
      padding: "0px 12px",
      border: "2px solid black",
      borderRadius: 4,
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
      "& .MuiInput-input": {
        height: "64px",
      },
      "& .MuiSvgIcon-root": {
        display: "none",
      },
    },
  },
});

const AutoSelectFieldNf = ({
  textFieldProps,
  options,
  className,
  name,
  multiple,
  label,
  value,
  getOptionLabel,
  variant,
  ...otherProps
}) => {
  const { root } = useStyles();
  return (
    <>
      <Autocomplete
        id="free-solo-demo"
        sx={{
          width: "100%",
          height: "100%",
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend": {
            display: label ? "block" : "none",
          },
        }}
        size="small"
        className={root}
        options={options}
        name={name}
        getOptionLabel={getOptionLabel}
        {...otherProps}
        value={value}
        noOptionsText="There are no options to show"
        renderInput={(params) => (
          <TextField
            hiddenLabel
            InputLabelProps={{ shrink: true }}
            {...params}
            label={label}
            {...otherProps}
            variant={variant || "outlined"}
          />
        )}
      />
    </>
  );
};

export default AutoSelectFieldNf;

import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useField, useFormikContext } from "formik";
import countries from "../../data/countries";

const CountrySelect = ({
  name,
  data,
  selectValue,
  defaultValue,
  ...otherProps
}) => {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const configSelect = {
    ...field,
    ...otherProps,
    variant: "outlined",
    fullWidth: true,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }
  return (
    <Autocomplete
      id="country-select-demo"
      fullWidth={true}
      size="small"
      value={values[name]}
      options={countries}
      autoHighlight
      onChange={(_, value) => setFieldValue(name, value)}
      getOptionLabel={(option) => option}
      renderOption={(props, option) =>
        option.length >= 1 && (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option
                .split("(")[1]
                .slice(0, -1)
                .toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.toLowerCase()}.png 2x`}
              alt=""
            />
            {option}
          </Box>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          error={configSelect.error}
          helperText={configSelect.helperText}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelect;

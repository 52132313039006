import Navbar from "../../components/Layout/navbar";
import RolesTable from "./rolesTable";

const index = () => {
  return (
    <>
      <Navbar />
      <RolesTable></RolesTable>
    </>
  );
};

export default index;

import { Typography } from "@mui/material";
import React from "react";

import Chart from "react-apexcharts";

import DashboardCard from "../../../components/home/DashboardCard";
import RevenueFromProduct from "../../../components/home/RevenueFromProduct";

function YearlySalesReport() {
  var options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
        export: {
          csv: {
            filename: "Yearly-Sales-Report",
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "string",
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };
  var series = [
    {
      name: "Product 1",
      data: [310, 400, 280, 510, 420, 1090, 300, 210, 320, 450, 320, 340],
    },
    {
      name: "Product 2",
      data: [210, 320, 450, 320, 340, 520, 410, 310, 400, 280, 510, 420],
    },
  ];

  return (
    <DashboardCard sx={{ padding: "10px", height: "410px" }}>
      <Typography component="p" fontWeight="500" fontSize="16px">
        Yearly Products Sales Report
      </Typography>
      <Typography
        fontSize="12px"
        fontWeight="400"
        component="p"
        className="second"
      >
        Year 2021
      </Typography>
      <div className="d-flex">
        <RevenueFromProduct
          name="Revenue from Product 01"
          revenue={"3,54,276"}
          revLastYear={-11.2}
          sx={{ padding: "10px", marginRight: "1rem" }}
        />
        <RevenueFromProduct
          name="Revenue from Product 02"
          revenue={"2,55,276"}
          revLastYear={11.2}
          sx={{ padding: "10px" }}
        />
      </div>
      <Chart
        options={options}
        series={series}
        type="area"
        height={250}
        style={{ marginTop: "-20px" }}
      />
    </DashboardCard>
  );
}

export default YearlySalesReport;

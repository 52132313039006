import { FaUserEdit } from "react-icons/fa";
import { Box } from "@mui/material";
import React, { useState } from "react";
import useUserData from "../userHooks/useAddUser";
import UserDrower from "../userDrower/index";
import { useUserUpdate } from "../../../../redux/actions/userAction";

const useHomeUser = () => {
  const [usersData, setUsersData] = useState([]);
  const { isLoading, data } = useUserData({
    onSuccess: (data) => setUsersData(data?.data),
  });
  const { mutate } = useUserUpdate();
  const newData = usersData?.map((item) => {
    let temp = { ...item, ...item.profile };
    delete temp["profile"];
    return temp;
  });
  const createColumnSchema = (newData) => {
    let data = { ...newData };
    for (const field of [
      "id",
      "uuid",
      "is_active",
      "additional_fields",
      "user",
      "profile_pic",
      "updated_on",
      "created_on",
    ]) {
      delete data[field];
    }
    return (
      data &&
      Object.keys(data)
        .map((key, pos) => ({
          field: key,
          headerName: key.replace("_", " "),
          minWidth: 150,
          type: "dateTime",
          hide: pos > 10 ? true : false,
          headerClassName: "text-capitalize",
          cellClassName: "p-0",
          flex: 1,
          order: key === "full_name" ? 1 : 0,
          renderCell: (params) => {
            return params.field === "full_name" ? (
              <UserDrower
                name={
                  <Box
                    className="ps-2 d-flex align-items-center"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      width: "100%",
                      fontSize: 14,
                      height: "100%",
                    }}
                  >
                    <FaUserEdit size={18} className="me-2" /> {params.value}
                  </Box>
                }
                heading="User Card"
                data={params.row}
                mutate={mutate}
              />
            ) : (
              <Box
                className="ps-3 primary-main text-truncate second"
                style={{
                  width: "100%",
                  height: "100%",
                  textDecoration: "none",
                }}
              >
                {params.value}
              </Box>
            );
          },
        }))
        .sort((a, b) => b.order - a.order)
    );
  };
  return { isLoading, createColumnSchema, data: newData };
};

export default useHomeUser;

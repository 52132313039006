import React from "react";
import Navbar from "../../components/Layout/navbar";

const index = () => {
  return (
    <>
      <Navbar />
      <div
        style={{ width: "100%", height: "calc(100vh - 50px)" }}
        className='d-flex justify-content-center align-items-center'
      >
        <img src='/coming-soon.jpg' alt='coming soon' width='250px' />
      </div>
    </>
  );
};

export default index;

import React from "react";

import { IconButton, MenuItem, useMediaQuery } from "@mui/material";
import { AttachMoney, Person } from "@mui/icons-material";
import { PlayForWork } from "@mui/icons-material";

import DropDown from "../dropdown";
import {
  openContactDialog,
  openLeadDialog,
} from "../../../redux/actions/LayoutAction";
import { useDispatch } from "react-redux";
import { IoAdd } from "react-icons/io5";

const AddDropDown = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const handleClickLeadOpen = () => {
    dispatch(openLeadDialog());
  };
  const handleClickContactOpen = () => {
    dispatch(openContactDialog());
  };

  return (
    <>
      <DropDown
        button={
          <>
            <IconButton sx={{ color: "#ffffff" }}>
              <IoAdd />
            </IconButton>
          </>
        }
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClickLeadOpen();
          }}
          sx={{ cursor: "pointer" }}
        >
          <PlayForWork
            color="secondary.dark"
            className="me-2"
            sx={
              !matches
                ? { cursor: "pointer" }
                : { cursor: "pointer", fontSize: 17 }
            }
          />
          Lead
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClickContactOpen();
          }}
          sx={{ cursor: "pointer" }}
        >
          <Person
            color="secondary.dark"
            className="me-2"
            sx={
              !matches
                ? { cursor: "pointer" }
                : { cursor: "pointer", fontSize: 17 }
            }
          />
          Contact
        </MenuItem>
        <MenuItem
          className="second"
          sx={
            !matches
              ? { cursor: "pointer" }
              : { cursor: "pointer", fontSize: 14 }
          }
        >
          {" "}
          <AttachMoney
            color="secondary.dark"
            className="me-2"
            sx={
              !matches
                ? { cursor: "pointer" }
                : { cursor: "pointer", fontSize: 17 }
            }
          />
          Deal
        </MenuItem>
      </DropDown>
    </>
  );
};

export default AddDropDown;

import * as Yup from "yup";

const useAddContact = () => {
  const CONTACT_INITIAL_FORM_STATE = {
    first_name: "",
    primary_email: "",
    primary_number: "",
    title: "",
    last_name: "",
    company_name: "",
    whatsapp: "",
  };
  const CONTACT_FORM_VALIDATION = Yup.object().shape({
    first_name: Yup.string().required("This Field is Rquired"),
    primary_email: Yup.string().email().required("This Field is Rquired"),
    primary_number: Yup.string().required("This Field is Rquired"),
    title: Yup.string().required("This Field is Rquired"),
    last_name: Yup.string().required("This Field is Rquired"),
    company_name: Yup.string().required("This Field is Rquired"),
    whatsapp: Yup.string().required("This Field is Rquired"),
  });
  return {
    CONTACT_INITIAL_FORM_STATE,
    CONTACT_FORM_VALIDATION,
  };
};

export default useAddContact;

import { QueryClient } from "react-query";

// Create a client
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,

      onError: (error) => {
        console.log("errrrrrrrrrrrrrrrrr");
        if (error.response) {
          console.log(error);
          if (error.response.status === 406 && !error.response.data.is_active) {
            console.log(error.response.data.is_active);
            console.log(window);
            window.location.pathname = "/subscription-expired";
          }
        }
      },
    },
  },
});

export default client;

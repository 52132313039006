import React from "react";

import { Divider, Grid } from "@mui/material";

import { sourceData } from "../../../../utils/leadVariables";

import InputField from "../../../../components/formsUI/InputField";
import SelectField from "../../../../components/formsUI/SelectField";
import AutoSelectField from "../../../../components/formsUI/AutoComplete";
import useAccountData from "../../../account/accountHooks/useAccountData";
import { useLocation } from "react-router-dom";

const CompanyInfo = () => {
  const { data: data } = useAccountData();
  const Options = data?.data?.map((company) => ({
    uuid: company.id,
    label: company.company_name,
  }));

  const { pathname } = useLocation();

  return (
    <>
      <Grid item xs={12}>
        <Divider>Company Info</Divider>
      </Grid>
      <Grid item xs={12} sm={6}>
        {pathname.startsWith("/leads") ? (
          <InputField
            name="company_name"
            label="Company"
            color="secondary"
            size="small"
          />
        ) : (
          <AutoSelectField
            name="company"
            label="Company"
            size="small"
            multiple={false}
            options={Options ? Options : []}
            getOptionLabel={(options) => options?.label || options || ""}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="gst_number"
          label="GST Number"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="primary_email"
          label="Primary Email Address *"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="secondary_email"
          label="Secondary Email Address"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="website"
          label="Website"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name="source"
          label="Source  *"
          color="secondary"
          data={sourceData}
          selectValue="name"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="industry"
          label="Industry *"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="fax_number"
          label="Fax Number"
          color="secondary"
          size="small"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name="title"
          label="Job Title*"
          color="secondary"
          size="small"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <InputField name="tags" label="Tags" color="secondary" size="small" />
      </Grid>
    </>
  );
};

export default CompanyInfo;

import { Typography } from "@mui/material";

import React, { useState } from "react";
import AddNote from "./AddNote";
import moment from "moment";
import NoteCard from "./NoteCard";
import { useSelector } from "react-redux";

const Notes = ({ forDeal }) => {
  const [isUpdate, setIsUpdate] = useState(null);

  let data = {};
  const { deal } = useSelector((state) => state.dealStore);
  const { contact } = useSelector((state) => state.contactStore);

  if (forDeal) data = deal?.data?.notes;
  else data = contact?.notes;

  const isDifferentDay = (messageIndex) => {
    if (messageIndex === 0) return true;

    const d1 = new Date(data[messageIndex - 1].created_at);
    const d2 = new Date(data[messageIndex].created_at);

    return (
      d1.getFullYear() !== d2.getFullYear() ||
      d1.getMonth() !== d2.getMonth() ||
      d1.getDate() !== d2.getDate()
    );
  };

  const setEdit = (uuid) => {
    setIsUpdate(uuid);
  };
  return (
    <>
      <AddNote forDeal={forDeal} />
      <div
        style={{
          maxHeight: "calc(100vh - 300px)",
          overflowY: "auto",
          marginTop: "10px",
        }}
      >
        {data?.map((row, index) => {
          return (
            <div>
              {isDifferentDay(index) && (
                <Typography
                  fontSize="14px"
                  color="#616161"
                  className="pe-3 mb-2"
                  fontWeight={500}
                >
                  {moment(row.created_at).format(
                    "DD/MM/YYYY\xa0\xa0\xa0\xa0hh:mm A"
                  )}
                </Typography>
              )}
              {isUpdate && isUpdate === row.uuid ? (
                <AddNote
                  isUpdate
                  row={row}
                  setEdit={setEdit}
                  forDeal={forDeal}
                />
              ) : (
                <NoteCard row={row} setEdit={setEdit} forDeal={forDeal} />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Notes;

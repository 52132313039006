import { Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DashboardCard from "../../../components/home/DashboardCard";
import { styled } from "@mui/material/styles";
import { useDealsClosingAndStage } from "../homeHooks/useHomeData";
import PhoneMenu from "../../../components/ivr/PhoneMenu";
import { MailOutline, Phone } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: "white !important",
    color: "black",
    padding: "5px 3px",
    textAlign: "center",
    fontSize: 14,
    borderBlock: "3px solid rgba(0, 0, 0, 0.25)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    background: "white !important",
    padding: "5px 8px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  root: {
    backgroundColor: "red !important",
  },
  "&:hover": {
    background: "rgba(0, 0, 0, 0.10) !important",
  },
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
}));

function ClosingDealsMonth() {
  const columns = [
    { id: "deal_name", label: "Deal Name" },
    { id: "company", label: "Company" },
    {
      id: "stage",
      label: "Stage",
    },
    {
      id: "closing_date",
      label: "Closing Date",
    },
    {
      id: "contact_deal",
      label: "Contact",
    },
  ];

  const { data } = useDealsClosingAndStage();
  const dealsClosingThisMon = data?.dealsClosingThisMonth;

  const navigate = useNavigate();

  return (
    <DashboardCard sx={{ padding: "10px", height: "410px" }}>
      <div
        className="d-flex justify-content-between border-bottom"
        style={{ padding: "5px 30px 15px 15px" }}
      >
        <Typography component="p" fontSize="16px" fontWeight="500">
          <span className="me-2">$</span> Deals Closing this Month
        </Typography>
        <Typography component="p" fontSize="16px" fontWeight="500">
          {dealsClosingThisMon?.length}
        </Typography>
      </div>
      <TableContainer sx={{ maxHeight: 340 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  tyle={{ minWidth: column.minWidth, fontWeight: "600" }}
                  className="bg-third"
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dealsClosingThisMon?.map((deal) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/deals/${deal.uuid}/`);
                  }}
                >
                  <StyledTableCell sx={{ color: "black" }}>
                    {deal.name}
                  </StyledTableCell>
                  <StyledTableCell>
                    {deal.contact.first_name + " " + deal.contact.last_name}
                    <br />
                    {deal.contact.company_name}
                  </StyledTableCell>
                  <StyledTableCell>{deal.status.stage_name}</StyledTableCell>
                  <StyledTableCell>{"need in api"}</StyledTableCell>
                  <StyledTableCell>
                    <span className="d-flex justify-content-around">
                      <IconButton size="small">
                        <MailOutline
                          style={{ color: "black", fontSize: "13px" }}
                        />
                      </IconButton>
                      <PhoneMenu
                        name={
                          <IconButton size="small">
                            <Phone
                              style={{ color: "black", fontSize: "13px" }}
                            />
                          </IconButton>
                        }
                        phNum={deal.contact.primary_number}
                      />
                    </span>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div />
    </DashboardCard>
  );
}

export default ClosingDealsMonth;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import getDomain from "./getDomain";
import resetLocalStorage from "./resetLocalStorage";
import setAuthToken from "./setAuthToken";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (localStorage.getItem("domain") !== getDomain()) {
  //     window.location.replace(process.env.REACT_APP_FE_REDIRECT_URL);
  //     resetLocalStorage();
  //   }
  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/my-crm/log-in");
      resetLocalStorage();
    } else {
      setAuthToken(localStorage.getItem("token"));
    }
    //eslint-disable-next-line
  }, [localStorage.getItem("token")]);

  return children;
};

export default PrivateRoute;

import React from "react";

import Drawer from "@mui/material/Drawer";
import { Button, MenuItem, useMediaQuery } from "@mui/material";
import { Grid, Box } from "@mui/material";

import { Formik, Form } from "formik";

import useCreateLead from "../newLead/useCreateLead";

import SubmitButton from "../../../components/formsUI/SubmitButton";
import PersonalInfo from "../newLead/CreateComponents/PersonalInfo";
import CompanyInfo from "../newLead/CreateComponents/CompanyInfo";
import LeadInfo from "../newLead/CreateComponents/LeadInfo";
import SocialInfo from "../newLead/CreateComponents/SocialInfo";
import KeyboardBadge from "../../../components/Layout/KeyboardBadge";
import useLeadDrower from "./useLeadDrower";
import AdditionalInfo from "../newLead/CreateComponents/AdditionalInfo";
import AddressSectionWrapper from "../newLead/CreateComponents/AddressSectionWrapper";
import RightClickMenu from "../../../components/lead/RightClickMenu";
import { useLeadDelete } from "../../../redux/actions/leadActions";
import { Edit } from "@mui/icons-material";
import ConvertToContact from "../../contact/convertToContact";
import DeleteDialog from "../../../components/utils/deleteDialog";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Index = ({
  name,
  data,
  mutate,
  isLoading,
  heading,
  needRightClickMenu,
  needEditButton,
}) => {
  const { state, defaultState, toggleDrawer, accountDefaultState } =
    useLeadDrower(data);
  const { validation, accountValidation } = useCreateLead(null);

  const sm = useMediaQuery("(max-width:600px)");
  const { mutate: deleteLead } = useLeadDelete();
  console.log(heading,"inside lead drower for contact");
  return (
    <>
      {needRightClickMenu && (
        <RightClickMenu name={<div onClick={() => toggleDrawer()}>{name}</div>}>
          <MenuItem
            onClick={() => {
              toggleDrawer();
            }}
            sx={{ fontSize: "13px" }}
          >
            <Edit fontSize="18" className="me-2" />
            Edit Lead
          </MenuItem>
          <ConvertToContact data={data} />
          <DeleteDialog
            mutate={deleteLead}
            uuid={data?.uuid}
            description="This Lead will be permanantly deleted."
            element={
              <MenuItem sx={{ fontSize: "13px" }}>
                <DeleteForeverIcon fontSize="18" className="me-2" />
                Delete
              </MenuItem>
            }
          />
        </RightClickMenu>
      )}
      {needEditButton && <div onClick={toggleDrawer}>{name}</div>}
      <Drawer anchor={"right"} open={state} onClose={toggleDrawer}>
        <Box sx={{ width: "80vw" }}>
          <Box
            sx={{ background: "#ededed" }}
            className="border-bottom border-dark p-3 py-4 d-flex justify-content-between align-items-center"
          >
            {heading}
            {!sm && (
              <span className="text-muted ">
                Press <KeyboardBadge ele="Esc" /> to close
              </span>
            )}
          </Box>
          <Formik
            initialValues={
              heading === "Account Card"
                ? { ...accountDefaultState }
                : {
                    ...defaultState,
                    owner:heading === "Contact Card" ? data?.owner : defaultState.owner
                  }
            }
            enableReinitialize={true}
            validationSchema={
              heading === "Account Card" ? accountValidation : validation
            }
            onSubmit={async (values) => {
              mutate(values, { onSuccess: () => toggleDrawer() });
            }}
          >
            <Form>
              <Box className="mt-3 p-3 mb-5">
                <Grid container sx={{ marginBottom: "20rem!important" }}>
                  <Grid container spacing={2}>
                    {heading !== "Account Card" && <PersonalInfo />}
                    <CompanyInfo />
                    {heading !== "Account Card" && (
                      <>
                        {" "}
                        <LeadInfo />
                        <SocialInfo />{" "}
                      </>
                    )}
                    {heading == "Contact Card" ? "" : <AddressSectionWrapper />}
                    <AdditionalInfo />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  background: "#ededed",
                  position: "fixed",
                  bottom: 0,
                  right: 0,
                  zIndex: 9,
                  width: "calc(80vw + 5px)",
                }}
                className="border-top border-dark p-3"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={5}
                    sm={3}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-around aligin-items-center"
                  >
                    <Button
                      size="small"
                      onClick={toggleDrawer}
                      variant="contained"
                      color="error"
                      className="text-capitalize"
                    >
                      Close
                    </Button>
                    <SubmitButton
                      isLoading={isLoading}
                      variant="contained"
                      type="submit"
                      sx={{
                        background: "#C4C4C4",
                        padding: "4px 10px !important",
                        color: "#000",
                      }}
                      className="text-capitalize"
                    >
                      Update
                    </SubmitButton>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </Formik>
        </Box>
      </Drawer>
    </>
  );
};

export default Index;

import React from "react";

import { Grid, Stack } from "@mui/material";

import ContactOwner from "./ContactOwner";
// import ContactStatus from './ContactStatus';
import ContactCompany from "./ContactCompany";
import ContactAddress from "./ContactAddress";
import ContactAvatar from "./ContactAvatar";

const ContactCard = () => {
  return (
    <Grid item xs={12} sm={12} md={3} lg={3}>
      <Stack
        direction='column'
        alignItems='flex-start'
        style={{ borderRadius: "10px", height: "100%" }}
        className='bg-card p-3'
      >
        <ContactAvatar />
        <ContactOwner />
        <ContactCompany />
        <ContactAddress />
      </Stack>
    </Grid>
  );
};

export default ContactCard;

import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import React from "react";

const SelectDateNf = ({
  name,
  className,
  label,
  value,
  onChange,
  ...otherProps
}) => {
  return (
    <DatePicker
      label={label}
      value={value}
      {...otherProps}
      className={className}
      name={name}
      onChange={onChange}
      renderInput={(params) => (
        <TextField size="small" fullWidth variant="outlined" {...params} />
      )}
    />
  );
};

export default SelectDateNf;

import {
  OPEN_LEAD_DAILOG,
  CLOSE_LEAD_DAILOG,
  OPEN_ACCOUNT_DIALOG,
  CLOSE_ACCOUNT_DIALOG,
  OPEN_CONTACT_DIALOG,
  CLOSE_CONTACT_DIALOG,
  OPEN_PRODUCT_DAILOG,
  CLOSE_PRODUCT_DAILOG,
  OPEN_USER_DAILOG,
  CLOSE_USER_DAILOG,
  ON_PAGE_CHANGE,
  ON_SIZE_CHANGE,
  ON_DEFAULT_PAGINATION,
  OPEN_ROLE_DIALOG,
  CLOSE_ROLE_DIALOG
} from "../types/layoutTypes";

export const openLeadDialog = () => (dispatch) => {
  dispatch({ type: OPEN_LEAD_DAILOG });
};

export const closeLeadDialog = () => (dispatch) => {
  dispatch({ type: CLOSE_LEAD_DAILOG });
};

export const openAccountDialog = () => (dispatch) => {
  dispatch({ type: OPEN_ACCOUNT_DIALOG });
};
export const closeAccountDialog = () => (dispatch) => {
  dispatch({ type: CLOSE_ACCOUNT_DIALOG });
};
export const openContactDialog = () => (dispatch) => {
  dispatch({ type: OPEN_CONTACT_DIALOG });
};

export const closeContactDialog = () => (dispatch) => {
  dispatch({ type: CLOSE_CONTACT_DIALOG });
};

export const openProductDialog = () => (dispatch) => {
  dispatch({ type: OPEN_PRODUCT_DAILOG });
};

export const closeProductDailog = () => (dispatch) => {
  dispatch({ type: CLOSE_PRODUCT_DAILOG });
};
export const openUserDialog = () => (dispatch) => {
  dispatch({ type: OPEN_USER_DAILOG });
};

export const closeUserDailog = () => (dispatch) => {
  dispatch({ type: CLOSE_USER_DAILOG });
};

export const onPageChange = (page) => (dispatch) => {
  dispatch({ type: ON_PAGE_CHANGE,payload:page });
};

export const onSizeChange = (size) => (dispatch) => {
  dispatch({ type: ON_SIZE_CHANGE,payload:size });
};

export const setDefaultPagination = (data) => (dispatch) => {
  dispatch({ type: ON_DEFAULT_PAGINATION,payload:data });
};

export const openRoleDailog = () => (dispatch) => {
  dispatch({ type: OPEN_ROLE_DIALOG });
};

export const closeRoleDailog = () => (dispatch) => {
  dispatch({ type: CLOSE_ROLE_DIALOG });
};

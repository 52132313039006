import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from "react";
import DashboardCard from "../../../components/home/DashboardCard";

import Chart from "react-apexcharts";

const PageStructure = ({ tab1, tab2, defaultTab, optionTab1, optionTab2 }) => {
  const [optionTab, setOptionTab] = useState("default");
  let options = {
    chart: {
      type: "donut",
      height: "400px",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
      },
    },
    grid: {
      padding: {
        bottom: -80,
      },
    },
  };

  let series = [44];

  return (
    <Container className="px-5 pt-4 pb-4">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DashboardCard
            sx={{
              padding: "10px 20px 10px 20px",
              height: "120px",
              cursor: "pointer",
            }}
            isSelected={optionTab === "tab1" ? true : false}
            onClick={() => {
              optionTab === "tab1"
                ? setOptionTab("default")
                : setOptionTab("tab1");
            }}
          >
            {tab1}
          </DashboardCard>
        </Grid>
        <Grid item xs={4}>
          <DashboardCard
            sx={{
              padding: "10px 20px 10px 20px",
              height: "120px",
              cursor: "pointer",
            }}
            isSelected={optionTab === "tab2" ? true : false}
            onClick={() => {
              optionTab === "tab2"
                ? setOptionTab("default")
                : setOptionTab("tab2");
            }}
          >
            {tab2}
          </DashboardCard>
        </Grid>
        <Grid item xs={4}>
          <DashboardCard
            sx={{ padding: "10px 20px 10px 20px", height: "120px" }}
          >
            <Chart
              options={options}
              series={series}
              type="donut"
              // height={}
            />
          </DashboardCard>
        </Grid>
        <Grid item xs={12}>
          <DashboardCard
            sx={{ padding: "10px 20px 10px 20px", height: "525px" }}
          >
            {optionTab === "default"
              ? defaultTab
              : optionTab === "tab1"
              ? optionTab1
              : optionTab2}
          </DashboardCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageStructure;

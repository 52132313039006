import { NavLink as Link, useNavigate } from "react-router-dom"
import {
  Box,
  Grid,
  Avatar,
  Tooltip,
  IconButton,
  MenuItem,
  ListItemIcon,
  Divider,
  useMediaQuery,
  ListItemText,
} from "@mui/material"
import { IoSearch } from "react-icons/io5"
import {
  MdOutlineLocalPhone,
  MdOutlineNotificationsActive,
} from "react-icons/md"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { useQueryClient } from "react-query"

import logo from "../../../media/logo.png"
import { useHotkeys } from "react-hotkeys-hook"
import getDomain from "../../../utils/getDomain"
import {
  CallOutlined,
  Logout,
  MoreVert,
  NotificationsActiveOutlined,
} from "@mui/icons-material"
import CreateNewLead from "../../../pages/lead/newLead"
import { useSelector } from "react-redux"
import DropDown from "../dropdown"
import AddDropDown from "./AddDropDown"
import MobileNav from "./MobileNav"
import { menus } from "../../../constants/menus"
import CustomeMenu from "../menu"
import CreateNewRole from "../../../pages/roles/newRole/CreateNewRole"
const Navbar = () => {
  const { leadDialog, accountDialog, contactDialog,openRoleDialog } =
    useSelector((state) => state.layoutStore)
  const { user } = useSelector((state) => state.userStore)
  const userData = { ...user, ...user?.profile }
  delete userData["profile"]
  const queryClient = useQueryClient()
  const matches = useMediaQuery("(max-width:900px)")
  const lgMatch = useMediaQuery("(max-width:1200px)")

  const navigate = useNavigate()
  const onClick = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("userData")
    localStorage.removeItem("userData")
    navigate("/")
    queryClient.removeQueries()
  }

  useHotkeys("ctrl+h", (e) => {
    e.preventDefault()
    navigate("/", { replace: true })
  })
  useHotkeys("ctrl+l", (e) => {
    e.preventDefault()
    navigate("/leads", { replace: true })
  })
  useHotkeys("ctrl+c", (e) => {
    e.preventDefault()
    navigate("/contacts", { replace: true })
  })
  useHotkeys("ctrl+d", (e) => {
    e.preventDefault()
    navigate("/deals", { replace: true })
  })

  return (
    <>
      <CreateNewLead
        hideButton={true}
        dialogTitle="Lead"
        dialogOpen={leadDialog}
      />
      <CreateNewLead
        hideButton={true}
        dialogTitle="Contact"
        dialogOpen={contactDialog}
      />
      <CreateNewLead
        hideButton={true}
        dialogTitle="Account"
        dialogOpen={accountDialog}
      />
      <CreateNewRole dialogOpen={openRoleDialog} />
      
      <Box
        sx={{ height: "50px", width: "100%" }}
        className={`${matches ? "" : "d-flex"} NewNavbar bg-first p-1`}
        // className="d-flex align-items-center navbar bg-first p-1"
      >
        {matches ? (
          <MobileNav handelLogout={onClick} />
        ) : (
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} className="d-flex align-items-center">
              <Grid
                item
                xs={0.5}
                className="fifth d-flex align-items-center justify-content-center"
              >
                <DropDown
                  button={
                    <Tooltip title="Account settings">
                      <IconButton size="small" sx={{ ml: !matches && 2, p: 0 }}>
                        <Avatar
                          alt="avatar"
                          src={`${
                            process.env.REACT_APP_API_PROTOCOL
                          }${getDomain()}${"."}${
                            process.env.REACT_APP_API_URL
                          }${userData?.profile_pic}`}
                          sx={{
                            width: "32px",
                            height: "32px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  }
                  transformOrigin={{ horizontal: "left", vertical: "top" }}
                  anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        left: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  }}
                >
                  <MenuItem>
                    <Link
                      to="/user/profile"
                      style={{ textDecoration: "none", color: "#576a77" }}
                    >
                      <Grid className="d-flex align-items-center">
                        <Avatar
                          alt="avatar"
                          // src={`${process.env.REACT_APP_API_URL}${userData?.profile_pic}`}
                          src={`${
                            process.env.REACT_APP_API_PROTOCOL
                          }${getDomain()}${"."}${
                            process.env.REACT_APP_API_URL
                          }${userData?.profile_pic}`}
                          // src={`${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_URL}${userData?.profile_pic}`}
                          sx={{
                            width: "30px",
                            height: "30px",
                          }}
                        />
                        Profile
                      </Grid>
                    </Link>
                  </MenuItem>
                  <Divider />
                  {matches && (
                    <>
                      <MenuItem>
                        {" "}
                        <ListItemIcon>
                          <CallOutlined fontSize="small" />{" "}
                        </ListItemIcon>
                        Call{" "}
                      </MenuItem>{" "}
                      <MenuItem>
                        {" "}
                        <ListItemIcon>
                          <NotificationsActiveOutlined fontSize="small" />{" "}
                        </ListItemIcon>
                        Notifications{" "}
                      </MenuItem>
                    </>
                  )}
                  <MenuItem onClick={onClick}>
                    {" "}
                    <ListItemIcon>
                      <Logout fontSize="small" />{" "}
                    </ListItemIcon>
                    Logout{" "}
                  </MenuItem>{" "}
                </DropDown>
              </Grid>
              <Grid
                item
                xs={11}
                lg={8}
                className="d-flex align-items-center justify-content-evenly"
              >
                {menus.map((item) => (
                  <Grid className="d-flex ">
                    <Tooltip title={item.name} arrow placement="bottom">
                      <Link
                        to={item.path}
                        className="white navbar-link"
                        style={{ padding: "5px 5px 5px 5px" }}
                      >
                        {item.name}
                      </Link>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
              {!lgMatch ? (
                <Grid
                  item
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                  sx={{ position: "relative" }}
                >
                  <>
                    <IconButton sx={{ color: "#ffffff" }}>
                      <IoSearch />
                    </IconButton>
                    <IconButton sx={{ color: "#ffffff", padding: "0px" }}>
                      <AddDropDown />
                    </IconButton>
                    <IconButton sx={{ color: "#ffffff" }}>
                      <MdOutlineLocalPhone />
                    </IconButton>
                    <IconButton sx={{ color: "#ffffff" }}>
                      <MdOutlineNotificationsActive />
                    </IconButton>
                    <IconButton sx={{ color: "#ffffff" }}>
                      <AiOutlineQuestionCircle />
                    </IconButton>
                  </>
                </Grid>
              ) : (
                <>
                  <IconButton sx={{ color: "#ffffff", padding: "0px" }}>
                    <AddDropDown />
                  </IconButton>
                  <CustomeMenu
                    renderButton={
                      <IconButton>
                        <MoreVert />
                      </IconButton>
                    }
                  >
                    <MenuItem>
                      <ListItemIcon>
                        <IoSearch fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Search</ListItemText>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <MdOutlineLocalPhone fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Call</ListItemText>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <MdOutlineNotificationsActive fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Notifications</ListItemText>
                    </MenuItem>
                    <MenuItem>
                      <ListItemIcon>
                        <AiOutlineQuestionCircle fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Info</ListItemText>
                    </MenuItem>
                  </CustomeMenu>{" "}
                </>
              )}
              <Avatar alt="avatar" src={logo} sx={{ width: 30, height: 30 }} />{" "}
            </Grid>
          </Grid>
        )}
      </Box>
      <div style={{ height: "50px" }} />
    </>
  )
}

export default Navbar

import { Divider, Grid } from "@mui/material";
import React from "react";
import CustomTabs from "../../../../components/Layout/TabPanel";
import {
  address,
  billing_address,
  shipping_address,
} from "../../../../utils/leadVariables";
import AddressInfo from "./AddressInfo";

const AddressSectionWrapper = () => {
  return (
    <Grid item xs={12}>
      <Grid item className="pb-2" xs={12}>
        <Divider>Address Info</Divider>
      </Grid>
      <CustomTabs
        data={[
          {
            index: 0,
            label: "Address",
            component: AddressInfo,
            componentProps: {
              name: address,
            },
          },
          {
            index: 1,
            label: "Billing",
            component: AddressInfo,
            componentProps: {
              name: billing_address,
            },
          },
          {
            index: 2,
            label: "Shipping",
            component: AddressInfo,
            componentProps: {
              name: shipping_address,
            },
          },
        ]}
        isHeightAuto={true}
        panelClass="bg-white"
      />
    </Grid>
  );
};

export default AddressSectionWrapper;

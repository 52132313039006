import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MenuItem, Grid, Divider, DialogActions } from "@mui/material";

import { PublishedWithChanges } from "@mui/icons-material";

import InputField from "../../../components/formsUI/InputField";

import { Form, Formik } from "formik";

import useConvertToContact from "./useConvertToContact";

import { useConvetToContactUpdate } from "../../../redux/actions/contactAction";
import { useLeadUpdate } from "../../../redux/actions/leadActions";
import AutoSelectField from "../../../components/formsUI/AutoComplete";
import useAddUserData from "../../settings/user/userHooks/useAddUser";

const ConvertToContact = ({ handleClose, data }) => {
  const { open, defaultState, validation, handleClickOpen, handleCtoCClose } =
    useConvertToContact(handleClose, data);

  const { mutate } = useConvetToContactUpdate();
  const { mutate: updateLead } = useLeadUpdate({ dontNeedToast: true });

  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  };
  const { data: userList } = useAddUserData();
  const Options = userList?.data?.map((item) => ({
    uuid: item.uuid,
    label: item.profile.full_name,
  }));

  return (
    <>
      <MenuItem sx={{ fontSize: "13px" }} onClick={handleClickOpen}>
        <PublishedWithChanges fontSize="18" className="me-2" />
        Convert
      </MenuItem>
      <Dialog
        onKeyDown={stopPropagationForTab}
        open={open}
        aria-labelledby="alert-Dialog-title"
        aria-describedby="alert-Dialog-description"
      >
        <Formik
          initialValues={{
            ...defaultState,
          }}
          validationSchema={validation}
          onSubmit={async (values) => {
            updateLead(values, {
              onSuccess: () => {
                mutate(values, { onSuccess: () => handleCtoCClose() });
              },
            });
            // mutate(values, { onSuccess: () => handleCtoCClose() });
          }}
        >
          {({ values }) => (
            <Form>
              {console.log(values?.owner)}
              <DialogTitle id="alert-Dialog-title" className="border-bottom">
                Are you sure do you want to covert this lead?
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-Dialog-description"
                  className="mt-1"
                >
                  The conversion of this lead will create contact of a person or
                  a company.
                  <br />
                  <br />
                  You should only convert if it's a qualified lead.
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} mt={2}>
                    <Divider>Owner Info</Divider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoSelectField
                      name="owner"
                      label="Owner*"
                      color="secondary"
                      size="small"
                      multiple={false}
                      options={Options ? Options : []}
                      getOptionLabel={(option) => option?.label}
                      value={(option) => option?.uuid}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider>Personal Info</Divider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name="first_name"
                      label="First Name *"
                      color="secondary"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name="middle_name"
                      label="Middle Name *"
                      color="secondary"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name="last_name"
                      label="Last Name *"
                      color="secondary"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name="primary_number"
                      label="Primary Number *"
                      color="secondary"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider>Company Info</Divider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name="company_name"
                      label="Company Name *"
                      color="secondary"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name="gst_number"
                      label="GST Number *"
                      color="secondary"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField
                      name="primary_email"
                      label="Primary Email Address *"
                      color="secondary"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="border-top">
                <Button
                  onClick={handleCtoCClose}
                  color="error"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button type="submit" autoFocus variant="contained">
                  Convert
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default ConvertToContact;

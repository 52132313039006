import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { BiRupee } from "react-icons/bi";
import { useProductDelete } from "../../../../../redux/actions/dealAction";
import EditComponent from "../../../../Layout/edit/EditComponent";
const ProductAccordion = ({ data }) => {
  const price = () => {
    return data?.quantity * data?.product?.base_price;
  };

  const { mutate: deleteMutate } = useProductDelete({
    deal: data?.uuid,
    product: data?.product?.uuid,
  });

  return (
    <Grid container>
      <Grid item xs={12} className="d-flex align-items-center">
        <Grid item xs={8}>
          <Typography
            style={{ fontWeight: 500, color: "#000" }}
            fontSize={"10px"}
            component="p"
            className="d-flex"
          >
            {data?.product?.title}
            <span className="ms-2" style={{ color: "#616161" }}>
              ({data?.product?.product_id})
            </span>
          </Typography>
        </Grid>

        <Grid item xs={4} className="d-flex justify-content-end">
          <IconButton sx={{ fontSize: "14px" }}>
            <span style={{ color: "#000000" }}>
              <EditComponent
                uuid={data?.uuid}
                clear="Delete"
                hideEdit
                showdelete={true}
                deleteDescription={"This Product will be permanantly deleted."}
                deleteMutate={deleteMutate}
                showShare={false}
              />
            </span>
          </IconButton>
        </Grid>
      </Grid>

      <Grid item xs={12} className="d-flex">
        <Grid item xs={8}>
          <Typography fontSize={"10px"} component="p" className="d-flex">
            <span>Quantity - {data?.quantity}</span>
          </Typography>
        </Grid>

        <Grid
          className="d-flex align-items-end justify-content-end"
          item
          xs={4}
        >
          <Typography
            fontSize={"12px"}
            style={{ color: "#000", fontWeight: 500 }}
            className="d-flex justify-content-end"
            component="p"
          >
            <span>
              <BiRupee />
            </span>
            {price()?.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductAccordion;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSingleUser } from "../../../../redux/actions/userAction";
import useGetSingleUser from "../userHooks/useGetSingleUser";

const useSingleUser = () => {
  const { data, isLoading } = useGetSingleUser({
    onSuccess: (data) => {
      localStorage.setItem("userData", JSON.stringify(data?.data));
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(setSingleUser(data?.data));
    }
  }, [dispatch, data]);
  return { isLoading };
};

export default useSingleUser;

import { useQuery } from "react-query";
import axios from "axios";
import moment from "moment";

const useGetC2CReport = (phNum, fromDate, toDate) => {
  const user = JSON.parse(localStorage.getItem("userData"));
  const userData = { ...user, ...user?.profile };
  delete userData["profile"];

  return useQuery(
    "GetC2CReport",
    async () =>
      await axios.get(
        `https://prpmobility.com/Api/RecordingReport.aspx?Reporttype=1&accountid=20200078&Fromdate=${moment(
          fromDate
        ).format("DD/MM/YYYY")}&Todate=${moment(toDate).format(
          "DD/MM/YYYY"
        )}&aparty=${userData?.phone.slice(-10)}&bparty=${phNum?.slice(-10)}`
      ),
    {
      refetchOnWindowFocus: false,
      select: (data) => data?.data,
    }
  );
};

export default useGetC2CReport;

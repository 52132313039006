import Switch from "@mui/material/Switch"
import { useState } from "react"
import { toast } from "react-toastify"
import {
  useRolesData,
  useDeleteRoles,
} from "../rolesHooks/useRolesData"
import useUpdateRoles from "../rolesHooks/useUpdateRoles"
import { Button, IconButton, Tooltip } from "@mui/material"
import CustomDialog from "../../../components/Layout/customDialog/CustomDialog"
import { MdOutlineDeleteForever, MdOutlineMode } from "react-icons/md"
import * as Yup from "yup"
import { openRoleDailog } from "../../../redux/actions/LayoutAction"
import { useDispatch, useSelector } from "react-redux"
import RolesForm from "../rolesForm"

const useRolesTable = () => {
  const [rolesData, setRolesData] = useState()
  const { openRoleDialog } = useSelector((state) => state.layoutStore)
  const dispatch = useDispatch()

  const { isLoading, refetch } = useRolesData({
    onSuccess: (data) => setRolesData(data?.data),
  })
  const { mutate } = useUpdateRoles({ refetch: refetch })
  const { deleteMutate } = useDeleteRoles({ refetch: refetch })

  const handleClickOpen = () => {
    dispatch(openRoleDailog())
  }

  const handleChange = (event, uuid) => {
    mutate(
      { is_active: event.target.checked, uuid: uuid },
      {
        onSuccess: () => {
          // toast.success("Role updated successfully")
          refetch()
        },
      }
    )
  }

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      headerClassName: "text-capitalize full-width",
      cellClassName: "p-1",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      headerClassName: "text-capitalize full-width",
      cellClassName: "p-1",
      flex: 1,
    },
    {
      field: "is_active",
      headerName: "Active",
      minWidth: 150,
      headerClassName: "text-capitalize full-width",
      cellClassName: "p-1",
      flex: 1,
      renderCell: (params) => {
        return (
          <Switch
            checked={params?.row?.is_active}
            onClick={(e) => handleChange(e, params.row.uuid)}
          ></Switch>
        )
      },
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 150,
      headerClassName: "text-capitalize full-width",
      cellClassName: "p-1",
      flex: 1,
      renderCell: (params) => {
        console.log(params.row, "params.row")
        return (
          <>
            <CustomDialog
              mutate={mutate}
              heading="Edit Role"
              button={
                <Tooltip title="Edit Role">
                  <IconButton sx={{ padding: "4px" }}>
                    <MdOutlineMode />
                  </IconButton>
                </Tooltip>
              }
              closeButton="CLOSE"
              addButton="UPDATE"
              initialValues={{
                uuid: params?.row?.id,
                name: params?.row?.name,
                description: params?.row?.description,
                is_active: params?.row?.is_active,
                permissionsList: params?.row?.permissions.map((e) => e.uuid),
                checked: params?.row?.permissions.map((e) => e.uuid),
                expanded: ["Select"],
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name is required!"),
                description: Yup.string().required("Description is required!"),
                permissionsList: Yup.array().required(
                  "Please select atleast one permission"
                ),
              })}
            >
              <RolesForm></RolesForm>
            </CustomDialog>

            <CustomDialog
              mutate={deleteMutate}
              heading="Delete Role"
              button={
                <Tooltip title="Delete Role">
                  <IconButton sx={{ padding: "4px" }}>
                    <MdOutlineDeleteForever />
                  </IconButton>
                </Tooltip>
              }
              closeButton="CLOSE"
              addButton="DELETE"
              initialValues={{
                uuid: params?.row?.uuid,
              }}
            >
              <h6 style={{ marginTop: "10px" }}>
                Are you sure you want to delete this role?
              </h6>
            </CustomDialog>
          </>
        )
      },
    },
  ]
  return { columns, isLoading, rolesData }
}

export default useRolesTable

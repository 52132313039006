import { Button, Divider, Grid, Typography, useMediaQuery } from "@mui/material"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import useGetC2CReport from "../../../../ivr/useGetC2CReport"
import { MdOutlinePhoneMissed } from "react-icons/md"
import DateRangePickerMui from "../../../../formsUI/DateRangePicker"
import moment from "moment"
import { toast } from "react-toastify"
import { grid } from "@mui/system"

const PreviousCalls = () => {
  const matches = useMediaQuery("(max-width:600px)")
  const { contact } = useSelector((state) => state.contactStore)
  const date = new Date()
  const [dateValue, setDateValue] = useState([
    moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "MM/DD/YYYY"
    ),
    moment(date).format("MM/DD/YYYY"),
  ])
  const { data: Data, refetch: DataRefetch } = useGetC2CReport(
    contact?.primary_number,
    dateValue[0],
    dateValue[1]
  )

  return (
    <>
      {/* <div className="mb-3 d-flex align-items-center justify-content-center">
        <DateRangePickerMui
          value={dateValue}
          setValue={setDateValue}
          startLabel="From Date"
          endLabel="To Date"
          style={{ width: "140px" }}
        />
        <Button
          variant="contained"
          type="submit"
          className="text-capitalize bg-first fifth px-3 py-1 ms-3 d-block"
          style={{
            paddingInline: "15px",
            paddingBlock: "0px",
          }}
          onClick={() => {
            if (dateValue[0] === null || dateValue[0] === null) {
              toast.error("Please select date range");
            } else {
              DataRefetch();
            }
          }}
        >
          Get Details
        </Button>
      </div> */}

      <Grid container className="mb-3">
        <Grid item xs={12} sm={8} className="mb-2" style={ matches ? {display:"grid", placeContent:"center"} : {}}>
          <DateRangePickerMui
            value={dateValue}
            setValue={setDateValue}
            startLabel="From Date"
            endLabel="To Date"
            style={matches ? { width: "120px" } : { width: "140px" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={ matches ? {display:"grid", placeContent:"center"} : {}}>
          <Button
            variant="contained"
            type="submit"
            className="text-capitalize bg-first fifth px-3 py-1 ms-3 d-block"
            style={{
              paddingInline: "15px",
              paddingBlock: "0px",
            }}
            onClick={() => {
              if (dateValue[0] === null || dateValue[0] === null) {
                toast.error("Please select date range")
              } else {
                DataRefetch()
              }
            }}
          >
            Get Details
          </Button>
        </Grid>
      </Grid>
      {typeof Data === "object" &&
        Data?.map((log) => {
          return (
            <>
              <div className="d-flex justify-content-between">
                <Typography
                  color="text.dark"
                  fontSize="12px"
                  className="d-flex align-items-center"
                >
                  To: {contact.first_name} - {contact.title},{" "}
                  {contact.company_name}
                </Typography>
                <span>
                  <Typography
                    fontSize="12px"
                    color="text.dark"
                    display="inline"
                    className="me-2"
                  >
                    {log?.call_start_time_in}
                  </Typography>
                </span>
              </div>
              <div>
                <Typography color="text.dark" fontSize="12px" display="inline">
                  Number:
                  <span className="ms-2">{log?.outgoing_picked}</span>
                </Typography>
              </div>
              <div className="mt-1 d-flex align-items-center">
                {log?.call_status_out === "ANSWERED" ? (
                  <>
                    {" "}
                    <Typography
                      color="text.dark"
                      fontSize="12px"
                      display="inline"
                      // className="ms-3"
                    >
                      Call Recording:
                    </Typography>
                    <>
                      <audio
                        style={{ height: "30px" }}
                        className="ms-2"
                        controls
                        src={`http://prpmobility.com${log?.callrecordingurl}`}
                      >
                        Your browser does not support the
                        <code>audio</code> element.
                      </audio>
                    </>
                  </>
                ) : (
                  <Typography
                    color="text.dark"
                    fontSize="12px"
                    display="inline"
                    className="d-flex align-items-center"
                  >
                    Call Unanswered{" "}
                    <MdOutlinePhoneMissed
                      style={{ color: "red" }}
                      className="ms-2"
                    />
                  </Typography>
                )}
              </div>

              <Divider className="mt-2 mb-4" />
            </>
          )
        })}
    </>
  )
}

export default PreviousCalls

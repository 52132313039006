import { useEffect, useMemo, useRef } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";

import { Formik, Form } from "formik";

import useCreateLead from "./useCreateLead";

import PersonalInfo from "./CreateComponents/PersonalInfo";
import CompanyInfo from "./CreateComponents/CompanyInfo";
import LeadInfo from "./CreateComponents/LeadInfo";
import SocialInfo from "./CreateComponents/SocialInfo";
import AdditionalInfo from "./CreateComponents/AdditionalInfo";
import AddressSectionWrapper from "./CreateComponents/AddressSectionWrapper";

const CreateNewLead = ({ handleDropDownClose, dialogTitle, dialogOpen }) => {
  const user = JSON.parse(localStorage.getItem("userData"));

  const defaultState =
    dialogTitle === "Account"
      ? {
          company_name: "",
          gst_number: "",
          title: "",
          primary_email: "",
          secondary_email: "",
          website: "",
          source: "Self",
          industry: "",
          fax_number: "",
          tags: "",

          address_line1: "",
          city1: "",
          state1: "",
          nationality1: "",
          pin_code1: "",

          address_line2: "",
          city2: "",
          state2: "",
          nationality2: "",
          pin_code2: "",

          address_line3: "",
          city3: "",
          state3: "",
          nationality3: "",
          pin_code3: "",

          description: "",
        }
      : {
          first_name: "",
          middle_name: "",
          last_name: "",
          primary_number: "",
          secondary_number: "",
          work_number: "",

          company_name: "",
          gst_number: "",
          title: "",
          primary_email: "",
          secondary_email: "",
          website: "",
          source: "Self",
          industry: "",
          fax_number: "",
          tags: "",

          status: "New",
          owner: user
            ? { ...user?.profile, label: user?.profile?.full_name, ...user }
            : "",

          skype: "",
          facebook: "",
          twitter: "",
          linkedin: "",
          whatsapp: "",

          address_line1: "",
          city1: "",
          state1: "",
          nationality1: "",
          pin_code1: "",

          address_line2: "",
          city2: "",
          state2: "",
          nationality2: "",
          pin_code2: "",

          address_line3: "",
          city3: "",
          state3: "",
          nationality3: "",
          pin_code3: "",

          description: "",
        };

  const {
    validation,
    handleLeadClose,
    handleContactClose,
    handleAccountClose,
    accountValidation,
    newLeadMutate,
    newContactMutate,
    newAccountMutate,
  } = useCreateLead(handleDropDownClose);

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (dialogOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [dialogOpen]);

  let actions = useMemo(
    () => ({
      Lead: (values, resetForm) => {
        newLeadMutate(values, {
          onSuccess: () => {
            resetForm();
            handleLeadClose();
          },
        });
      },
      Contact: (values, resetForm) => {
        newContactMutate(values, {
          onSuccess: () => {
            resetForm();
            handleContactClose();
          },
        });
      },
      Account: (values, resetForm) => {
        newAccountMutate(values, {
          onSuccess: () => {
            resetForm();
            handleAccountClose();
          },
        });
      },
    }),
    [
      handleLeadClose,
      handleContactClose,
      handleAccountClose,
      newAccountMutate,
      newContactMutate,
      newLeadMutate,
    ]
  );

  return (
    <>
      <Formik
        initialValues={{
          ...defaultState,
        }}
        validationSchema={
          dialogTitle === "Account" ? accountValidation : validation
        }
        enableReinitialize={true}
        onSubmit={async (values, { resetForm }) => {
          actions[dialogTitle](values, resetForm);
        }}
      >
        {({ submitForm }) => (
          <Form>
            <Dialog
              fullWidth
              maxWidth="md"
              scroll="paper"
              open={dialogOpen}
              aria-labelledby="scroll-Dialog-title"
              aria-describedby="scroll-Dialog-content"
            >
              <DialogTitle
                sx={{
                  background: "#ededed",
                  position: "sticky",
                  top: "0",
                  right: "0",
                  zIndex: 10,
                }}
                className="border-bottom border-dark shadow-sm"
                id="scroll-Dialog-title"
              >
                New {dialogTitle}
              </DialogTitle>
              <DialogContent>
                <Grid container pt={2}>
                  <Grid container spacing={2}>
                    {dialogTitle !== "Account" && <PersonalInfo />}
                    <CompanyInfo />
                    {dialogTitle !== "Account" && (
                      <>
                        {" "}
                        <LeadInfo />
                        <SocialInfo />
                      </>
                    )}
                    {dialogTitle == 'Contact' ? "" : <AddressSectionWrapper />}
                    <AdditionalInfo />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  background: "#ededed",
                }}
                className="border-top border-dark"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={5}
                    sm={3}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-around aligin-items-center"
                  >
                    <Button
                      size="small"
                      onClick={
                        dialogTitle === "Lead"
                          ? handleLeadClose
                          : dialogTitle === "Contact"
                          ? handleContactClose
                          : handleAccountClose
                      }
                      variant="contained"
                      color="error"
                      className="text-capitalize"
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      className="text-capitalize"
                      sx={{
                        padding: "4px 10px !important",
                      }}
                      onClick={() => submitForm()}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateNewLead;

import React from "react";

import { Box, Grid, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import Breadcrumb from "../formsUI/Breadcrumb";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import ConvertToDeal from "../../pages/deal/convertToDeal";

const ContactHeader = () => {
  const { contact } = useSelector((state) => state.contactStore);
  return (
    <Grid
      container
      display="row"
      justifyContent={"space-between"}
      className="bg-fifth"
      sx={{
        borderBottom: "2px solid #ced4da",
        py: "10px",
        paddingLeft: "25px",
      }}
    >
      <Box className="d-flex align-items-center">
        <Grid className="d-flex align-items-center justify-content-center">
          <IconButton>
            <Link
              to="/contacts"
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <BiArrowBack />
            </Link>
          </IconButton>
        </Grid>
        <Grid
          container
          className="d-flex align-items-center justify-content-between ms-3"
        >
          <Grid>
            <Breadcrumb module="Contacts" name={contact?.first_name} />
          </Grid>
        </Grid>
      </Box>
      <Box className="d-flex align-items-center me-2">
        <ConvertToDeal contact={contact} />
      </Box>
    </Grid>
  );
};

export default ContactHeader;

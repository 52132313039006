import { useDispatch } from "react-redux";
import { SET_SEARCH } from "../types/commonTypes";

export const useSetSearch = () => {
  const dispatch = useDispatch();
  const setSearchValue = (searchValue) => {
    dispatch({ type: SET_SEARCH, payload: searchValue });
  };
  return {
    setSearchValue,
  };
};

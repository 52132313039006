import { PhotoCamera } from "@mui/icons-material";
import { Avatar, Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import getDomain from "../../../utils/getDomain";
import CropEasy from "../../crop/CropEasy";

const ProfileImage = () => {
  const [openCrop, setOpenCrop] = useState(false);
  const [userData, setUserData] = useState({
    ...JSON.parse(localStorage.getItem("userData")).profile,
  });
  const inp = useRef(null);
  const [photoURL, setPhotoURL] = useState(userData?.profile_pic);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("userData"));
    const data = { ...user, ...user?.profile };
    if (data) {
      setUserData(data);
    }
    // eslint-disable-next-line
  }, [localStorage.getItem("userData")]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setPhotoURL(URL.createObjectURL(file));
      setOpenCrop(true);
    }
  };
  return !openCrop ? (
    <Grid item xs={12} sm={4} md={4} className="profle-sidebar">
      <Grid
        item
        xs={12}
        className="mt-3 p-3 d-flex flex-column justify-content-center align-items-center"
      >
        <div style={{ position: "relative", width: "200px", height: "200px" }}>
          <Avatar
            alt=""
            src={`${process.env.REACT_APP_API_PROTOCOL}${getDomain()}${"."}${
              process.env.REACT_APP_API_URL
            }${userData?.profile_pic}`}
            style={{
              width: "200px",
              height: "200px",
            }}
          />
          <label
            htmlFor="profile-update-icon"
            style={{
              position: "absolute",
              bottom: "10%",
              right: "0%",
              zIndex: "1000",
              background: "#208996",
              borderRadius: "50%",
            }}
          >
            <input
              accept="image/*"
              name="profile_pic"
              hidden
              id="profile-update-icon"
              type="file"
              ref={inp}
              onChange={(e) => handleImageUpload(e)}
            />
            <IconButton onClick={() => inp?.current?.click()}>
              <PhotoCamera sx={{ color: "#ffffff" }} />
            </IconButton>
          </label>
        </div>
      </Grid>
    </Grid>
  ) : (
    <CropEasy {...{ photoURL, setOpenCrop, setPhotoURL }} />
  );
};

export default ProfileImage;

import { useQuery } from "react-query";
import { tenantAPI } from "../../../config/api";

const useDealData = (options) => {
  return useQuery("deals", async () => await tenantAPI.get(`/deals/0/`), {
    ...options,
    select: (data) => {
      return data?.data;
    },
  });
};
export default useDealData;

import { Typography } from "@mui/material";

import React from "react";
import Chart from "react-apexcharts";

import DashboardCard from "../../../components/home/DashboardCard";

function LeadsToDealsReport() {
  let options = {
    chart: {
      type: "bar",
      toolbar: {
        export: {
          csv: {
            filename: "Leads-Converted-into-Deals",
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#165DFF", "#0FC6C2"],
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  var series = [
    {
      name: "Leads",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 54, 77, 82],
    },
    {
      name: "Deals",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 81, 14],
    },
  ];

  return (
    <DashboardCard sx={{ padding: "15px 20px", height: "410px" }}>
      <Typography component="p" fontSize="16px" fontWeight="500">
        Leads Converted into Deals
      </Typography>
      <Typography
        fontSize="12px"
        fontWeight="500"
        component="p"
        className="second"
      >
        Current Year - 2022
      </Typography>
      <Chart options={options} series={series} type="bar" height={340} />
    </DashboardCard>
  );
}

export default LeadsToDealsReport;

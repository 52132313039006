import React from "react";
import * as Yup from "yup";

const usePass = () => {
  const INITIAL_FORM_STATE = {
    old_password: "",
    new_password: "",
    new_password2: "",
  };
  const FORM_VALIDATION = Yup.object().shape({
    old_password: Yup.string().required("This field is required"),
    new_password: Yup.string()
      .required("This field is required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        () => (
          <>
            <span>Your Password Must contaoin following :-</span>
            <ul>
              <li>Must Contain 8 Characters</li>
              <li> One Uppercase</li> <li>One Lowercase</li>{" "}
              <li>One Number </li> <li>one special case Character</li>
            </ul>
          </>
        )
      ),
    new_password2: Yup.string()
      .oneOf([Yup.ref("new_password"), null], "Passwords must match")
      .required("This field is required"),
  });

  return {
    INITIAL_FORM_STATE,
    FORM_VALIDATION,
  };
};

export default usePass;

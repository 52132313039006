import { useQuery } from "react-query";
import { tenantAPI } from "../../../../config/api";

const useAddProduct = (options) => {
  return useQuery(
    "getProduct",
    async () => await tenantAPI.get(`/products/0/`),
    {
      select: (data) => {
        return {
          data: data?.data?.data?.map((r) => ({ ...r, id: r.uuid })),
          count: data?.data?.count,
        };
      },
      ...options,
    }
  );
};

export default useAddProduct;

import * as Yup from "yup";

const useAddNote = (row) => {
  const defaultState = {
    text: "",
    attachments: "",
  };

  const validation = Yup.object().shape({
    text: Yup.string().required("Note is required"),
    attachments: Yup.mixed(),
  });
  return {
    defaultState,
    validation,
  };
};

export default useAddNote;

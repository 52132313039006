import React from "react";

import ActivityHOC from "./ActivityHOC";

import { Typography } from "@mui/material";

import { EventNote } from "@mui/icons-material";

const UpcomingAppointments = () => {
  return (
    <ActivityHOC
      icon={<EventNote />}
      title="Upcoming Appointments"
      subtitle="No Upcoming Appointments"
    >
      <Typography variant="p" className="second" fontSize="10px">
        No upcoming appointments
      </Typography>
    </ActivityHOC>
  );
};

export default UpcomingAppointments;

import React, { useEffect, useState } from "react";

import { DataGridPro } from "@mui/x-data-grid-pro";

import GridOverlayComp from "./GridOverlay";
import CustomLoadingOverlay from "./LoadingOverlay";
import CustomToolbar from "./CustomToolbar";

import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import {
  onPageChange,
  onSizeChange,
} from "../../../redux/actions/LayoutAction";

const escapeRegExp = (value) => {
  return value && value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    "& .MuiTablePagination-root": {
      marginBottom: "3rem",
    },
    "& .MuiTablePagination-selectLabel,& .MuiTablePagination-displayedRows,": {
      marginBottom: 0,
      color: "#495057",
    },
    "& .MuiTablePagination-toolbar": {
      dispaly: "flex",
      aligItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiCheckbox-root.Mui-checked": {
      color: "#000",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#E1F3FA",
      border: "none !important",
      color: "#495057",
      "& .MuiDataGrid-columnSeparator": {
        color: "#4C6371",
      },
      [theme.breakpoints.down("sm")]: {
        minHeigth: "45 !important",
        maxHeigth: "45 !important",
        lineHeight: "45 !important",
      },
    },
    "& .MuiButton-root": {
      color: "#495057",
      border: "2px solid #60859d",
      padding: "5px 10px",
    },
    "& .MuiDataGrid-row:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)!important",
    },
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        minHeigth: "45 !important",
        maxHeigth: "45 !important",
        lineHeight: "45 !important",
      },
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("sm")]: {
        minHeigth: "45 !important",
        maxHeigth: "45 !important",
        lineHeight: "45 !important",
      },
    },
  },
}));

const Table = ({
  data,
  loading,
  columns,
  hideFooter,
  checkboxSelection = false,
  ...otherProps
}) => {
  const { search } = useSelector((state) => state.commonStore);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState(data || []);
  const { rowCount, size } = useSelector((store) => store.layoutStore);
  const dispatch = useDispatch();
  // const SearchFunc = (searchValue) => {
  //   const searchRegex = new RegExp(escapeRegExp(searchValue), "gi");
  //   const filteredRows = data.filter((row) =>
  //     Object.keys(row).some((field) =>
  //       searchRegex.test(row[field] ? row[field].toString() : "")
  //     )
  //   );
  //   setRows(filteredRows);
  // };

  useEffect(() => {
    const searchRegex = new RegExp(escapeRegExp(search), "gi");
    const filteredRows = data.filter((row) =>
      Object.keys(row).some((field) =>
        searchRegex.test(row[field] ? row[field].toString() : "")
      )
    );
    setRows(filteredRows);
  }, [search, data]);

  const clasess = useStyles();

  useEffect(() => {
    if (data) {
      setRows(data);
    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <div
      style={{
        height: !rows?.length ? "450px" : "auto",
        width: "100%",
      }}
      className="mt-3 table-wrapper"
    >
      <DataGrid
        className={`${clasess.root}`}
        {...otherProps}
        onPageSizeChange={(newPageSize) => dispatch(onSizeChange(newPageSize))}
        onPageChange={(newPage) => {
          console.log(newPage);
          dispatch(onPageChange(newPage));
        }}
        rowsPerPageOptions={[20, 50, 100, 150].filter(
          (size) => rowCount >= size
        )}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: GridOverlayComp,
          Toolbar: CustomToolbar,
        }}
        rowCount={rowCount}
        paginationMode="server"
        getRowId={(row) => row?.id || row?.uuid}
        columns={columns}
        rows={rows}
        pageSize={size}
        checkboxSelection={checkboxSelection}
        // disableSelectionOnClick
        loading={loading}
        rowHeight={40}
        pagination
        hideFooter={hideFooter}
        autoHeight={rows.length !== 0 ? true : false}
        sx={{ fontSize: "13px" }}
      />
    </div>
  );
};

export default Table;

import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { tenantAPI } from "../../../config/api"
import errorHandle from "../../../utils/errorHandle"

const useUpdateRoles = (options) => {
  const mutation = useMutation(
    async (data) => {
      await tenantAPI.put(`accounts/role/${data?.uuid}/`, data)
    },
    {
      onSuccess: (data, variables) => {
        options?.refetch()
        toast.success("Roles Updated Successfully")
      },
      onError: (error) => {
        errorHandle(error)
      },
    }
  )

  return { mutate: mutation.mutate }
}

export default useUpdateRoles

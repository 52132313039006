import { Typography } from "@mui/material";

import React from "react";
import RevenueFromProduct from "../../../components/home/RevenueFromProduct";
import PageStructure from "../components/PageStructure";

import Chart from "react-apexcharts";
import ProfitRevDetails from "./ProfitRevDetails";

const ProfitRev = () => {
  return (
    <RevenueFromProduct
      name="Revenue Amount May 2022 "
      revenue={"5,276.33"}
      revLastYear={-11.2}
    />
  );
};

const LostRev = () => {
  return (
    <RevenueFromProduct
      name="Revenue Lost in May 2022 "
      revenue={"5,276.33"}
      revLastYear={11.2}
      isRevLost={true}
    />
  );
};

const RevenueReportYear = () => {
  let series = [
    {
      name: "Revenue",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 190, 300, 1000],
    },
  ];

  let options = {
    chart: {
      height: 400,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#0FC6C2"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };
  return (
    <>
      <Typography component="p" fontWeight="500" fontSize="20px">
        Revenue Report
      </Typography>
      <Chart options={options} series={series} type="line" height={400} />
    </>
  );
};

const Sales = () => {
  return (
    <PageStructure
      tab1={<ProfitRev />}
      tab2={<LostRev />}
      defaultTab={<RevenueReportYear />}
      optionTab1={<ProfitRevDetails />}
    />
  );
};

export default Sales;
